import React, { memo, useCallback, useMemo } from 'react';

import { Badge, Button, Stack } from 'react-bootstrap';

import VirtualTable from '../../../components/VirtualTable';
import { SettingsBridgesFilterData, SettingsBridgesSortingData } from '.';

import { DBBridge, DBBridges, DBTokens } from '../../../common/types';

import { deleteBridge, updateBridgeInstance, updateBridgeUrl } from './actions';
import SocketClient from '../../../common_custom/SocketClient';
import { INTERFACE_EVENTS } from '../../../common';

const columns = [
  { name: 'Bridge name', key: 'name', minWidth: 250 },
  { name: 'Bridge URL', key: 'url', minWidth: 150, width: '-webkit-fill-available' },
  { name: 'Num', key: 'numBridges', minWidth: 80 },
  { name: 'Num En', key: 'numEnabled', minWidth: 80 },
  {
    name: 'Automation',
    key: 'automation',
    minWidth: 170,
    width: '-webkit-fill-available',
  },
  { name: 'Instance', key: 'instance', minWidth: 250 },
  { name: 'x', key: 'delete', minWidth: 40 },
];

/**
 *
 */
type SettingsBridgesTableProps = {
  bridges: DBBridges
  allTokens: DBTokens
  textFilter: string
  filterData: SettingsBridgesFilterData
  sortingData: SettingsBridgesSortingData
}
export default memo(function SettingsBridgesTable({
                                                    bridges,
                                                    allTokens,
                                                    textFilter,
                                                    filterData,
                                                    sortingData,
                                                  }: SettingsBridgesTableProps) {
  const bridgeEntries = Object.entries(bridges);
  const tokenDatas = Object.values(allTokens);

  /**
   * Caclulate number of bridges
   */
  const numBridgesByBridge: { [key: string]: number } = useMemo(() => {
    return bridgeEntries.reduce(
      (acc, [bridgeName, bridgeData]) => ({
        ...acc,
        [bridgeName]: tokenDatas.filter((v) => Object.keys(v.bridge || {}).includes(bridgeName))
          .length,
      }),
      {},
    );
  }, [bridgeEntries, tokenDatas]);

  /**
   * Caclulate number of bridges
   */
  const numBridgesEnabledByBridge: { [key: string]: number } = useMemo(() => {
    return bridgeEntries.reduce(
      (acc, [bridgeName, bridgeData]) => ({
        ...acc,
        [bridgeName]: tokenDatas.filter(
          (v) => Object.keys(v.bridge || {}).includes(bridgeName) && v.active,
        ).length,
      }),
      {},
    );
  }, [bridgeEntries, tokenDatas]);

  /**
   * Filtering
   */
  const filteredBridgeEntries = useMemo(() => {
    return bridgeEntries.filter(([bridgeName, bridgeData]) => {
      const { url, instanceName } = bridgeData;
      const text = textFilter.toLowerCase();

      const textCondition =
        textFilter === '' ||
        bridgeName.toLowerCase().includes(text) ||
        url.toLowerCase().includes(text) ||
        instanceName?.toLowerCase().includes(text);

      const instanceCondition =
        filterData.workerInstance === 'none' ||
        (filterData.workerInstance === 'true' && instanceName && instanceName.length > 0) ||
        (filterData.workerInstance === 'false' && !instanceName);

      return textCondition && instanceCondition;
    });
  }, [bridges, textFilter, filterData]);

  /**
   * Sorting
   */
  filteredBridgeEntries.sort((a, b) => {
    if (sortingData.sortBy === 'bridgeName') {
      return sortingData.sortOrder === 'asc' ? a[0].localeCompare(b[0]) : b[0].localeCompare(a[0]);
    }
    if (sortingData.sortBy === 'numBridges') {
      return sortingData.sortOrder === 'asc'
        ? numBridgesByBridge[a[0]] - numBridgesByBridge[b[0]]
        : numBridgesByBridge[b[0]] - numBridgesByBridge[a[0]];
    }
    if (sortingData.sortBy === 'numBridgesEn') {
      return sortingData.sortOrder === 'asc'
        ? numBridgesEnabledByBridge[a[0]] - numBridgesEnabledByBridge[b[0]]
        : numBridgesEnabledByBridge[b[0]] - numBridgesEnabledByBridge[a[0]];
    }
    return 0;
  });

  const data = filteredBridgeEntries.map(([bridgeName, bridgeData]) => {
    return [
      <BridgeNameCell bridgeName={bridgeName} />,
      <BridgeUrlCell bridgeName={bridgeName} url={bridgeData.url} />,
      <NumBridgesCell value={numBridgesByBridge[bridgeName]} />,
      <NumBridgesEnabledCell value={numBridgesEnabledByBridge[bridgeName]} />,
      <SetBridgeAutomationButtons bridgeName={bridgeName} />,
      <WorkerInstanceCell bridgeName={bridgeName}
                          workerInstance={bridgeData.instanceName} />,
      <DeleteCell bridgeName={bridgeName} />,
    ];
  });

  const totalNumBridges = bridgeEntries.length;
  const filteredNumBridges = filteredBridgeEntries.length;
  const estimateRowHeight = useCallback(() => 40, []);

  return (
    <Stack gap={2}>
      <div>
        <Badge bg="light" text="dark" className="h1" style={{ fontSize: '0.8em' }}>
          {`Showing ${filteredNumBridges} / ${totalNumBridges} trades`}
        </Badge>
      </div>
      <VirtualTable
        columns={columns}
        data={data}
        style={{ fontFamily: 'monospace' }}
        estimateRowHeight={estimateRowHeight}
      />
    </Stack>
  );
});

/**
 *
 */
type BridgeNameCellProps = {
  bridgeName: string
}
const BridgeNameCell = memo(function BridgeNameCell({ bridgeName }: BridgeNameCellProps) {
  return (
    <Badge bg="light" text="dark" className="noBg" style={{ fontSize: '1em' }}>
      {bridgeName}
    </Badge>
  );
});

/**
 *
 */
type BridgeUrlCellProps = {
  bridgeName: string
  url: DBBridge['url']
}
const BridgeUrlCell = memo(function BridgeUrlCell({
                                                    bridgeName,
                                                    url,
                                                  }: BridgeUrlCellProps) {
  return (
    <Button
      size="sm"
      variant="light"
      className="noBgHov noBorder text-truncate"
      onClick={(e) => {
        if (e.altKey || e.metaKey) {
          return window.open(url);
        }
        updateBridgeUrl(bridgeName, url);
      }}
    >
      {url || '‎ '}
    </Button>
  );
});

/**
 *
 */
type NumBridgesCellProps = {
  value: number
}
const NumBridgesCell = memo(function NumBridgesCell({ value }: NumBridgesCellProps) {
  return (
    <Badge bg="light" text="dark" className="noBg" style={{ fontSize: '1em' }}>
      {value}
    </Badge>
  );
});

/**
 *
 */
type NumBridgesEnabledCellProps = {
  value: number
}
const NumBridgesEnabledCell = memo(function NumBridgesEnabledCell({ value }: NumBridgesEnabledCellProps) {
  return (
    <Badge bg="light" text="dark" className="noBg" style={{ fontSize: '1em' }}>
      {value}
    </Badge>
  );
});

/**
 *
 */
type SetBridgeAutomationButtonsProps = {
  bridgeName: string
}
const SetBridgeAutomationButtons = memo(function SetBridgeAutomationButtons({
                                                                              bridgeName,
                                                                            }: SetBridgeAutomationButtonsProps) {
  return (
    <Stack direction="horizontal" gap={2} style={{ justifyContent: 'center' }}>
      <Button
        size="sm"
        variant="outline-success"
        onClick={() => {
          SocketClient.emitEvent(INTERFACE_EVENTS.TOGGLE_BRIDGE_AUTOMATION, {
            bridgeName: bridgeName,
            newValue: true,
          });
        }}
      >
        Enable
      </Button>
      <Button
        size="sm"
        variant="outline-danger"
        onClick={() => {
          SocketClient.emitEvent(INTERFACE_EVENTS.TOGGLE_BRIDGE_AUTOMATION, {
            bridgeName: bridgeName,
            newValue: false,
          });
        }}
      >
        Disable
      </Button>
    </Stack>
  );
});

/**
 *
 */
type WorkerInstanceCellProps = {
  bridgeName: string
  workerInstance: DBBridge['instanceName']
}
const WorkerInstanceCell = memo(function WorkerInstanceCell({
                                                              bridgeName,
                                                              workerInstance,
                                                            }: WorkerInstanceCellProps) {
  return (
    <Button
      size="sm"
      variant="light"
      className="noBg noBorder text-truncate"
      style={{ cursor: 'default' }}
      onClick={(e) => {
        if (e.metaKey && e.shiftKey) {
          updateBridgeInstance(bridgeName, workerInstance);
        }
      }}
    >
      {workerInstance || '‎ '}
    </Button>
  );
});

/**
 *
 */
type DeleteCellProps = {
  bridgeName: string
}
const DeleteCell = memo(function DeleteCell({ bridgeName }: DeleteCellProps) {
  return (
    <Button size="sm" variant="light" onClick={() => deleteBridge(bridgeName)}>
      {'x'}
    </Button>
  );
});
