import React, {
  createContext,
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Settings from './pages/settings';
import Dashboard from './pages/dashboard';
import SettingsAWS from './pages/settings/aws';
import SettingsTokens from './pages/settings/tokens';
import SettingsCompetitors from './pages/settings/competitors';
import SettingsBridges from './pages/settings/bridges';
import SettingsConfigs from './pages/settings/configs';
import SettingsConfigsJson from './pages/settings/configsJson';
import Transactions from './pages/transactions';
import LatestTransactions from './pages/transactions/latest';
import NewTransaction from './pages/transactions/new';

import {
  AWSGroupScheme,
  BRAIN_EVENTS,
  CheckerRes,
  DBAutomationRules,
  DBBridges,
  DBMiscSettings,
  DBTokens,
  DEFAULT_BRAIN_STATE,
  GO_NODER_EVENTS,
  MODULE_EVENTS,
  MODULE_EVENTS_PARAMS,
  NETWORK,
  NODER_EVENTS,
  SOCKET_CLIENT_RSA_KEYS,
  SOCKET_PATH,
  WORKER_EVENTS,
} from './common';
import { useImmer } from 'use-immer';
import Loader from './components/Loader';

import { useSetEventHandlers } from './helper/EventHandlers';
import SecureLocalStorage from './helper/SecureLocalStorage';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SocketClient, {
  exportPKCS8PrivateKeyPem,
  exportSPKIPublicKeyPem,
  importPKCS8PrivateKeyPem,
  importSPKIPublicKeyPem,
  INTERFACE_SOCKET_CLIENT_RSA_KEYS,
} from './common_custom/SocketClient';
import Logger from './common_custom/Logger';
import Sound from './helper/Sound';
import { Competitors } from './common/types/monitoringTypes';
import {
  CloseAllToast,
  errorToast,
  infoToast,
  promiseToast,
  successToast,
  warnToast,
} from './helper/Toast';

/**
 * Sends a data update event
 */
export function sendUpdateEvent<T extends MODULE_EVENTS>(eventName: T, eventData: MODULE_EVENTS_PARAMS[T]) {
  if (process.env.NODE_ENV === 'production') {
    SocketClient.emitEvent(eventName, eventData);
  } else {
    SocketClient.emitEvent(eventName, eventData);
    // InnerEmitter.emit(eventName, eventData)
  }
}

export type AppContextType = {
  projectName: AppState['projectName']
  devMode: AppState['devMode']
  brainState: AppState['brainState']
  connectedServices: AppState['connectedServices']
  newHeads: AppState['newHeads']
  goNoderNewHeads: AppState['goNoderNewHeads']
  eventerStatsString: AppState['eventerStatsString']
  workerStatsString: AppState['workerStatsString']
  workerAnalyticsString: AppState['workerAnalyticsString']
  workerEventsString: AppState['workerEventsString']
  workerLpStatString: AppState['workerLpStatString']
  miscSettings: AppState['miscSettings']
}
export const AppContext = createContext<AppContextType>({
  projectName: 'DefiTracker 4',
  devMode: false,
  brainState: DEFAULT_BRAIN_STATE,
  connectedServices: {},
  newHeads: {},
  goNoderNewHeads: {},
  eventerStatsString: '',
  workerStatsString: '',
  workerAnalyticsString: '',
  workerEventsString: '',
  workerLpStatString: '',
  miscSettings: undefined,
});

export type AppExpiryContextType = {
  timeValueBeforeDisconnect: AppState['timeValueBeforeDisconnect']
  disconnectButtonVariant: AppState['disconnectButtonVariant']
}
export const AppExpiryContext = createContext<AppExpiryContextType>({
  timeValueBeforeDisconnect: '59:59',
  disconnectButtonVariant: 'light',
});

export type AppState = {
  projectName: string
  devMode: boolean
  msRemainingBeforeDisconnect: number
  timeValueBeforeDisconnect: string
  disconnectButtonVariant: 'light' | 'warning' | 'danger'
  brainState: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.BRAIN_STATE_UPDATE]
  connectedServices: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CONNECTED_SERVICES]

  newHeads: { [key in NETWORK]?: MODULE_EVENTS_PARAMS[NODER_EVENTS.NEW_HEADS] }
  goNoderNewHeads: { [key in NETWORK]?: MODULE_EVENTS_PARAMS[GO_NODER_EVENTS.NEW_BLOCK_TX_DATAS] }
  gasEstimate: { [key in NETWORK]?: MODULE_EVENTS_PARAMS[NODER_EVENTS.GAS_ESTIMATE] }
  balance: {
    [networkName in NETWORK]?: {
      [tokenName: string]: string
    }
  }
  secondaryBalance: {
    [walletAddress: string]: {
      [networkName in NETWORK]?: {
        [tokenName: string]: string
      }
    }
  }

  allTokens: DBTokens
  // newTokens: UnknownTokens
  competitors: Competitors
  archivedTxsFileNames: string[]
  currentArchiveFileName: string | undefined
  archivedTxs: { [key: string]: string }
  bridges: DBBridges
  automationRules?: DBAutomationRules
  awsGroups: AWSGroupScheme[]
  trackingResults: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_ITERATION_RESULT]
  trackingErrors: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_ITERATION_ERROR]
  eventerStatsString: string
  workerStatsString: string
  workerAnalyticsString: string
  workerEventsString: string
  workerLpStatString: string

  miscSettings?: DBMiscSettings
  connectedUsers: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CONNECTED_USERS]

  user: {
    name: string
    accessKey: string
  }

  configs?: {
    transactions: string
    monitoring: string
    'noder-go': string
  }
  configsJson: Record<string, string | undefined>

  checkerRes: CheckerRes
}

export default function App() {
  const [appState, setAppState] = useImmer<AppState>({
    projectName: 'DefiTracker 4',
    devMode: false,
    msRemainingBeforeDisconnect: 60 * 60 * 1000 - 1000,
    timeValueBeforeDisconnect: '59:59',
    disconnectButtonVariant: 'light',
    brainState: DEFAULT_BRAIN_STATE,
    connectedServices: {},

    newHeads: {},
    goNoderNewHeads: {},
    gasEstimate: {},
    balance: {},
    secondaryBalance: {},

    allTokens: {},
    competitors: {},
    archivedTxsFileNames: [],
    currentArchiveFileName: undefined,
    archivedTxs: {},
    bridges: {},
    automationRules: undefined,
    awsGroups: [],
    trackingResults: {},
    trackingErrors: {},
    eventerStatsString: '',
    workerStatsString: '',
    workerAnalyticsString: '',
    workerEventsString: '',
    workerLpStatString: '',

    miscSettings: undefined,
    connectedUsers: {},

    user: {
      name: '',
      accessKey: '',
    },
    configsJson: {},
    checkerRes: {},
  });

  const deferredTrackingResults = useDeferredValue(appState.trackingResults);
  const deferredTrackingErrors = useDeferredValue(appState.trackingErrors);

  const dataToastId = useRef<ReturnType<typeof toast>>('');
  const toastDataUpdate = useCallback((text: string) => {
    // toast.dismiss(dataToastId.current)
    dataToastId.current = successToast(text);
  }, []);

  // Set event handlers
  useSetEventHandlers(setAppState, toastDataUpdate);

  useEffect(() => {
    Logger.init({
      projectName: 'interface2',
    });
  }, []);

  useEffect(() => {
    // @ts-ignore
    window.notifyAll = (msg: string, autoClose?: number | false) => {
      SocketClient.emitEvent(BRAIN_EVENTS.NOTIFICATION_TO_CLIENT, {
        msg,
        type: 'info',
        autoClose,
      });
    };
    // @ts-ignore
    window.sendCommand = (to: string, cmd: string) => {
      SocketClient.emitEvent(MODULE_EVENTS.COMMAND, { to, cmd });
    };
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (appState.msRemainingBeforeDisconnect > 0) {
      console.log('Settings up ping to keep alive interval');

      interval = setInterval(() => {
        fetch(window.location.origin)
          .then(() => {
            console.log('🟢 ping to keep alive success');
          })
          .catch((e) => {
            console.warn('🟠 ping to keep alive error', e);
          });
      }, 1000 * 60);
    }

    return () => {
      clearInterval(interval);
      console.log('Cleared ping to keep alive interval');
    };
  }, [appState.msRemainingBeforeDisconnect]);

  useEffect(() => {
    let dateWhenExpires = new Date(Date.now() + appState.msRemainingBeforeDisconnect);

    const interval = setInterval(() => {
      let msRemaining = dateWhenExpires.getTime() - Date.now();
      let minutes = Math.floor(msRemaining / 1000 / 60);
      let seconds = Math.floor((msRemaining / 1000) % 60);

      if (minutes < 0) minutes = 0;
      if (seconds < 0) seconds = 0;

      setAppState((draft) => {
        draft.timeValueBeforeDisconnect = `${String(minutes).padStart(2, '0')}:${String(
          seconds,
        ).padStart(2, '0')}`;

        if (minutes < 5) {
          draft.disconnectButtonVariant = 'danger';
        } else if (minutes < 10) {
          draft.disconnectButtonVariant = 'warning';
        } else {
          draft.disconnectButtonVariant = 'light';
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [appState.msRemainingBeforeDisconnect]);

  /**
   * Load private key from local storage
   */
  useEffect(() => {
    const username = SecureLocalStorage.getItem('USER_NAME');
    const accessKey = SecureLocalStorage.getItem('USER_ACCESS_KEY');
    if (!accessKey) return;

    setAppState((draft) => {
      draft.user = {
        name: username ?? '',
        accessKey,
      };
    });
  }, []);

  /**
   * Generate key pair
   */
    // const [keyPair, setKeyPair] = useState<ENCRYPTION_WORKER_RESULT["GENERATE_KEYS"]>()
  const [keyPair, setKeyPair] = useState<INTERFACE_SOCKET_CLIENT_RSA_KEYS>();
  const keyGenStartedRef = useRef<boolean>(false);
  useEffect(() => {
    if (keyGenStartedRef.current) return;
    keyGenStartedRef.current = true;

    // const keysPromise = SocketClient.generateKeyPairSync()

    const keysPromise = new Promise<INTERFACE_SOCKET_CLIENT_RSA_KEYS>(async (resolve) => {
      await new Promise((r) => setTimeout(r, 100));

      if (window.crypto.subtle === undefined) {
        return errorToast(
          'Unable to generate RSA keys. window.crypto.subtle is undefined. Secure https context required.',
          {
            autoClose: false,
            closeButton: false,
            closeOnClick: false,
          },
        );
      }

      // Try to load keys locally
      const localKeys = SecureLocalStorage.getItem('RSA_KEY_PAIR');
      if (localKeys) {
        try {
          const keys: INTERFACE_SOCKET_CLIENT_RSA_KEYS = {
            keySize: localKeys.keySize,
            time: localKeys.time,
            public: await importSPKIPublicKeyPem(localKeys.public),
            private: await importPKCS8PrivateKeyPem(localKeys.private),
          };
          console.log('Loaded rsa keys from localStorage');
          return resolve(keys);
        } catch (e) {
          SecureLocalStorage.removeItem('RSA_KEY_PAIR');
        }
      }

      // Generate new keys
      const keys = await SocketClient.generateKeyPairSync();
      const localKeysToSave: SOCKET_CLIENT_RSA_KEYS = {
        keySize: keys.keySize,
        time: keys.time,
        public: await exportSPKIPublicKeyPem(keys.public),
        private: await exportPKCS8PrivateKeyPem(keys.private),
      };
      SecureLocalStorage.setItem('RSA_KEY_PAIR', localKeysToSave);
      console.log(`Generated rsa keys in ${keys.time}ms`);
      return resolve(keys);
    });

    promiseToast(
      keysPromise,
      {
        pending: 'Generating RSA key pair...',
        success: 'RSA key pair generated',
        error: 'Failed to generate RSA key pair',
      },
      {
        autoClose: 1000,
        closeOnClick: false,
        closeButton: false,
      },
    )
      .then((keys: any) => {
        SocketClient.init({
          baseUrl: process.env.REACT_APP_SOCKET_BASE_URL || 'http://localhost:3000',
          socketPath: SOCKET_PATH.INTERFACE,
          keyPair: keys,
        });
        setKeyPair(keys);
      })
      .catch((e) => {
      });
  }, []);

  /**
   * Auth / ReAuth handler
   */
  const authToastId = useRef<ReturnType<typeof toast>>('');
  useEffect(() => {
    if (!keyPair) return;

    toast.dismiss(authToastId.current);
    if (!appState.user.accessKey) {
      authToastId.current = infoToast('Please enter access key to authenticate', {
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
      });
    } else {
      const connect = async () => {
        const authPromise = Promise.all([
          SocketClient.connect(
            [
              MODULE_EVENTS.CLIENT_AUTH_SUCCESS,
              MODULE_EVENTS.INIT_INTERFACE,
              MODULE_EVENTS.INTERFACE_EVENT_CACHE,

              MODULE_EVENTS.BRAIN_STATE_UPDATE,
              MODULE_EVENTS.AWS_GROUPS_UPDATE_PROCESSED,
              MODULE_EVENTS.CONNECTED_USERS,
              MODULE_EVENTS.CONNECTED_SERVICES,
              MODULE_EVENTS.UPDATE_MISC_SETTINGS_PROCESSED,
              MODULE_EVENTS.CREATE_TOKEN_PROCESSED,
              MODULE_EVENTS.UPDATE_TOKEN_PROCESSED,
              MODULE_EVENTS.DELETE_TOKEN_PROCESSED,
              MODULE_EVENTS.CREATE_BRIDGE_PROCESSED,
              MODULE_EVENTS.UPDATE_BRIDGE_PROCESSED,
              MODULE_EVENTS.DELETE_BRIDGE_PROCESSED,
              MODULE_EVENTS.UPDATE_AUTOMATION_RULES_PROCESSED,

              MODULE_EVENTS.NOTIFICATION_TO_CLIENT,

              MODULE_EVENTS.NEW_HEADS,
              MODULE_EVENTS.GAS_ESTIMATE,
              MODULE_EVENTS.BALANCE_UPDATE,
              MODULE_EVENTS.SECONDARY_BALANCE_UPDATE,

              MODULE_EVENTS.NEW_BLOCK_TX_DATAS,
              MODULE_EVENTS.BLOCK_TX_EXTRACT_ERROR,

              MODULE_EVENTS.EVENTER_STATS_STRING,

              MODULE_EVENTS.WORKER_STATS_STRING,
              MODULE_EVENTS.WORKER_ANALYTICS_STRING,
              MODULE_EVENTS.WORKER_EVENTS_STRING,
              MODULE_EVENTS.WORKER_LP_STAT_STRING,
              MODULE_EVENTS.WORKER_ITERATION_RESULT,
              MODULE_EVENTS.WORKER_ITERATION_ERROR,

              MODULE_EVENTS.TRADE_CREATED,
              MODULE_EVENTS.TRANSACTIONS_SENT,
              MODULE_EVENTS.TRANSACTIONS_CREATED,
              MODULE_EVENTS.TRANSACTIONS_CONFIRMED,
              MODULE_EVENTS.TRADE_COMPLETED,

              MODULE_EVENTS.MONITORING_ON_UPDATE_COMPETITOR_PROCESSED,
              MODULE_EVENTS.MONITORING_ON_UPDATE_UNKNOWN_TOKEN_PROCESSED,

              MODULE_EVENTS.FETCH_TXS_CONFIG_RESULT,
              MODULE_EVENTS.FETCH_NODER_GO_CONFIG_RESULT,
              MODULE_EVENTS.FETCH_MONITORING_CONFIG_RESULT,

              MODULE_EVENTS.LIST_JSON_CONFIGS_RESULT,
              MODULE_EVENTS.REQUEST_JSON_CONFIG_RESULT,
              MODULE_EVENTS.UPDATE_JSON_CONFIG_RESULT,

              MODULE_EVENTS.ADD_CHECKER_RES,
              MODULE_EVENTS.DELETE_CHECKER_RES,
              MODULE_EVENTS.COMMAND,
            ],
            appState.user.accessKey,
            () => {
              Sound.play('error');
              setAppState((draft) => {
                draft.msRemainingBeforeDisconnect = 0;
              });
              warnToast('Disconnected from server. Reconnecting...', {
                autoClose: false,
                closeOnClick: true,
                closeButton: true,
              });
              connect();
            },
          ).then(() => {
            Sound.play('plop');
            setTimeout(() => {
              Sound.play('plop');
            }, 200);
          }),
          new Promise((r) => setTimeout(r, 100)),
        ]);
        promiseToast(
          authPromise,
          {
            pending: 'Connecting to server...',
            success: 'Connected successfully',
            error: 'Failed to authenticate',
          },
          {
            autoClose: 1000,
            closeOnClick: false,
            closeButton: false,
          },
        )
          .catch((e: any) => {
            errorToast('Failed to authenticate with server', {
              autoClose: false,
              closeOnClick: false,
              closeButton: false,
            });
          });
      };
      connect();
    }
  }, [appState.user.accessKey, keyPair, setAppState]);

  return (
    <AppContext.Provider
      value={{
        projectName: appState.projectName,
        devMode: appState.devMode,
        brainState: appState.brainState,
        connectedServices: appState.connectedServices,
        newHeads: appState.newHeads,
        goNoderNewHeads: appState.goNoderNewHeads,
        eventerStatsString: appState.eventerStatsString,
        workerStatsString: appState.workerStatsString,
        workerAnalyticsString: appState.workerAnalyticsString,
        workerEventsString: appState.workerEventsString,
        workerLpStatString: appState.workerLpStatString,
        miscSettings: appState.miscSettings,
      }}
    >
      <AppExpiryContext.Provider
        value={{
          timeValueBeforeDisconnect: appState.timeValueBeforeDisconnect,
          disconnectButtonVariant: appState.disconnectButtonVariant,
        }}
      >
        <Loader projectName="DefiTracker 4" buildNumber="0.0.1" />
        <div style={{ position: 'relative' }}>
          <ToastContainer
            position="top-right"
            closeButton={false}
            newestOnTop
            closeOnClick
            pauseOnHover
          />
          <CloseAllToast />
        </div>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Dashboard
                  workersRunning={appState.brainState.workersRunning}
                  workersStartedBy={appState.brainState.workersStartedBy}
                  pinnedTokens={appState.brainState.pinnedTokens}
                  autosellPins={appState.brainState.autosellPins}
                  wethPriceUSD={appState.brainState.wethPriceUSD}
                  nativePriceUSD={appState.brainState.nativePriceUSD}
                  newHeads={appState.newHeads}
                  allTokens={appState.allTokens}
                  bridges={appState.bridges}
                  trackingResults={deferredTrackingResults}
                  trackingErrors={deferredTrackingErrors}
                  selectedAccount={appState.user}
                  miscSettings={appState.miscSettings}
                  connectedUsers={appState.connectedUsers}
                  gasEstimate={appState.gasEstimate}
                  balance={appState.balance}
                  secondaryBalance={appState.secondaryBalance}
                  setAppState={setAppState}
                  automationRules={appState.automationRules}
                  checkerRes={appState.checkerRes}
                />
              }
            />

            <Route path="/tx" element={<Transactions />} />

            <Route
              path="/tx/latest"
              element={<LatestTransactions tokens={appState.allTokens} />}
            />

            <Route path="/tx/new"
                   element={<NewTransaction tokens={appState.allTokens} />}
            />

            <Route path="/settings" element={<Settings />} />

            <Route
              path="/settings/aws"
              element={<SettingsAWS awsGroups={appState.awsGroups} />}
            />

            <Route
              path="/settings/configs"
              element={
                <SettingsConfigs
                  configs={appState.configs}
                  setAppState={setAppState}
                />
              }
            />

            <Route
              path="/settings/configsJson"
              element={
                <SettingsConfigsJson
                  configsJson={appState.configsJson}
                  setAppState={setAppState}
                />
              }
            />

            <Route
              path="/settings/tokens"
              element={
                <SettingsTokens
                  allTokens={appState.allTokens}
                  bridges={appState.bridges}
                  miscSettings={appState.miscSettings}
                />
              }
            />

            <Route
              path="/settings/competitors"
              element={<SettingsCompetitors competitors={appState.competitors} />}
            />

            <Route
              path="/settings/bridges"
              element={
                <SettingsBridges allTokens={appState.allTokens}
                                 bridges={appState.bridges} />
              }
            />
          </Routes>
        </Router>
      </AppExpiryContext.Provider>
    </AppContext.Provider>
  );
}
