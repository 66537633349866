import * as Sounds from '../assets/sounds';

const volumes: { [key in keyof typeof Sounds]: number } = {
  dindilin: 1,
  beep: 1,
  error: 1,
  plop: 0.5,
  call: 1,
  profit: 1,
};

export default class Sound {
  static play(sound: keyof typeof Sounds) {
    try {
      const audio = new Audio(Sounds[sound]);
      const sliderVolume = parseInt(localStorage.getItem("volumeSlider") || "100")
      const newVolume = volumes[sound] * (sliderVolume / 100)
      audio.volume = newVolume;
      audio.play().catch(() => {});
    } catch (e) {
    }
  }
}
