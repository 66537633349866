import { memo, useEffect, useState } from 'react';
import { Button, Col, Container, Row, Stack } from 'react-bootstrap';
import AceEditor from 'react-ace';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/snippets/python';
import { Updater } from 'use-immer';
import 'ace-builds/src-noconflict/theme-monokai';

import Header_C, {
  HeaderBlockNumSection_C,
  HeaderConnectedServicesSection_C,
  HeaderSection,
  HeaderStartStopSection_C,
} from '../../../components/Header';
import Content, { ContentSection, ContentSpacer } from '../../../components/Content';
import Sidebar, {
  SidebarRootNavLinks,
  SidebarSection,
} from '../../../components/Sidebar';
import { AppState, sendUpdateEvent } from '../../../App';
import { SidebarSettingsNavLinks } from '..';
import {
  fetchMonitoringConf,
  fetchNoderGoConf,
  fetchTxsConf,
  updateMonitoringConf,
  updateNoderGoConf,
  updateTxsConf,
} from './actions';
import { MODULE_EVENTS } from '../../../common';

/**
 * Configs settings page
 *  Route '/settings/configs'
 */
type SettingsConfigsProps = {
  configs?: AppState['configs']
  setAppState: Updater<AppState>
}
export default memo(function SettingsConfigs(
  {
    configs,
    setAppState,
  }: SettingsConfigsProps) {
  const [currentConfName, setCurrentConfName] = useState('transactions');

  useEffect(() => {
    fetchTxsConf();
    fetchNoderGoConf();
    fetchMonitoringConf();
    setTimeout(() => {
      if (!configs || !(configs as any)[currentConfName]) {
        fetchTxsConf();
        fetchNoderGoConf();
        fetchMonitoringConf();
      }
    }, 2000);
  }, []);

  return (
    <>
      <Header_C>
        <HeaderSection>
          <HeaderStartStopSection_C />
        </HeaderSection>
        <HeaderSection justifyContent="center">
          <HeaderConnectedServicesSection_C />
        </HeaderSection>
        <HeaderSection justifyContent="end">
          <HeaderBlockNumSection_C />
        </HeaderSection>
      </Header_C>

      <Container fluid className="main">
        <Row>
          <Col className="sidebarContainer">
            <Sidebar>
              <SidebarRootNavLinks />
              <SidebarSettingsNavLinks />

              <SidebarSection renderP gap={1}>
                <SideButtons
                  confNames={['transactions', 'monitoring', 'noder-go']}
                  currentConfName={currentConfName}
                  setCurrentConfName={setCurrentConfName}
                />
              </SidebarSection>
            </Sidebar>
          </Col>
          <Col className="contentContainer">
            <Content>
              <ContentSection>
                {!!configs && !!(configs as any)[currentConfName] && (
                  <div
                    style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <div style={{ width: '60%', marginRight: 24 }}>
                      <AceEditor
                        mode="python"
                        theme="monokai"
                        value={(configs as any)[currentConfName]}
                        style={{
                          width: '100%',
                          height: '80vh',
                        }}
                        onChange={(val) => {
                          const currentConf = val;
                          setAppState((draft: any) => {
                            ;(draft.configs as any)[currentConfName] = currentConf;
                          });
                        }}
                        name="conf-editor"
                        editorProps={{ $blockScrolling: true }}
                      />
                      <Button
                        size="sm"
                        variant="success"
                        style={{
                          width: 200,
                          marginTop: 24,
                        }}
                        onClick={() => {
                          switch (currentConfName) {
                            case 'transactions':
                              updateTxsConf((configs as any)[currentConfName]).catch(sendConfigParseErrToUI);
                              break;
                            case 'noder-go':
                              updateNoderGoConf((configs as any)[currentConfName]).catch(sendConfigParseErrToUI);
                              break;
                            case 'monitoring':
                              updateMonitoringConf((configs as any)[currentConfName]).catch(sendConfigParseErrToUI);
                              break;
                          }
                        }}
                      >
                        Update Config
                      </Button>
                    </div>
                    {currentConfName === 'transactions' ? <TxsDesc /> : null}
                    {currentConfName === 'noder-go' ? <NoderDesc /> : null}
                    {currentConfName === 'monitoring' ? <MonitoringDesc /> : null}
                  </div>
                )
                }
              </ContentSection>
              <ContentSection>
                <ContentSpacer height={100} />
              </ContentSection>
            </Content>
          </Col>
        </Row>
      </Container>
    </>
  );
});

/**
 *
 */
const sendConfigParseErrToUI = () => sendUpdateEvent(
  MODULE_EVENTS.NOTIFICATION_TO_CLIENT,
  {
    type: 'error',
    msg: 'Failed to parse config, please check syntax',
    autoClose: 5000,
  },
);

/**
 *
 */
type SideButtonsProps = {
  confNames: string[]
  currentConfName: string
  setCurrentConfName: any
}
const SideButtons = memo(function SideButtons(
  {
    confNames,
    currentConfName,
    setCurrentConfName,
  }: SideButtonsProps) {
  return (
    <Stack direction="vertical" gap={3}>
      {confNames.map((confName) => {
        const name = confName === 'transactions' ? 'Transactions' : confName === 'monitoring' ? 'Monitoring' : 'Noder';
        return (
          <Button
            key={confName}
            size="sm"
            variant={confName === currentConfName ? 'success' : 'secondary'}
            onClick={() => setCurrentConfName(confName)}
          >
            {name}
          </Button>
        );
      })}
    </Stack>
  );
});

const TxsDesc = () => (
  <div style={{ width: '40%', fontSize: 14 }}>
    <h5>Description for some of the config fields</h5>
    <br />
    <p>
      <b>gas</b> - gas rules for specific networks and tx types.
    </p>
    <br />

    <p>
      <b>nonceReuse</b> - windowed txs flow (only supported by
      Ethereum).
    </p>
    <br />

    <p>
      <b>eventer</b> - enables eventer logs waiting before fetching
      receipts and retry logic on buy and sell.
    </p>
    <br />

    <p>
      <b>bribe</b> - networks bribe.
      <b>cheapBribe</b> - networks bribe for tokens that should use
      cheap value.
      <b>expensiveBribe</b> - networks bribe for tokens that should use
      expensive value.
    </p>
    <br />

    <p>
      <b>zroCheckerValueUSD</b> - ZRO bridge value in USD that we will
      use instead of actual ZRO fee price during iteration check for
      profit.
    </p>
  </div>
);

const NoderDesc = () => (
  <div style={{ width: '40%', fontSize: 14 }}>
    <h5>Description for some of the config fields</h5>
    <br />
    <p>
      <b>networks</b> - network configuration for tracking.
    </p>
    <br />

    <p>
      <b>networks.rpc</b> - network HTTP RPC url.
    </p>
    <br />

    <p>
      <b>networks.ws</b> - network WS RPC url.
    </p>
    <br />

    <p>
      <b>networks.modules</b> - enable/disable network module.
    </p>
    <br />
  </div>
);

const MonitoringDesc = () => (
  <div style={{ width: '40%', fontSize: 14 }}>
    <h5>Description for some of the config fields</h5>
    <br />
    <p>
      <b>scannerApiKeys</b> - network scanner api keys.
    </p>
    <br />

    <p>
      <b>networksRpcUrls</b> - network rpc urls.
    </p>
    <br />

    <p>
      <b>bridge</b> - Bridge tokens syncer config.
    </p>
    <p>
      <b>bridge.addresses</b> - list of networks and addresses for monitoring.
    </p>
    <br />

    <p>
      <b>sniperbot</b> - Sniperbot tokens syncer config.
    </p>
    <p>
      <b>sniperbot.providerUrl</b> - main ethereum rpc url.
    </p>
    <p>
      <b>sniperbot.fallbackProviderUrl</b> - additional ethereum rpc url.
    </p>
    <p>
      <b>sniperbot.bots</b> - list of sniperbots configuration values.
    </p>
    <br />

    <p>
      <b>telegram</b> - telegram config for bridge and sniperbot channels.
    </p>
    <br />
  </div>
);
