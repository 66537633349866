import { memo, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import BigNumber from 'bignumber.js';
import { cloneDeep } from 'lodash';

import Header_C, {
  HeaderBlockNumSection_C,
  HeaderConnectedServicesSection_C,
  HeaderSection,
  HeaderStartStopSection_C,
} from '../../components/Header';
import Content from '../../components/Content';
import Sidebar, {
  SidebarFilterButton,
  SidebarFilterRow,
  SidebarRootNavLinks,
} from '../../components/Sidebar';
import { SwalStyled } from '../../helper/Popup';
import { sendUpdateEvent } from '../../App';
import {
  BRIDGE,
  MODULE_EVENTS,
  NETWORK,
  sanitizeDecimalStr,
  Sell,
  SELL_STATUS,
} from '../../common';
import { createRoot, Root } from 'react-dom/client';

/**
 * Filter data
 */
export type TransactionsFilterData = {
  success: 'true' | 'false' | 'none'
}
export const defaultFilterData: TransactionsFilterData = {
  success: 'none',
};

/**
 * Transactions page
 *  Route '/tx'
 */
export default memo(function Transactions() {
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * Redirect user to specific setting page
   */
  useEffect(() => {
    const { pathname } = location;

    if (pathname.replaceAll('/', '') === 'tx') {
      navigate('/tx/latest');
    }
  }, [location.pathname]);

  /**
   * Render is here only to keep the sidebar with some items
   *  so that the page is not blank while user is being redirected
   */
  return (
    <>
      <Header_C>
        <HeaderSection>
          <HeaderStartStopSection_C />
        </HeaderSection>
        <HeaderSection justifyContent="center">
          <HeaderConnectedServicesSection_C />
        </HeaderSection>
        <HeaderSection justifyContent="end">
          <HeaderBlockNumSection_C />
        </HeaderSection>
      </Header_C>

      <Container fluid className="main">
        <Row>
          <Col className="sidebarContainer">
            <Sidebar>
              <SidebarRootNavLinks />
            </Sidebar>
          </Col>
          <Col className="contentContainer">
            <Content>
              <Content>{/* should be empty  */}</Content>
            </Content>
          </Col>
        </Row>
      </Container>
    </>
  );
});

/**
 *
 */
export const SideButtons = memo(function SideButtons() {
  const navigate = useNavigate();
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Button style={{ marginBottom: '8px' }} size="sm" variant="secondary"
              onClick={() => navigate('/tx/new')}>
        Create new transaction
      </Button>
      <Button style={{ marginBottom: '8px' }} size="sm" variant="secondary"
              onClick={() => handleRetrySellOrClaimClick(false)}>
        Retry Sell
      </Button>
      <Button style={{ marginBottom: '8px' }} size="sm" variant="secondary"
              onClick={() => handleRetrySellOrClaimClick(true)}>
        Retry Claim
      </Button>
    </div>
  );
});

export const handleRetrySellOrClaimClick = async (claim: boolean, initValue?: string) => {
  SwalStyled.fire({
    title: `Retry ${claim ? 'Claim' : 'Sell'}`,
    html: '<div id="retry-claim-form"></div>',
    showConfirmButton: false,
    showCloseButton: true,
    width: '400px',
    inputValue: initValue ?? '',
    didOpen: () => {
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#retry-claim-form',
      ) as HTMLElement;

      if (formContainer) {
        // Create a root.
        const root: Root = createRoot(formContainer);

        // Render the React component inside the Swal modal
        root.render(
          <RetrySellOrClaimForm
            claim={claim}
            initialTradeId={initValue}
            onClose={() => SwalStyled.close()}
          />,
        );

        // Store the root instance to unmount later
        (SwalStyled as any)._retryClaimRoot = root;
      }
    },
    willClose: () => {
      // Clean up the React component when modal is closed
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#retry-claim-form',
      ) as HTMLElement;

      if (formContainer && (SwalStyled as any)._retryClaimRoot) {
        const root: Root = (SwalStyled as any)._retryClaimRoot;
        root.unmount();
        delete (SwalStyled as any)._retryClaimRoot;
      }
    },
  });
};

interface RetrySellOrClaimFormProps {
  claim: boolean;
  initialTradeId?: string;
  onClose: () => void;
}

const RetrySellOrClaimForm: React.FC<RetrySellOrClaimFormProps> = (
  {
    claim,
    initialTradeId,
    onClose,
  }) => {
  const [tradeId, setTradeId] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setTradeId(initialTradeId ?? tradeId ?? '');
  }, [initialTradeId]);

  const handleRetry = () => {
    const trimmedTradeId = tradeId.trim();
    const tradeIdNumber = Number(trimmedTradeId);

    // Validation: Trade ID must be a number and >= 1729105791000
    if (
      !trimmedTradeId ||
      isNaN(tradeIdNumber) ||
      tradeIdNumber < 1729105791000
    ) {
      setError('Wrong Trade ID');
      return;
    }

    // Send the update event
    const event = claim
      ? MODULE_EVENTS.RETRY_CLAIM
      : MODULE_EVENTS.RETRY_SELL;
    sendUpdateEvent(event, {
      tradeId: trimmedTradeId,
      public: isPublic,
    });

    // Close the modal
    onClose();
  };

  return (
    <Form>
      <Form.Group controlId="tradeId" style={{ marginBottom: '12px' }}>
        <Form.Control
          type="text"
          placeholder="Enter Trade ID"
          value={tradeId}
          onChange={(e) => {
            setTradeId(e.target.value);
            if (error) setError('');
          }}
          isInvalid={!!error}
        />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Form.Group>

      {claim && (
        <Form.Group controlId="publicToggle" style={{
          maxWidth: 200,
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'end',
          marginBottom: '36px',
        }}>
          <Form.Check
            type="checkbox"
            label="Public (will skip sell)"
            checked={isPublic}
            onChange={(e) => setIsPublic(e.target.checked)}
          />
        </Form.Group>
      )}

      <Button variant="primary" onClick={handleRetry}>
        Retry
      </Button>
    </Form>
  );
};

export const handleUpdateSell = async (data: Sell, shouldSendBack: boolean) => {
  SwalStyled.fire({
    title: `Update Sell ${data.tradeId}`,
    html: '<div id="update-sell-form"></div>',
    showConfirmButton: false,
    showCloseButton: true,
    width: '1300px',
    didOpen: () => {
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#update-sell-form',
      ) as HTMLElement;

      if (formContainer) {
        // Create a root.
        const root: Root = createRoot(formContainer);

        // Render the React component inside the Swal modal
        root.render(
          <UpdateSellForm
            data={data}
            shouldSendBack={shouldSendBack}
            onClose={() => SwalStyled.close()}
          />,
        );

        // Store the root instance to unmount later
        (SwalStyled as any)._updateSellRoot = root;
      }
    },
    willClose: () => {
      // Clean up the React component when modal is closed
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#update-sell-form',
      ) as HTMLElement;

      if (formContainer && (SwalStyled as any)._updateSellRoot) {
        const root: Root = (SwalStyled as any)._updateSellRoot;
        root.unmount();
        delete (SwalStyled as any)._updateSellRoot;
      }
    },
  });
};

interface UpdateSellFormProps {
  data: Sell;
  shouldSendBack: boolean;
  onClose: () => void;
}

const UpdateSellForm: React.FC<UpdateSellFormProps> = (
  {
    data,
    shouldSendBack,
    onClose,
  }) => {
  const wrongBridgeValue = 'WRONG BRIDGE';

  const [updates, setUpdates] = useState<Sell>(cloneDeep(data));
  const [amount, setAmount] = useState(`${data.amount ?? 0}`);
  const [minAmount, setMinAmount] = useState(`${data.minAmountOut ?? 0}`);
  const [maxAmountTokens, setMaxAmountTokens] = useState(`${data.maxAmountIn ?? 0}`);
  const [bribe, setBribe] = useState(`${data.bribe ?? 0}`);
  const [gasPrice, setGasPrice] = useState(`${data.gasPriceGwei ?? 0}`);
  const [err, setErr] = useState<string | null>(null);

  const bridges = useMemo(() => Object.values(BRIDGE), []);
  const networks = useMemo(() => Object.values(NETWORK), []);
  const sellStatuses = useMemo(() => Object.values(SELL_STATUS), []);

  useEffect(() => {
    const correctBridge = bridges.find(b => updates.bridge);
    if (!correctBridge) {
      setUpdates({ ...updates, bridge: wrongBridgeValue as any });
    }
    if (shouldSendBack && !updates.networkSendBack) {
      setUpdates({ ...updates, networkSendBack: updates.networkSell });
    }
  }, [updates]);

  const isWrongBridge = (updates.bridge as any) === wrongBridgeValue;

  const handleUpdate = () => {
    const minAmountOut = Number(minAmount);
    const maxAmountIn = Number(maxAmountTokens);
    const gasPriceWei = Number(gasPrice);

    setErr(null);
    if (BigNumber(amount).isNaN()) {
      setErr('wrong amount value');
      return;
    }
    if (Number.isNaN(Number(updates.balance))) {
      setErr('wrong balance value');
      return;
    }
    if (Number.isNaN(minAmountOut)) {
      setErr('wrong min amount out value');
      return;
    }
    if (Number.isNaN(maxAmountIn)) {
      setErr('wrong max amount in value');
      return;
    }
    if (Number.isNaN(Number(bribe))) {
      setErr('wrong bribe value');
      return;
    }
    if (Number.isNaN(gasPriceWei)) {
      setErr('wrong gas price value');
      return;
    }

    const amountShifted = sanitizeDecimalStr(
      BigNumber(amount)
        .div(10 ** updates.token.tokenDecim)
        .toFixed(updates.token.tokenDecim),
    );
    const eventData = {
      ...data, ...updates,
      amount,
      amountShifted,
      minAmountOut,
      gasPriceWei,
      maxAmountIn,
      bribe: Number(bribe),
    };
    sendUpdateEvent(MODULE_EVENTS.UPDATE_SELL, eventData);
    onClose();
  };

  const labelStyle: React.CSSProperties = {
    width: '240px',
    textAlign: 'left',
    marginTop: '8px',
  };
  const controlStyle = {
    display: 'flex',
    marginBottom: '12px',
    justifyContent: 'space-between',
    alignItems: 'space-between',
  };

  return (
    <Form>
      {!!err && (
        <Form.Text
          style={{ ...controlStyle, color: 'red', fontWeight: 'bold' }}>{err}</Form.Text>
      )}
      <div style={{ display: 'flex' }}>
        <div style={{
          display: 'flex', flexDirection: 'column', width: '50%',
          marginRight: '12px',
        }}>
          <Form.Group id="update-sell-bridge-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-bridge-select"
                        style={labelStyle}>Bridge</Form.Label>
            <Form.Select
              id="update-sell-bridge-select"
              value={updates.bridge}
              style={{ color: isWrongBridge ? 'red' : 'inherit' }}
              onChange={(e) => {
                setUpdates({ ...updates, bridge: e.target.value as BRIDGE });
              }}
            >
              {isWrongBridge && <option key={wrongBridgeValue}
                                        value={wrongBridgeValue}>{wrongBridgeValue}</option>}
              {bridges.map(b => <option key={b} value={b}>{b}</option>)}
            </Form.Select>
          </Form.Group>

          <Form.Group id="update-sell-hash-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-hash-control" style={labelStyle}>Bridge
              Hash</Form.Label>
            <Form.Control
              id="update-sell-hash-control"
              type="text"
              value={updates.bridgeTxHash}
              onChange={(e) => {
                setUpdates({ ...updates, bridgeTxHash: e.target.value });
              }}
            />
          </Form.Group>

          <Form.Group id="update-sell-approve-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-approve-control" style={labelStyle}>Bridge
              Approve</Form.Label>
            <Form.Control
              id="update-sell-approve-control"
              type="text"
              value={updates.bridgeApproveTarget}
              onChange={(e) => {
                setUpdates({ ...updates, bridgeApproveTarget: e.target.value });
              }}
            />
          </Form.Group>

          <Form.Group id="update-sell-network-buy-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-network-buy-select" style={labelStyle}>Network
              Buy</Form.Label>
            <Form.Select
              id="update-sell-network-buy-select"
              value={updates.networkBuy}
              onChange={(e) => {
                setUpdates({ ...updates, networkBuy: e.target.value as NETWORK });
              }}
            >
              {networks.map(n => <option key={n} value={n}>{n}</option>)}
            </Form.Select>
          </Form.Group>

          <Form.Group id="update-sell-network-sell-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-network-sell-select" style={labelStyle}>Network
              Sell</Form.Label>
            <Form.Select
              id="update-sell-network-sell-select"
              value={updates.networkSell}
              onChange={(e) => {
                setUpdates({ ...updates, networkSell: e.target.value as NETWORK });
              }}
            >
              {networks.map(n => <option key={n} value={n}>{n}</option>)}
            </Form.Select>
          </Form.Group>

          {shouldSendBack &&
            <Form.Group id="update-sell-network-send-group" style={controlStyle}>
              <Form.Label htmlFor="update-sell-network-send-select" style={labelStyle}>Network
                Send Back</Form.Label>
              <Form.Select
                id="update-sell-network-send-select"
                value={updates.networkSendBack}
                onChange={(e) => {
                  setUpdates({ ...updates, networkSendBack: e.target.value as NETWORK });
                }}
              >
                {networks.map(n => <option key={n} value={n}>{n}</option>)}
              </Form.Select>
            </Form.Group>
          }

          <Form.Group id="update-sell-balance-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-balance-control"
                        style={labelStyle}>Balance</Form.Label>
            <Form.Control
              id="update-sell-balance-control"
              type="text"
              value={`${updates.balance}` ?? '0'}
              onChange={(e) => {
                setUpdates({ ...updates, balance: e.target.value });
              }}
            />
          </Form.Group>

          <Form.Group id="update-sell-amount-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-amount-control"
                        style={labelStyle}>Amount</Form.Label>
            <Form.Control
              id="update-sell-amount-control"
              type="text"
              value={`${amount}` ?? '0'}
              onChange={(e) => {
                const val = Number(e.target.value);
                if (Number.isNaN(val)) {
                  return;
                }
                setAmount(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group id="update-sell-min-amount-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-min-amount-control" style={labelStyle}>Min
              Amount</Form.Label>
            <Form.Control
              id="update-sell-min-amount-control"
              type="text"
              value={minAmount}
              onChange={(e) => {
                setMinAmount(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group id="update-sell-max-amount-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-max-amount-control" style={labelStyle}>Max
              Tokens Amount</Form.Label>
            <Form.Control
              id="update-sell-max-amount-control"
              type="text"
              value={maxAmountTokens}
              onChange={(e) => {
                setMaxAmountTokens(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group id="update-sell-bribe-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-bribe-control"
                        style={labelStyle}>Bribe</Form.Label>
            <Form.Control
              id="update-sell-bribe-control"
              type="text"
              value={bribe}
              onChange={(e) => {
                setBribe(e.target.value);
              }}
            />
          </Form.Group>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          width: '50%',
          marginLeft: '12px',
        }}>
          <Form.Group id="update-sell-gas-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-gas-control" style={labelStyle}>Gas
              Price</Form.Label>
            <Form.Control
              id="update-sell-gas-control"
              type="text"
              value={gasPrice}
              onChange={(e) => {
                setGasPrice(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group id="update-transfer-remainder-group" style={controlStyle}>
            <Form.Label htmlFor="update-transfer-remainder-control" style={labelStyle}>Remainder
              To</Form.Label>
            <Form.Control
              id="update-transfer-remainder-control"
              type="text"
              value={updates.transferRemainderTo ?? ''}
              onChange={(e) => {
                setUpdates({
                  ...updates,
                  transferRemainderTo: e.target.value,
                });
              }}
            />
          </Form.Group>

          <Form.Group id="update-sell-token-name-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-token-name-control" style={labelStyle}>Token
              Name</Form.Label>
            <Form.Control
              id="update-sell-token-name-control"
              type="text"
              value={updates.token.tokenName}
              onChange={(e) => {
                setUpdates({
                  ...updates,
                  token: { ...updates.token, tokenName: e.target.value },
                });
              }}
            />
          </Form.Group>

          <Form.Group id="update-sell-token-buy-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-token-buy-control" style={labelStyle}>Token
              Buy</Form.Label>
            <Form.Control
              id="update-sell-token-buy-control"
              type="text"
              value={updates.token.tokenBuyAddr}
              onChange={(e) => {
                setUpdates({
                  ...updates,
                  token: { ...updates.token, tokenBuyAddr: e.target.value },
                });
              }}
            />
          </Form.Group>

          <Form.Group id="update-sell-token-sell-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-token-sell-control" style={labelStyle}>Token
              Sell</Form.Label>
            <Form.Control
              id="update-sell-token-sell-control"
              type="text"
              value={updates.token.tokenSellAddr}
              onChange={(e) => {
                setUpdates({
                  ...updates,
                  token: { ...updates.token, tokenSellAddr: e.target.value },
                });
              }}
            />
          </Form.Group>

          <Form.Group id="update-sell-token-decim-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-token-decim-control" style={labelStyle}>Token
              Decimals</Form.Label>
            <Form.Control
              id="update-sell-token-decim-control"
              type="text"
              value={`${updates.token.tokenDecim}`}
              onChange={(e) => {
                const val = Number(e.target.value);
                if (Number.isNaN(val)) {
                  return;
                }
                setUpdates({
                  ...updates,
                  token: { ...updates.token, tokenDecim: val },
                });
              }}
            />
          </Form.Group>

          <Form.Group id="update-sell-status-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-status-select" style={labelStyle}>Sell
              Status</Form.Label>
            <Form.Select
              id="update-sell-status-select"
              value={updates.sellStatus}
              onChange={(e) => {
                setUpdates({ ...updates, sellStatus: e.target.value as SELL_STATUS });
              }}
            >
              {sellStatuses.map(s => <option key={s} value={s}>{s}</option>)}
            </Form.Select>
          </Form.Group>

          <Form.Group id="update-sell-claim-status-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-claim-status-select" style={labelStyle}>Claim
              Status</Form.Label>
            <Form.Select
              id="update-sell-claim-status-select"
              value={updates.claimStatus}
              onChange={(e) => {
                setUpdates({ ...updates, claimStatus: e.target.value as SELL_STATUS });
              }}
            >
              {sellStatuses.map(s => <option key={s} value={s}>{s}</option>)}
            </Form.Select>
          </Form.Group>

          <Form.Group id="update-sell-claim-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-claim-control"
                        style={labelStyle}>Claimable</Form.Label>
            <Form.Check
              id="update-sell-claim-control"
              type="checkbox"
              checked={updates.isClaimable ?? false}
              onChange={(e) => {
                setUpdates({ ...updates, isClaimable: e.target.checked });
              }}
            />
          </Form.Group>

          <Form.Group id="update-sell-skip-sell-group" style={controlStyle}>
            <Form.Label htmlFor="update-sell-skip-sell-control" style={labelStyle}>Skip
              Sell</Form.Label>
            <Form.Check
              id="update-sell-skip-sell-control"
              type="checkbox"
              checked={updates.skipSell ?? false}
              onChange={(e) => {
                setUpdates({ ...updates, skipSell: e.target.checked });
              }}
            />
          </Form.Group>
        </div>
      </div>
      <Button style={{ width: '100%', marginTop: '24px' }} variant="primary"
              onClick={handleUpdate}>
        Update
      </Button>
    </Form>
  );
};

export const handleUpdateSellHash = async (tradeId: string) => {
  SwalStyled.fire({
    title: `Update Bridge Tx Hash`,
    html: '<div id="update-bridge-hash-form"></div>',
    showConfirmButton: false,
    showCloseButton: true,
    width: '400px',
    didOpen: () => {
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#update-bridge-hash-form',
      ) as HTMLElement;

      if (formContainer) {
        // Create a root.
        const root: Root = createRoot(formContainer);

        // Render the React component inside the Swal modal
        root.render(
          <UpdateSellHashForm
            tradeId={tradeId}
            onClose={() => SwalStyled.close()}
          />,
        );

        // Store the root instance to unmount later
        (SwalStyled as any)._updateSellHashRoot = root;
      }
    },
    willClose: () => {
      // Clean up the React component when modal is closed
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#update-sell-hash-form',
      ) as HTMLElement;

      if (formContainer && (SwalStyled as any)._updateSellHashRoot) {
        const root: Root = (SwalStyled as any)._updateSellHashRoot;
        root.unmount();
        delete (SwalStyled as any)._updateSellHashRoot;
      }
    },
  });
};

interface UpdateSellHashFormProps {
  tradeId: string;
  onClose: () => void;
}

const UpdateSellHashForm: React.FC<UpdateSellHashFormProps> = ({ tradeId, onClose }) => {
  const [hash, setHash] = useState('');

  const handleUpdate = () => {
    sendUpdateEvent(MODULE_EVENTS.UPDATE_SELL_HASH, { tradeId, hash });
    onClose();
  };

  return (
    <Form>
      <Form.Group controlId="bridge-hash" style={{ marginBottom: '12px' }}>
        <Form.Control
          type="text"
          placeholder="Enter Bridge Tx Hash"
          value={hash}
          onChange={(e) => {
            setHash(e.target.value);
          }}
        />
      </Form.Group>

      <Button variant="primary" onClick={handleUpdate}>
        Update
      </Button>
    </Form>
  );
};

/**
 *
 */
type SidebarTextFilterProps = {
  textFilter: string
  setTextFilter: React.Dispatch<React.SetStateAction<string>>
}
export const SidebarTextFilter = memo(function SidebarTextFilter({
                                                                   textFilter,
                                                                   setTextFilter,
                                                                 }: SidebarTextFilterProps) {
  return (
    <Form.Control
      size="sm"
      type="text"
      placeholder="trade | hash | token | dex | bridge"
      value={textFilter}
      onChange={(e) => setTextFilter(e.target.value)}
    />
  );
});

/**
 *
 */
type SidebarPropertyFilterProps = {
  filterData: TransactionsFilterData
  setFilterData: React.Dispatch<React.SetStateAction<TransactionsFilterData>>
}
export const SidebarPropertyFilter = memo(function SidebarPropertyFilter(
  {
    filterData,
    setFilterData,
  }: SidebarPropertyFilterProps) {
  const { success } = filterData;

  return (
    <>
      <SidebarFilterRow text="Status">
        <SidebarFilterButton
          text="Success"
          active={success === 'true'}
          onClick={() =>
            setFilterData({
              ...filterData,
              success: success === 'true' ? 'none' : 'true',
            })
          }
        />
        <SidebarFilterButton
          text="Failed"
          active={success === 'false'}
          onClick={() =>
            setFilterData({
              ...filterData,
              success: success === 'false' ? 'none' : 'false',
            })
          }
        />
      </SidebarFilterRow>
    </>
  );
});

/**
 *
 */
type SidebarClearFiltersProps = {
  textFilter: string
  filterData: TransactionsFilterData
  setTextFilter: React.Dispatch<React.SetStateAction<string>>
  setFilterData: React.Dispatch<React.SetStateAction<TransactionsFilterData>>
}
export const SidebarClearFilters = memo(function SidebarClearFilters({
                                                                       textFilter,
                                                                       filterData,
                                                                       setTextFilter,
                                                                       setFilterData,
                                                                     }: SidebarClearFiltersProps) {
  const filtersPresent =
    false || textFilter !== '' || JSON.stringify(filterData) !== JSON.stringify(defaultFilterData);

  if (!filtersPresent) return <></>;

  return (
    <Button
      size="sm"
      variant="dark"
      onClick={() => {
        setTextFilter('');
        setFilterData(defaultFilterData);
      }}
    >
      Clear all filters
    </Button>
  );
});
