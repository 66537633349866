import { sendUpdateEvent } from '../../App';
import {
    BuildApprove,
    BuildBridge,
    BuildDex,
    BuildTransfer,
    MODULE_EVENTS,
} from '../../common';

/**
 *
 */
export function sendTransferTx(data: BuildTransfer) {
  sendUpdateEvent(MODULE_EVENTS.TRANSACTIONS_CREATE_TRANSFER_TX, data);
}

/**
 *
 */
export function sendDexTx(data: BuildDex) {
  sendUpdateEvent(MODULE_EVENTS.TRANSACTIONS_CREATE_DEX_TX, data);
}

/**
 *
 */
export function sendApproveDexTx(data: BuildApprove) {
  sendUpdateEvent(MODULE_EVENTS.TRANSACTIONS_CREATE_DEX_APPROVAL_TX, data);
}

/**
 *
 */
export function sendBridgeTx(data: BuildBridge) {
  sendUpdateEvent(MODULE_EVENTS.TRANSACTIONS_CREATE_BRIDGE_TX, data);
}

/**
 *
 */
export function sendApproveBridgeTx(data: BuildApprove) {
  sendUpdateEvent(MODULE_EVENTS.TRANSACTIONS_CREATE_BRIDGE_APPROVAL_TX, data);
}
