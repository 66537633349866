import React, { memo, useCallback } from 'react';
import { Badge, Button, Stack } from 'react-bootstrap';
import BigNumber from 'bignumber.js';

import VirtualTable, { TableRow } from '../../../components/VirtualTable';
import {
  DBTokens,
  NETWORK,
  NETWORK_EXPLORER_ADDRESS,
  NETWORK_NATIVE_AND_WRAPPED_TOKENS,
  toMilliseconds,
  TradesCountInfo,
  TradesProfitInfo,
  TradeWithProfitInfo,
  TxSide,
  TxType,
} from '../../../common';
import Popup from '../../../helper/Popup';
import { TransactionsFilterData } from '..';
import { NetworkToTicker } from '../../../helper/misc';
import { ArrowLeft, ArrowRight } from '../../../helper/svg';

const columns = [
  { name: 'Side', key: 'side', minWidth: 50 },
  { name: 'Tx hash', key: 'txHash', minWidth: 140, width: '-webkit-fill-available' },
  { name: 'Sender', key: 'sender', minWidth: 140 },
  { name: 'Type', key: 'type', minWidth: 100 },
  { name: 'Token', key: 'token', minWidth: 100 },
  { name: 'Bridge', key: 'bridge', minWidth: 160 },
  { name: 'Aggregator', key: 'bridge', minWidth: 120 },
  { name: 'Return Tokens', key: 'expectedReturn', minWidth: 180 },
  { name: 'Expected Profit', key: 'expectedProfit', minWidth: 180 },
  { name: 'Fee', key: 'fee', minWidth: 120 },
  { name: 'Value', key: 'fee', minWidth: 120 },
  { name: 'Bribe', key: 'bribe', minWidth: 120 },
  { name: 'Start 🕒', key: 'timeStart', minWidth: 120 },
  { name: 'Start 🧱', key: 'blockStart', minWidth: 120 },
  { name: 'Submit 🕒', key: 'timeSubmitted', minWidth: 120 },
  { name: 'Create 🕒', key: 'timeCreated', minWidth: 120 },
  { name: 'Submit 🧱', key: 'blockSubmitted', minWidth: 120 },
  { name: 'Target 🧱', key: 'blockTarget', minWidth: 120 },
  { name: 'Confirm 🕒', key: 'timeCreated', minWidth: 120 },
  { name: 'Confirmed 🧱', key: 'blockConfirmed', minWidth: 120 },
  { name: 'Status', key: 'status', minWidth: 80 },
  { name: 'Duration', key: 'duration', minWidth: 100 },
  { name: 'Error', key: 'error', minWidth: 140 },
  { name: 'Provider', key: 'provider', minWidth: 100 },
  { name: 'Pos', key: 'txPos', minWidth: 50 },
  { name: 'JSON', key: 'JSON', minWidth: 100 },
];

/**
 *
 */
type TransactionsTableProps = {
  tokens: DBTokens
  trades: TradeWithProfitInfo[]
  total: number
  countInfo: TradesCountInfo | null
  profitInfo: TradesProfitInfo | null
  textFilter: string
  filterData: TransactionsFilterData
  onLoadMore?: () => void
}
export default memo(function TransactionsTable(
  {
    tokens: dbTokens,
    trades,
    total,
    countInfo,
    profitInfo,
    onLoadMore,
  }: TransactionsTableProps) {
  const tokens = mergeDbTokensWithStaticTokens(dbTokens);

  const data: TableRow[] = [];
  for (const trade of trades) {
    if (!trade.txs || !Object.values(trade.txs).length) {
      continue;
    }

    const buyTxs = Object.values(trade.txs).filter(t => t.txType === TxType.Bundle || t.txType === TxType.Swap || t.txType === TxType.Bridge || t.txType === TxType.ApproveBridge || t.txType === TxType.ApproveSwap).sort((a, b) => a.nonce - b.nonce);
    const sellTxs = Object.values(trade.txs).filter(t => t.txType === TxType.BundleSell || t.txType === TxType.Sell || t.txType === TxType.Claim || t.txType === TxType.Transfer || t.txType === TxType.ApproveSell).sort((a, b) => a.nonce - b.nonce);

    const buyTx = buyTxs[0];
    const firstTx = Object.values(trade.txs)[0];
    const mainTx = buyTx ?? firstTx;

    const tradeTxs = [...buyTxs, ...sellTxs].map((txData) => {
      // @ts-ignore
      const t = (tokens[txData?.tokenName]?.networkData ?? {})[txData?.networkName]?.decimals ?? 0;

      const status = txData.success === undefined ? 'N/A' : txData.success ? 'Success' : 'Failed';
      const isSell = txData.side === TxSide.SELL || txData.txType === TxType.BundleSell || txData.txType === TxType.ApproveSell || txData.txType === TxType.Sell || txData.txType === TxType.Claim;
      const networkBuy = txData.networkBuy ?? (mainTx as any).network ?? (txData as any).networkName;
      const networkSell = (mainTx as any).networkTo ?? mainTx.networkSell ?? 'N/A';
      const txNetwork = isSell ? networkSell : networkBuy;
      const isSol = txNetwork === NETWORK.SOLANA;

      const expectedReturn =
        !!(txData)?.dstTokenAmount && (txData)?.dstTokenAmount !== 'N/A'
          ? `${BigNumber((txData).dstTokenAmount)
            .div(10 ** t)
            .toFormat(2)
            .toString()}`
          : 'N/A';

      const expectedProfit =
        !!(txData)?.expectedProfit && (txData)?.expectedProfit !== 'N/A'
          ? `${BigNumber((txData).expectedProfit)
            .toFormat(8)
            .toString()}`
          : 'N/A';

      return [
        <SideCell type={txData.txType} />,
        <TxHashCell networkName={txNetwork} txHash={txData.txHash} />,
        <SenderCell networkName={txNetwork} sender={txData.sender} />,
        <TypeCell type={txData.txType} />,
        <TokenCell tokenName={txData.tokenName} networkName={txNetwork}
                   tokenAddress={txData.tokenAddress} />,
        <BridgeCell networkName={txNetwork} contractAddress={txData.bridgeApproveTarget}
                    bridgeName={txData.bridgeName} />,
        <AggregatorCell name={txData.dexName ?? ''} />,
        !!expectedReturn ? (
          <ExpectedReturnCell expectedReturn={expectedReturn}
                              tokenName={isSell ? 'WETH' : txData.tokenName} />
        ) : (
          <></>
        ),
        !!expectedProfit ? (
          <ExpectedProfitCell expectedProfit={expectedProfit}
                              ethPrice={txData.ethPrice} />
        ) : (
          <></>
        ),
        <FeeCell network={txNetwork} fee={txData.fee} nativePrice={txData.nativePrice} />,
        <ValueCell network={txNetwork} value={txData.value}
                   nativePrice={txData.nativePrice} />,
        <ValueCell network={txNetwork} value={isSol ? txData.bribeSOL : txData.bribeWei}
                   nativePrice={txData.nativePrice} wei={!isSol} sol={isSol} />,
        <TimeCell time={txData.timeStart} />,
        <BlockCell
          networkName={txNetwork}
          blockTime={txData.blockStartTime}
          blockNumber={!!txData.blockStart ? `${txData.blockStart}` : 'N/A'}
        />,
        <TimeCell time={txData.timeSent} />,
        <TimeCell time={txData.timeCreated} />,
        <BlockCell
          networkName={txNetwork}
          blockTime={txData.blockSubmittedTime}
          blockNumber={!!txData.blockSubmitted ? `${txData.blockSubmitted}` : 'N/A'}
        />,
        <BlockCell
          networkName={txNetwork}
          blockTime={txData.blockTargetTime}
          blockNumber={txData.blockTarget ? `${txData.blockTarget}` : 'N/A'}
        />,
        <TimeCell time={txData.timeConfirmed} />,
        <BlockCell
          networkName={txNetwork}
          blockTime={txData.blockConfirmedTime}
          blockNumber={txData.blockConfirmed ? `${txData.blockConfirmed}` : 'N/A'}
        />,
        <StatusCell replaced={txData.replaced} status={status}
                    error={txData.errMessage} />,
        <DurationCell startTime={txData.timeSent} endTime={txData.timeConfirmed} />,
        !!txData.errMessage ? <ErrorCell error={txData.errMessage} /> : <></>,
        <ProviderCell provider={txData.sendMethod ?? txData.provider ?? 'public'} />,
        <PosCell blockPos={txData.blockPos} />,
        <JSONCell title={`Transaction ${txData.txHash} JSON data`} data={txData} />,
      ];
    });

    const date = timestampToDate(trade.id);

    data.push({
      title: {
        date,
        tokenName: mainTx.tokenName,
        tradeId: trade.id,
        profitMsg: trade.profitInfo.msg,
        networkBuy: mainTx.networkBuy,
        networkSell: mainTx.networkSell,
      },
      profit: trade.profitInfo.profit ?? 0,
      fee: trade.profitInfo.fee,
      time: trade.completionTime,
      color: trade.profitInfo.color,
      action: {
        handler: () => showJSONPopup(`Trade ${trade.id} JSON data`, trade),
        name: 'JSON',
      },
    });
    data.push(...tradeTxs);
  }

  const estimateRowHeight = useCallback(() => 59, []);

  return (
    <Stack gap={2}>
      <div style={{ display: 'flex' }}>
        <Badge
          bg="light" text="dark" className="h1"
          style={{ fontSize: '0.8em', padding: '9px', marginRight: '12px' }}>
          {`Showing ${trades.length} / ${total} trades`}
        </Badge>
        <Badge
          bg="light" text="dark" className="h1"
          style={{ fontSize: '0.8em', padding: '9px', marginRight: '12px' }}>
          {`Trades Count 24h: ${countInfo?.total24H} / 7d: ${countInfo?.total7D} / 30d: ${countInfo?.total30D}`}
        </Badge>
        <Badge
          bg="light" text="dark" className="h1"
          style={{ fontSize: '0.8em', padding: '9px', marginRight: '12px' }}>
          {`Approx Profit 24h: ${profitInfo?.profit24H}$ / 7d: ${profitInfo?.profit7D}$ / 30d: ${profitInfo?.profit30D}$`}
        </Badge>
        <Badge
          bg="light" text="dark" className="h1"
          style={{ fontSize: '0.8em', padding: '9px', marginRight: '12px' }}>
          {`Approx Fee 24h: ${profitInfo?.fee24H}$ / 7d: ${profitInfo?.fee7D}$ / 30d: ${profitInfo?.fee30D}$`}
        </Badge>
      </div>
      <VirtualTable
        columns={columns}
        data={data}
        style={{ fontFamily: 'monospace' }}
        estimateRowHeight={estimateRowHeight}
        onLoadMore={onLoadMore}
      />
    </Stack>
  );
});

/**
 *
 */
type SenderCellProps = {
  networkName: string
  sender: string
}
const SenderCell = memo(function SenderCell({ networkName, sender }: SenderCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button
        size="sm"
        variant="light"
        style={{ height: '50px', fontSize: '0.8em' }}
        onClick={() => {
          window.open(`${NETWORK_EXPLORER_ADDRESS[networkName as NETWORK]}/address/${sender}`, '_blank');
        }}
      >
        {!!sender ? sender.slice(0, 6) + '..' + sender.slice(-4) : ''}
      </Button>
    </div>
  );
});

/**
 *
 */
type TokenCellProps = {
  tokenName: string
  networkName: NETWORK
  tokenAddress?: string
}
const TokenCell = memo(function TokenCell(
  {
    tokenName,
    tokenAddress,
    networkName,
  }: TokenCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      {tokenAddress ? <Button
        size="sm"
        style={{ height: '50px' }}
        variant="light"
        onClick={() => {
          window.open(`${NETWORK_EXPLORER_ADDRESS[networkName as NETWORK]}/address/${tokenAddress}`, '_blank');
        }}
      >
        <span style={{ display: 'block' }}>{tokenName}</span>
      </Button> : <Badge bg="light" text="dark" className="noBg">{tokenName}</Badge>}
    </div>
  );
});

/**
 *
 */
type SideCellProps = {
  type: TxType
}
const SideCell = memo(function SideCell({ type }: SideCellProps) {
  const isSell = type === TxType.BundleSell || type === TxType.ApproveSell || type === TxType.Sell || type === TxType.Claim || type === TxType.Transfer;
  const style = { width: '20px', height: '20px' };
  return <Badge bg="light" text="dark" className="noBg">
    {isSell ? <ArrowLeft style={{ ...style, color: 'darkmagenta' }} /> :
      <ArrowRight style={{ ...style, color: 'darkcyan' }} />}
  </Badge>;
});

/**
 *
 */
type TypeCellProps = {
  type: TxType
}
const TypeCell = memo(function TypeCell({ type }: TypeCellProps) {
  return <Badge bg="light" text="dark" className="noBg">{type}</Badge>;
});

/**
 *
 */
type AggregatorCellProps = {
  name: string
}
const AggregatorCell = memo(function AggregatorCell({ name }: AggregatorCellProps) {
  const capName = name === 'lp'
    ? 'LP'
    : name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  return <Badge bg="light" text="dark" className="noBg">{capName}</Badge>;
});

/**
 *
 */
type BridgeCellProps = {
  networkName: string
  bridgeName?: string
  contractAddress?: string
}
const BridgeCell = memo(function BridgeCell({
                                              networkName,
                                              bridgeName,
                                              contractAddress,
                                            }: BridgeCellProps) {
  const height = contractAddress ? '100%' : 'auto';
  return (
    <div className="d-grid" style={{ height }}>
      {contractAddress ? <Button
        size="sm"
        style={{ height: '50px' }}
        variant="light"
        onClick={() => {
          window.open(`${NETWORK_EXPLORER_ADDRESS[networkName as NETWORK]}/address/${contractAddress}`, '_blank');
        }}
      >
        <span style={{ display: 'block' }}>{bridgeName}</span>
      </Button> : <Badge bg="light" text="dark" className="noBg">{bridgeName}</Badge>}
    </div>
  );
});

/**
 *
 */
type ExpectedReturnCellProps = {
  expectedReturn: string
  tokenName?: string
}
const ExpectedReturnCell = memo(function ExpectedReturnCell({
                                                              expectedReturn,
                                                              tokenName,
                                                            }: ExpectedReturnCellProps) {
  return (
    <Badge bg="light" text="dark" className="noBg">
      {expectedReturn === 'NaN' ? 'N/A' : expectedReturn} {tokenName ?? ''}
    </Badge>
  );
});

/**
 *
 */
type ExpectedProfitCellProps = {
  expectedProfit: string
  ethPrice?: number
}
const ExpectedProfitCell = memo(function ExpectedProfitCell(
  {
    expectedProfit,
    ethPrice,
  }: ExpectedProfitCellProps) {
  const profit = !expectedProfit || expectedProfit === '0' ? '0' : BigNumber(expectedProfit).toFormat(4).toString();
  const profitUSD = ethPrice ? BigNumber(profit).multipliedBy(ethPrice).toFormat(4).toString() : undefined;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Badge bg="light" text="dark" className="noBg">{profit} ETH</Badge>
      {profitUSD ?
        <Badge bg="light" text="dark" className="noBg">{`${profitUSD} $`}</Badge> : <></>}
    </div>
  );
});

/**
 *
 */
type TimeProps = {
  time?: number
}
const TimeCell = memo(function TimeCell({ time }: TimeProps) {
  let content = !!time && time > 0 ? formatTime(time, true) : 'N/A';
  return <Badge bg="light" text="dark" className="noBg">{content}</Badge>;
});

const formatTime = (timestamp: number | string, ms = false): string => {
  timestamp = toMilliseconds(Number(timestamp));

  const date = new Date(+timestamp);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();
  const milliseconds = date.getUTCMilliseconds();

  const hoursStr = hours.toString().padStart(2, '0');
  const minutesStr = minutes.toString().padStart(2, '0');
  const secondsStr = seconds.toString().padStart(2, '0');
  const millisecondsStr = milliseconds.toString().padStart(3, '0');

  const base = `${hoursStr}:${minutesStr}:${secondsStr}`;
  return ms ? `${base}:${millisecondsStr}` : base;
};

/**
 *
 */
type BlockCellProps = {
  networkName: string
  blockTime?: number
  blockNumber: string
}
const BlockCell = memo(function BlockCell({
                                            networkName,
                                            blockTime,
                                            blockNumber,
                                          }: BlockCellProps) {
  return !blockNumber || blockNumber === 'N/A' ? (
    <Badge bg="light" text="dark" className="noBg">
      {blockNumber || 'N/A'}
    </Badge>
  ) : (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button
        size="sm"
        variant="light"
        style={{
          height: '50px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => {
          window.open(`${NETWORK_EXPLORER_ADDRESS[networkName as NETWORK]}/block/${blockNumber}`, '_blank');
        }}
      >
        <span style={{ fontSize: '0.9em' }}>{blockNumber.toLocaleString()}</span>
        {!!blockTime && blockTime > 0 ?
          <Badge bg="light" text="dark" className="noBg"
                 style={{
                   marginTop: '8px',
                   fontSize: '0.9em',
                 }}>{`${formatTime(blockTime)}`}</Badge> : <></>}
      </Button>
    </div>
  );
});

/**
 *
 */
type StatusCellProps = {
  status: string
  error?: string
  replaced?: boolean
}
const StatusCell = memo(function StatusCell({
                                              status,
                                              replaced,
                                              error,
                                            }: StatusCellProps) {
  let colorClass: string | undefined;
  if (status.toLowerCase() === 'success') {
    colorClass = 'success-imp';
  }
  if (status.toLowerCase() === 'failed') {
    colorClass = 'error-imp';
  }
  if (replaced) {
    colorClass = 'warning-imp';
  }

  // check for unknown or coleasce error
  if (isUnknownStatus(error || '', status)) {
    colorClass = 'warning-imp';
    status = 'Unknown';
  }

  const className = `noBg ${colorClass || ''}`;

  return (
    <Badge bg="light" text="dark" className={className}>
      {replaced ? 'Replaced' : status}
    </Badge>
  );
});

/**
 *
 */
type DurationCellProps = {
  startTime?: number
  endTime?: number
}
const DurationCell = memo(function DurationCell({
                                                  startTime,
                                                  endTime,
                                                }: DurationCellProps) {
  const duration =
    !!startTime && startTime > 0 && !!endTime && endTime > 0 ? formatDuration(startTime, endTime) : 'N/A';
  return (
    <Badge bg="light" text="dark" className="noBg">
      {duration}
    </Badge>
  );
});

/**
 *
 */
type ErrorCellProps = {
  error?: string
}
const ErrorCell = memo(function ErrorCell({ error }: ErrorCellProps) {
  const showFullError = (err: string) => Popup.popupMessage('Error', err);
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button
        size="sm"
        variant="light"
        style={{ fontSize: '0.8em', cursor: 'pointer' }}
        className="text-truncate"
        onClick={() => showFullError(error || '')}
      >
        {error || ''}
      </Button>
    </div>
  );
});

/**
 *
 */
type ProviderCellProps = {
  provider: string
}
const ProviderCell = memo(function ProviderCell({ provider }: ProviderCellProps) {
  return <Badge bg="light" text="dark" className="noBg">{provider}</Badge>;
});

/**
 *
 */
type PosCellProps = {
  blockPos?: number
}
const PosCell = memo(function PosCell({ blockPos }: PosCellProps) {
  return (
    <Badge bg="light" text="dark" className="noBg">
      {(!blockPos && blockPos !== 0) || (blockPos as any) === 'N/A' ? 'N/A' : blockPos}
    </Badge>
  );
});

/**
 *
 */
type FeeCellProps = {
  network: NETWORK
  fee?: string
  nativePrice?: number
}
const FeeCell = memo(function FeeCell({ network, fee, nativePrice }: FeeCellProps) {
  const feeNum = !!fee ? BigNumber(fee) : 'N/A';

  let feeStr = 'N/A';
  if (feeNum !== 'N/A') {
    feeStr = `${feeNum.toFormat(4).toString()}`;
  }
  const feeUSD = feeStr !== 'N/A' && nativePrice ? BigNumber(feeNum).multipliedBy(nativePrice).toFormat(4).toString() : undefined;
  const feeTicker = NetworkToTicker[network];

  return <div style={{ display: 'flex', flexDirection: 'column' }}>
    <Badge bg="light" text="dark"
           className="noBg">{feeStr} {feeStr !== 'N/A' ? feeTicker : ''}</Badge>
    {feeUSD ?
      <Badge bg="light" text="dark" className="noBg">{`${feeUSD} $`}</Badge> : <></>}
  </div>;
});

/**
 *
 */
type ValueCellProps = {
  network: NETWORK
  value?: string | number
  nativePrice?: number
  wei?: boolean
  sol?: boolean
}
const ValueCell = memo(function ValueCell(
  {
    network,
    value,
    nativePrice,
    wei,
    sol,
  }: ValueCellProps) {
  let valNum = (!!value || value === '0') ? BigNumber(value) : 'N/A';

  if (!!valNum && valNum !== 'N/A' && typeof valNum !== 'string' && wei) {
    valNum = valNum.div(1e18);
  }

  let valStr = 'N/A';
  if (valNum !== 'N/A' && typeof valNum !== 'string') {
    valStr = `${valNum.toFormat(4).toString()}`;
  }

  const valueUSD = valStr !== 'N/A' && nativePrice ? BigNumber(valNum).multipliedBy(nativePrice).toFormat(4).toString() : undefined;
  const valueTicker = NetworkToTicker[network];

  return <div style={{ display: 'flex', flexDirection: 'column' }}>
    <Badge bg="light" text="dark"
           className="noBg">{valStr} {valStr !== 'N/A' ? valueTicker : ''}</Badge>
    {valueUSD ?
      <Badge bg="light" text="dark" className="noBg">{`${valueUSD} $`}</Badge> : <></>}
  </div>;
});

/**
 *
 */
type TxHashCellProps = {
  networkName: string
  txHash?: string | number
}
const TxHashCell = memo(function TxHashCell({ networkName, txHash }: TxHashCellProps) {
  const baseHash = `${!!txHash ? txHash : Date.now()}`;
  const splitted = baseHash.split(':');
  const hash = baseHash.includes(':') ? splitted[splitted.length - 1] : baseHash;

  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button
        size="sm"
        variant="light"
        style={{ height: '50px', fontSize: '0.8em' }}
        onClick={() => {
          window.open(`${NETWORK_EXPLORER_ADDRESS[networkName as NETWORK]}/tx/${hash}`, '_blank');
        }}
      >
        {hash.slice(0, 6) + '..' + hash.slice(-4)}
      </Button>
    </div>
  );
});

/**
 *
 */
type JSONCellProps = {
  title: string
  data: any
}
const JSONCell = memo(function JSONCell({ title, data }: JSONCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button
        size="sm"
        variant="light"
        style={{ fontSize: '0.8em', cursor: 'pointer' }}
        onClick={() => showJSONPopup(title, data)}
      >
        JSON
      </Button>
    </div>
  );
});

const showJSONPopup = (title: string, data: any) => Popup.popupJSON(title, data);

const timestampToDate = (timestamp: string): string => {
  const date = new Date(+timestamp);

  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  const year = date.getUTCFullYear();
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}.${milliseconds}`;
};

const mergeDbTokensWithStaticTokens = (dbTokens: DBTokens): DBTokens => {
  return { ...dbTokens, ...NETWORK_NATIVE_AND_WRAPPED_TOKENS };
};

const formatDuration = (startTime: number, endTime: number): string => {
  const duration = endTime - startTime; // duration in milliseconds

  if (duration < 1000) {
    // less than a second
    return `${duration}ms`;
  } else if (duration < 60000) {
    // less than a minute
    return `${(duration / 1000).toFixed(1)}s`;
  } else if (duration < 3600000) {
    // less than an hour
    return `${(duration / 60000).toFixed(1)}m`;
  } else {
    // hours or more
    return `${(duration / 3600000).toFixed(1)}h`;
  }
};

const isUnknownStatus = (error: string, status: string) =>
  !!error &&
  status.toLowerCase() !== 'success' &&
  (error.includes('coleasce error') ||
    error.includes('already known') ||
    error.toLowerCase().includes('flashbot') ||
    error.toLowerCase().includes('flashbots') ||
    error.toLowerCase().includes('status for private tx'));
