import {
  GO_NODER_EVENTS,
  LockedNonces,
  MODULE_EVENTS_PARAMS,
  NativeTokens,
  NODER_EVENTS,
  Sell,
  WORKER_EVENTS,
} from '..';
import { NETWORK } from '../defaults';
import {
  AWSGroupScheme,
  DBAutomationRules,
  DBBridge,
  DBBridges,
  DBMiscSettings,
  DBToken,
  DBTokens,
  RoutesData,
} from '../types';
import { Competitors } from '../types/monitoringTypes';

export type PinKey = `${NETWORK}_${NETWORK}_${string}`;

export enum PinType {
  AUTO = 'auto',
  FORCE = 'force',
  PARALLEL = 'parallel',
}

export type TokenPinData = {
  [key in PinKey]?: PinType;
};

export type TokenPins = { [key: string]: TokenPinData };

export enum CheckerCode {
  SUCCESS,
  ERROR,
  TOKEN_CHECK,
  BALANCE,
  PROFIT,
  NO_TRADE,
  AUTOMATION,
}

export function checkerCodeToText(code: CheckerCode): string {
  switch (code) {
    case CheckerCode.AUTOMATION:
      return 'AUTOMATION';
    case CheckerCode.TOKEN_CHECK:
      return 'TOKEN_CHECK';
    case CheckerCode.BALANCE:
      return 'BALANCE';
    case CheckerCode.PROFIT:
      return 'PROFIT';
    case CheckerCode.NO_TRADE:
      return 'NO_TRADE';
  }
  return '?';
}

export type CheckerRes = {
  [iterId: string]: CheckerResItem;
};

export type CheckerResItem = {
  iterId: string;
  code: CheckerCode;
  createdAt: number;
  err?: string;
  reason?: string;
};

/**
 *
 */
export enum BRAIN_EVENTS {
  INIT_INTERFACE = 'INIT_INTERFACE',
  INTERFACE_EVENT_CACHE = 'INTERFACE_EVENT_CACHE',

  ADD_CHECKER_RES = 'ADD_CHECKER_RES',
  DELETE_CHECKER_RES = 'DELETE_CHECKER_RES',

  START_WORKERS = 'START_WORKERS',
  START_NODER = 'START_NODER',
  START_TRANSACTIONS = 'START_TRANSACTIONS',

  START_ROUTE_SEARCH = 'START_ROUTE_SEARCH',

  CONNECTED_USERS = 'CONNECTED_USERS',
  CONNECTED_SERVICES = 'CONNECTED_SERVICES',

  BRAIN_STATE_UPDATE = 'BRAIN_STATE_UPDATE',
  NOTIFICATION_TO_CLIENT = 'NOTIFICATION_TO_CLIENT',

  UPDATE_MISC_SETTINGS_PROCESSED = 'UPDATE_MISC_SETTINGS_PROCESSED',
  AWS_GROUPS_UPDATE_PROCESSED = 'AWS_GROUPS_UPDATE_PROCESSED',

  CREATE_TOKEN_PROCESSED = 'CREATE_TOKEN_PROCESSED',
  DELETE_TOKEN_PROCESSED = 'DELETE_TOKEN_PROCESSED',
  UPDATE_TOKEN_PROCESSED = 'UPDATE_TOKEN_PROCESSED',

  CREATE_BRIDGE_PROCESSED = 'CREATE_BRIDGE_PROCESSED',
  DELETE_BRIDGE_PROCESSED = 'DELETE_BRIDGE_PROCESSED',
  UPDATE_BRIDGE_PROCESSED = 'UPDATE_BRIDGE_PROCESSED',

  UPDATE_AUTOMATION_RULES_PROCESSED = 'UPDATE_AUTOMATION_RULES_PROCESSED',
}

/**
 *
 */
export type BRAIN_EVENTS_PARAMS = {
  [BRAIN_EVENTS.INIT_INTERFACE]: {
    devMode: boolean
    msRemainingBeforeDisconnect: number
    allTokens: DBTokens
    competitors: Competitors
    bridges: DBBridges
    miscSettings: DBMiscSettings
    awsGroups: AWSGroupScheme[]
    brainState: BRAIN_EVENTS_PARAMS[BRAIN_EVENTS.BRAIN_STATE_UPDATE]
    connectedServices: BRAIN_EVENTS_PARAMS[BRAIN_EVENTS.CONNECTED_SERVICES]
    checkerRes: CheckerRes
  }
  [BRAIN_EVENTS.INTERFACE_EVENT_CACHE]: {
    newHeads: { [key in NETWORK]?: MODULE_EVENTS_PARAMS[NODER_EVENTS.NEW_HEADS] }
    goNoderNewHeads: { [key in NETWORK]?: MODULE_EVENTS_PARAMS[GO_NODER_EVENTS.NEW_BLOCK_TX_DATAS] }
    gasEstimate: { [key in NETWORK]?: MODULE_EVENTS_PARAMS[NODER_EVENTS.GAS_ESTIMATE] }
    balance: { [key in NETWORK]?: { [tokenName: string]: string } }
    secondaryBalance: {
      [walletAddress: string]: {
        [networkName in NETWORK]?: {
          [tokenName: string]: string
        }
      }
    }

    trackingResults: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_ITERATION_RESULT]
  }
  [BRAIN_EVENTS.ADD_CHECKER_RES]: CheckerResItem
  [BRAIN_EVENTS.DELETE_CHECKER_RES]: string
  [BRAIN_EVENTS.START_WORKERS]: {
    miscSettings: DBMiscSettings
    activeTokensSplitted: DBTokens
    routesData: RoutesData | null
    // resolved: DBResolved
  }
  [BRAIN_EVENTS.START_NODER]: {
    walletAddress: string
    allTokens: DBTokens
  }
  [BRAIN_EVENTS.START_TRANSACTIONS]: {
    globalAutomationEnabled: boolean
    sellOnly: boolean
    // e2e only
    bundleAddr?: string
  }

  [BRAIN_EVENTS.START_ROUTE_SEARCH]: {
    miscSettings: DBMiscSettings
    activeTokensSplitted: DBTokens
    routesData: RoutesData | null
  }

  [BRAIN_EVENTS.CONNECTED_USERS]: {
    [username: string]: number
  }
  [BRAIN_EVENTS.CONNECTED_SERVICES]: {
    [serviceName: string]: {
      numExpected: number
      numConnected: number
      hasSubService: boolean
      subServiceOk: boolean
    }
  }

  [BRAIN_EVENTS.BRAIN_STATE_UPDATE]: {
    walletAddress: string
    workersRunning: boolean
    workersStartedBy: string
    routeSearchRunning: boolean
    routeSearchProgress: {
      [key: string]: number
    }
    pinnedTokens: TokenPins
    autosellPins: TokenPins
    pendingSells: Sell[]
    lockedNonces: LockedNonces
    wethPriceUSD: string
    nativePriceUSD: { [key in NativeTokens]: string }
  }
  [BRAIN_EVENTS.NOTIFICATION_TO_CLIENT]: {
    msg: string
    type: 'info' | 'warning' | 'error'
    autoClose?: false | number
  }

  [BRAIN_EVENTS.UPDATE_MISC_SETTINGS_PROCESSED]: {
    miscSettings: DBMiscSettings
  }

  [BRAIN_EVENTS.AWS_GROUPS_UPDATE_PROCESSED]: {
    awsGroups: AWSGroupScheme[]
  }

  [BRAIN_EVENTS.CREATE_TOKEN_PROCESSED]: {
    tokenName: string
    tokenData: DBToken
  }

  [BRAIN_EVENTS.DELETE_TOKEN_PROCESSED]: {
    tokenName: string
  }

  [BRAIN_EVENTS.UPDATE_TOKEN_PROCESSED]: {
    tokenName: string
    tokenData: DBToken
  }

  [BRAIN_EVENTS.CREATE_BRIDGE_PROCESSED]: {
    bridgeName: string
    bridgeData: DBBridge
  }
  [BRAIN_EVENTS.DELETE_BRIDGE_PROCESSED]: {
    bridgeName: string
    updatedTokens: DBTokens
  }
  [BRAIN_EVENTS.UPDATE_BRIDGE_PROCESSED]: {
    bridgeName: string
    bridgeData: DBBridge
  }

  [BRAIN_EVENTS.UPDATE_AUTOMATION_RULES_PROCESSED]: {
    rules: DBAutomationRules
  }
}

// Verification that all events defined in ENUM have their params defined in type above
// 	If it throws error, it means that you forgot to add event params to type above
type _ = {
  [Property in BRAIN_EVENTS]: BRAIN_EVENTS_PARAMS[Property]
}
