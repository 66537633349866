import React, { memo, ReactNode } from 'react';

import { Button, Container } from 'react-bootstrap';

import { BRAIN_EVENTS, MODULE_EVENTS_PARAMS } from '../common/moduleEvents';
import { getNetworkImage } from '../assets/networks';
import { mainnet, useAccount, useConnect, useNetwork } from 'wagmi';
import { NETWORK } from '../common';
import { arbitrum, bsc, polygon } from 'viem/chains';

const wagmiChainIdToNetworkName: { [key: string]: NETWORK } = {
  [mainnet.id]: NETWORK.ETHEREUM,
  [bsc.id]: NETWORK.BINANCE,
  [polygon.id]: NETWORK.POLYGON,
  [arbitrum.id]: NETWORK.ARBITRUM,
};

const WagmiInterface = memo(function WagmiInterface() {
  const { isConnected } = useAccount();
  const { connect, connectors } = useConnect();
  const { chain } = useNetwork();

  return (
    <div
      style={{
        position: 'absolute',
        bottom: '2.5em',
      }}
    >
      {!isConnected && (
        <Button variant="primary" onClick={() => connect({ connector: connectors[0] })}>
          Connect metamask
        </Button>
      )}
      {chain && (
        <span style={{ marginLeft: '1em' }}>
					<img
            src={getNetworkImage(wagmiChainIdToNetworkName[chain.id])}
            alt=""
            width={40}
            height={40}
          />
				</span>
      )}
    </div>
  );
});

/**
 *
 */
type GasBlockProps = {
  value: number
  minProfit?: number
  otherNetworks: { networkName: string; value: number }[]
}
export const GasBlock = memo(function GasBlock(
  {
    value,
    minProfit,
    otherNetworks,
  }: GasBlockProps) {
  return (
    <span
      style={{
        position: 'fixed',
        display: 'flex',
        right: '0',
        top: '1.3em',
        fontSize: '3em',
        lineHeight: '1em',
        padding: '0 20px',
        backgroundColor: 'white',
        zIndex: 1000,
      }}
    >
			{/*<WagmiWrapper>*/}
      {/*	<WagmiInterface/>*/}
      {/*</WagmiWrapper>*/}
      {`${value}${!!minProfit && minProfit > 0 ? ` (${minProfit})` : ''}`}
      <div
        style={{
          marginLeft: '4px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          fontSize: '0.9rem',
          lineHeight: '1rem',
        }}
      >
				{otherNetworks.map((nd) => (
          <div
            key={nd.networkName}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
            }}
          >
            <img src={getNetworkImage(nd.networkName)} alt="" width={12} height={12} />
            <span>{nd.value}</span>
          </div>
        ))}
			</div>
		</span>
  );
});

/**
 *
 */
type ContentSpacerProps = {
  height: number
}
export const ContentSpacer = memo(function ContentSpacer({ height }: ContentSpacerProps) {
  return <div style={{ height: `${height}px` }}></div>;
});

/**
 *
 */
type ContentSectionProps = {
  name?: string
  renderP?: boolean
  children?: ReactNode
}
export const ContentSection = memo(function ContentSection({
                                                             name,
                                                             renderP,
                                                             children,
                                                           }: ContentSectionProps) {
  return (
    <div className="content-section d-grid gap-2">
      {(name || renderP) && (
        <p className="fw-semibold" style={{ marginTop: '1em', marginBottom: '0.1em' }}>
          {name ? `${name}:` : ''}
        </p>
      )}
      {children}
    </div>
  );
});

/**
 *
 */
type ContentTextRowProps = {
  text: string
}
export const ContentTextRow = memo(function ContentTextRow({ text }: ContentTextRowProps) {
  return <p style={{ marginBottom: '0' }}>{text}</p>;
});

type ConnectedUsersBlockProps = {
  users: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CONNECTED_USERS]
}
export const ConnectedUsersBlock = memo(function ConnectedUsersBlock({ users }: ConnectedUsersBlockProps) {
  return (
    <div className="d-flex gap-2">
      <Button size="sm" variant="light" disabled>
        Users:
      </Button>
      {Object.entries(users).map(([u, c]) => (
        <Button key={u} size="sm" variant="light">
          {u}: {c}
        </Button>
      ))}
    </div>
  );
});

/**
 *
 */
type ContentProps = {
  children?: ReactNode
}
export default memo(function Content({ children }: ContentProps) {
  return (
    <Container className="content" fluid>
      {children}
    </Container>
  );
});
