import { memo, useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Col, Container, Row } from 'react-bootstrap';

import Header_C, {
  HeaderBlockNumSection_C,
  HeaderConnectedServicesSection_C,
  HeaderSection,
  HeaderStartStopSection_C,
} from '../../../components/Header';
import Content, { ContentSection, ContentSpacer } from '../../../components/Content';
import Sidebar, {
  SidebarRootNavLinks,
  SidebarSection,
} from '../../../components/Sidebar';
import TransactionsTable from '../table/TransactionsTable';
import {
  DBTokens,
  marshal,
  TradesCountInfo,
  TradesProfitInfo,
  TradeWithProfitInfo,
  TxsAPIResponse,
} from '../../../common';
import {
  defaultFilterData,
  SidebarClearFilters,
  SidebarPropertyFilter,
  SidebarTextFilter,
  SideButtons,
  TransactionsFilterData,
} from '../index';
import { useDebounce } from '../../../helper/debounce';

type LatestTransactionsProps = {
  tokens: DBTokens;
};

export default memo(function LatestTransactions({ tokens }: LatestTransactionsProps) {
  // Table data
  const [trades, setTrades] = useState<TradeWithProfitInfo[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [countInfo, setCountInfo] = useState<TradesCountInfo | null>(null);
  const [profitInfo, setProfitInfo] = useState<TradesProfitInfo | null>(null);

  // Immediate text filter
  const [textFilter, setTextFilter] = useState('');

  // Other filter data
  const [filterData, setFilterData] = useState<TransactionsFilterData>(defaultFilterData);

  // Pagination
  const [page, setPage] = useState<number>(0);

  // Loading state
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Whether there’s more data to load
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  // Debounced filter: waits 2s after user stops typing
  const debouncedTextFilter = useDebounce(textFilter, 500);

  // Build base URL
  const baseUrl = `${process.env.REACT_APP_SOCKET_BASE_URL}`
    .replace(/^wss:/, 'https:')
    .concat('/txs');

  // We track old filter values so we know if they've changed
  const prevFiltersRef = useRef<{
    text: string;
    success: string;
  }>({
    text: debouncedTextFilter,
    success: filterData.success,
  });

  // Single effect to handle both "filter changes" and "page changes"
  useEffect(() => {
    const prevFilters = prevFiltersRef.current;
    const haveFiltersChanged =
      prevFilters.text !== debouncedTextFilter ||
      prevFilters.success !== filterData.success;

    // If the filters have changed, update our ref
    if (haveFiltersChanged) {
      prevFiltersRef.current = {
        text: debouncedTextFilter,
        success: filterData.success,
      };
    }

    // If the filters changed but page isn't zero, reset page to 0
    if (haveFiltersChanged && page !== 0) {
      // Also reset hasNextPage to true if user changed filter
      setHasNextPage(true);
      setPage(0);
      return;
    }

    // Build query params
    const params: Record<string, string> = {};
    if (debouncedTextFilter) {
      params['filter'] = debouncedTextFilter;
    }
    if (filterData.success !== 'none') {
      params['success'] = filterData.success;
    }
    params['page'] = String(page);

    const queryString = new URLSearchParams(params).toString();
    const url = `${baseUrl}?${queryString}`;

    let cancelRequest = false;

    (async () => {
      setIsLoading(true);
      try {
        const res = await axios.get<TxsAPIResponse>(url);
        if (cancelRequest) return; // If effect unmounted
        const newTrades = res.data.trades || [];

        setTotal(res.data.total || 0);
        setCountInfo(res.data.count || null);
        setProfitInfo(res.data.profitInfo || null);

        if (page > 0) {
          // If page>0, append
          setTrades((prev) => [...prev, ...newTrades]);
        } else {
          // If page=0, replace
          setTrades(newTrades);
        }

        // If we got an empty result, no more data
        if (newTrades.length === 0) {
          setHasNextPage(false);
        }
        // Alternatively, if your server provides an accurate `total` and `trades` length:
        // if (page > 0 && (trades.length + newTrades.length) >= newTotal) {
        //   setHasNextPage(false);
        // }

      } catch (err) {
        console.error(`Failed to load trades: ${marshal(err)}`);
      } finally {
        if (!cancelRequest) {
          setIsLoading(false);
        }
      }
    })();

    return () => {
      cancelRequest = true;
    };
  }, [
    baseUrl,
    page,
    debouncedTextFilter,
    filterData.success, // Only watch relevant part of filterData
  ]);

  // Load more handler (infinite scroll style)
  const handleLoadMore = useCallback(() => {
    // If we’re already loading or no more data, skip
    if (isLoading || !hasNextPage) return;
    setPage((prev) => prev + 1);
  }, [isLoading, hasNextPage]);

  return (
    <>
      <Header_C>
        <HeaderSection>
          <HeaderStartStopSection_C />
        </HeaderSection>
        <HeaderSection justifyContent="center">
          <HeaderConnectedServicesSection_C />
        </HeaderSection>
        <HeaderSection justifyContent="end">
          <HeaderBlockNumSection_C />
        </HeaderSection>
      </Header_C>

      <Container fluid className="main">
        <Row>
          <Col className="sidebarContainer">
            <Sidebar>
              <SidebarRootNavLinks />

              <SidebarSection name="Filters" gap={2}>
                {/* textFilter updates immediately */}
                <SidebarTextFilter
                  textFilter={textFilter}
                  setTextFilter={setTextFilter}
                />

                <SidebarPropertyFilter
                  filterData={filterData}
                  setFilterData={setFilterData}
                />
                <SidebarClearFilters
                  textFilter={textFilter}
                  filterData={filterData}
                  setTextFilter={setTextFilter}
                  setFilterData={setFilterData}
                />
              </SidebarSection>

              <SidebarSection renderP gap={1}>
                <SideButtons />
              </SidebarSection>
            </Sidebar>
          </Col>
          <Col className="contentContainer">
            <Content>
              <ContentSection>
                <TransactionsTable
                  textFilter={debouncedTextFilter}
                  filterData={filterData}
                  tokens={tokens}
                  trades={trades}
                  total={total}
                  countInfo={countInfo}
                  profitInfo={profitInfo}
                  onLoadMore={handleLoadMore}
                />
              </ContentSection>
              <ContentSection>
                <ContentSpacer height={100} />
              </ContentSection>
            </Content>
          </Col>
        </Row>
      </Container>
    </>
  );
});
