import React, { memo, ReactNode, useContext, useState } from 'react';
import { Color } from 'react-bootstrap/esm/types';
import { createRoot, Root } from 'react-dom/client';
import { Badge, Button, Col, Modal, Row, Stack } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import typing from '../assets/typing.gif';
import gnat from '../assets/gnat.gif';
import stonks from '../assets/stonks.gif';
import popa from '../assets/popa.gif';

import { AppContext, sendUpdateEvent } from '../App';
import {
  MODULE_EVENTS,
  NETWORK,
  NETWORK_EXPLORER_ADDRESS,
  SOCKET_PATH,
} from '../common';
import { SwalStyled } from '../helper/Popup';
import { getNetworkImage } from '../assets/networks';
import SocketClient from '../common_custom/SocketClient';

/**
 *
 */
type HeaderSectionProps = {
  justifyContent?: 'start' | 'end' | 'center'
  children?: ReactNode
}
export const HeaderSection = memo(function HeaderSection(
  {
    children,
    justifyContent,
  }: HeaderSectionProps) {
  return (
    <Col
      className={'d-flex gap-2 ' + (justifyContent ? `justify-content-${justifyContent}` : '')}>
      {children}
    </Col>
  );
});

/**
 *
 */
type HeaderButtonProps = {
  img?: string
  text: string | JSX.Element
  hoverText?: string | JSX.Element
  variant?: string /** [outline-]primary etc */
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}
export const HeaderButton = memo(function HeaderButton(
  {
    img,
    text,
    hoverText,
    variant,
    onClick,
  }: HeaderButtonProps) {
  const [hover, setHover] = useState(false);
  return (
    <Button
      style={{ display: 'flex', alignItems: 'center' }}
      size="sm"
      variant={variant || 'light'}
      disabled={!onClick}
      onClick={onClick}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {img && <img width="14px" src={img} alt="" style={{ marginRight: '4px' }} />}
      <span>{hover ? hoverText || text : text}</span>
    </Button>
  );
});

/**
 *
 */
type HeaderBadgeProps = {
  text?: string
  textColor?: Color
  bgColor?: Color
  children?: ReactNode
}
export const HeaderBadge = memo(function HeaderBadge(
  {
    text,
    textColor,
    bgColor,
    children,
  }: HeaderBadgeProps) {
  return (
    <Badge bg={bgColor} text={textColor} className="d-flex"
           style={{ alignItems: 'center' }}>
      <Stack direction="horizontal" gap={1}>
        {text}
        {children}
      </Stack>
    </Badge>
  );
});

export const DevAppend = memo(function DevAppend() {
  return <span style={{ color: 'red', fontWeight: 'bold' }}>DEV DB</span>;
});

export const BuildAppend = memo(function BuildAppend({ buildNumber }: {
  buildNumber?: string
}) {
  return (
    <span style={{ color: '#adb5bd', fontSize: '0.7em', marginLeft: '10px' }}>
			{buildNumber ? '#' + buildNumber : ''}
		</span>
  );
});

export function getLogoSrc() {
  if (process.env.NODE_ENV === 'production') {
    return popa;
    return stonks;
    return gnat;
    // return unicorn
  }
  return typing;
}

/**
 *
 */
type HeaderProps = {
  children?: ReactNode
}
export default memo(function Header_C({ children }: HeaderProps) {
  const { projectName, devMode } = useContext(AppContext);
  const append = devMode ? (
    <DevAppend />
  ) : (
    <BuildAppend buildNumber={process.env.NODE_ENV === 'production' ? 'prod' : 'dev'} />
  );

  return (
    <Container fluid className="headerContainer">
      <Row>
        <Col className="sidebarOffset">
          <Container>
            <Link to="/" className="d-grid" style={{ textDecoration: 'none' }}>
              <Button className="text-start d-flex align-items-center gap-2"
                      variant="light">
                <img className={'logoImg'} src={getLogoSrc()}></img>
                {projectName || 'Title'}
                {append}
              </Button>
            </Link>
          </Container>
        </Col>
        <Col>
          <Container fluid>
            <Row>{children}</Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
});

function WorkersModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button size="sm" variant="outline-primary" onClick={handleShow}>
        Worker
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Worker actions</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', gap: '1em', flexWrap: 'wrap' }}>
          <Button
            onClick={() => {
              SocketClient.emitEvent(MODULE_EVENTS.COMMAND, {
                to: 'worker',
                cmd: 'saveStats',
              });
              setTimeout(() => handleClose(), 250);
            }}
          >
            Save file
          </Button>
          <Button
            onClick={() => {
              SocketClient.emitEvent(MODULE_EVENTS.COMMAND, {
                to: 'worker',
                cmd: 'clearBan',
              });
              setTimeout(() => handleClose(), 250);
            }}
          >
            Clear ban
          </Button>
          <Button
            onClick={() => {
              SocketClient.emitEvent(MODULE_EVENTS.COMMAND, {
                to: 'worker',
                cmd: 'recreateClients',
              });
              setTimeout(() => handleClose(), 250);
            }}
          >
            Recreate clients
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

/**
 *
 */
type HeaderStartStopSectionProps = {}
export const HeaderStartStopSection_C = memo(
  function HeaderStopStartSection({}: HeaderStartStopSectionProps) {
    const { brainState, miscSettings } = useContext(AppContext);
    const { workersRunning, routeSearchRunning, routeSearchProgress } = brainState;

    const combinedRouteSearchProgress =
      Object.values(routeSearchProgress || {}).reduce((acc, cur) => acc + cur, 0) /
      Object.keys(routeSearchProgress || {}).length;

    if (workersRunning) {
      return (
        <>
          <HeaderButton
            text="Stop"
            variant="outline-danger"
            onClick={
              routeSearchRunning
                ? undefined
                : () => {
                  sendUpdateEvent(MODULE_EVENTS.CLIENT_STOP_WORKERS, undefined);
                }
            }
          />
          <HeaderBadge bgColor="light" textColor="muted">
            <HeaderBadge text="working" bgColor="success" />
          </HeaderBadge>

          <HeaderButton
            text="Sell Only"
            variant={miscSettings?.sellOnly ? 'danger' : 'outline-secondary'}
            onClick={
              miscSettings?.sellOnly === undefined
                ? undefined
                : () => {
                  sendUpdateEvent(MODULE_EVENTS.UPDATE_MISC_SETTINGS, {
                    updates: {
                      sellOnly: !miscSettings.sellOnly,
                    },
                  });
                }
            }
          />

          <WorkersModal />

          <HeaderButton
            text="💃 Call girls"
            variant="outline-danger"
            onClick={() => {
              sendUpdateEvent(MODULE_EVENTS.COMMAND, {
                to: 'interface',
                cmd: 'call_girls',
              });
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <HeaderButton
            text="Start"
            variant="outline-success"
            onClick={
              routeSearchRunning
                ? undefined
                : () => {
                  sendUpdateEvent(MODULE_EVENTS.CLIENT_START_WORKERS, undefined);
                }
            }
          />
          <HeaderButton
            text={
              routeSearchRunning
                ? `Finding routes: ${(combinedRouteSearchProgress * 100).toFixed(2)}%`
                : `Find routes`
            }
            variant="outline-primary"
            onClick={
              routeSearchRunning
                ? undefined
                : (e) => {
                  if (e.shiftKey) {
                    sendUpdateEvent(
                      MODULE_EVENTS.CLIENT_START_ROUTE_SEARCH,
                      undefined,
                    );
                  }
                }
            }
          />
          <HeaderBadge bgColor="light" textColor="muted">
            <HeaderBadge text="stopped" bgColor="danger" />
          </HeaderBadge>

          <HeaderButton
            text="💃 Call girls"
            variant="outline-danger"
            onClick={() => {
              sendUpdateEvent(MODULE_EVENTS.COMMAND, {
                to: 'interface',
                cmd: 'call_girls',
              });
            }}
          />
        </>
      );
    }
  },
);

/**
 *
 */
type HeaderConnectedServicesSectionProps = {}
export const HeaderConnectedServicesSection_C = memo(
  function HeaderConnectedServicesSection({}: HeaderConnectedServicesSectionProps) {
    const { connectedServices } = useContext(AppContext);
    const entries = Object.entries(connectedServices).sort((a, b) => a[0].localeCompare(b[0]));

    return (
      <>
        {entries.map(([serviceName, service]) => {
          const { numConnected, numExpected, hasSubService, subServiceOk } = service;
          const variant = numConnected !== numExpected
            ? 'danger'
            : hasSubService
              ? subServiceOk ? 'success' : 'warning'
              : 'success';

          return <HeaderButton
            key={serviceName}
            text={`${serviceName[0]}: ${numConnected}`}
            variant={variant}
            onClick={() => openRestartServicePopup(serviceName)}
          />;
        })}
      </>
    );
  },
);

export const openRestartServicePopup = async (serviceName: string) => {
  SwalStyled.fire({
    title: `Are you sure you want to restart ${serviceName}?`,
    html: '<div id="restart-service-form"></div>',
    showConfirmButton: false,
    showCloseButton: true,
    width: '600px',
    didOpen: () => {
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#restart-service-form',
      ) as HTMLElement;

      if (formContainer) {
        const root: Root = createRoot(formContainer);
        root.render(
          <RestartServiceForm
            serviceName={serviceName}
            onClose={() => SwalStyled.close()}
          />,
        );

        (SwalStyled as any)._restartServiceRoot = root;
      }
    },
    willClose: () => {
      // Clean up the React component when modal is closed
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#restart-service-form',
      ) as HTMLElement;

      if (formContainer && (SwalStyled as any)._restartServiceRoot) {
        const root: Root = (SwalStyled as any)._restartServiceRoot;
        root.unmount();
        delete (SwalStyled as any)._restartServiceRoot;
      }
    },
  });
};

const serviceNameDeploymentMap: Record<SOCKET_PATH, string> = {
  [SOCKET_PATH.NODER]: 'noder-noder',
  [SOCKET_PATH.EVENTER]: 'eventer-eventer',
  [SOCKET_PATH.CRONER]: 'croner-croner',
  [SOCKET_PATH.WORKER]: 'worker-worker',
  [SOCKET_PATH.TRANSACTIONS]: 'transactions-transactions',
  [SOCKET_PATH.MONITORING]: 'monitoring-monitoring',
  [SOCKET_PATH.AGGREGATOR]: 'aggregator-aggregator',
  [SOCKET_PATH.AUTOMATION]: 'automation-automation',
  [SOCKET_PATH.INTERFACE]: '',
};

interface RestartServiceProps {
  serviceName: string;
  onClose: () => void;
}

const RestartServiceForm: React.FC<RestartServiceProps> = ({ serviceName, onClose }) => {
  const handleRestart = () => {
    sendUpdateEvent(MODULE_EVENTS.RESTART_SERVICE, { serviceName });
    onClose();
  };

  const handleRestartWithScaling = () => {
    const deploymentName = serviceNameDeploymentMap[serviceName as SOCKET_PATH];
    const namespace = serviceName === 'worker' ? 'worker' : 'core';
    sendUpdateEvent(MODULE_EVENTS.RESTART_SERVICE_WITH_SCALING, {
      deploymentName,
      namespace,
    });
    onClose();
  };

  return (
    <div style={{ marginTop: '24px' }}>
      <Button
        style={{ width: '160px', marginRight: '24px' }}
        variant="primary"
        onClick={handleRestart}
      >
        Restart
      </Button>
      <Button
        style={{ width: '160px', color: 'white' }}
        variant="warning"
        onClick={handleRestartWithScaling}
      >
        Restart By Scaling
      </Button>
    </div>
  );
};

const blockSnapshot: { [key in NETWORK]?: { value: string; timeUpdate: number } } = {};
const blockTimeDiffTreshold: { [key in NETWORK]?: number } & { default: number } = {
  [NETWORK.ETHEREUM]: 15_000,
  [NETWORK.ARBITRUM]: 5_000,
  [NETWORK.BASE]: 6_000,
  [NETWORK.BINANCE]: 6_000,
  [NETWORK.POLYGON]: 8_000,
  default: 40_000,
};

/**
 *
 */
type HeaderBlockNumSectionProps = {}
export const HeaderBlockNumSection_C = memo(function HeaderBlockNumSection({}: HeaderBlockNumSectionProps) {
  const { newHeads, goNoderNewHeads } = useContext(AppContext);
  const networkNames = Array.from(new Set([...Object.keys(newHeads), ...Object.keys(goNoderNewHeads)]));

  return (
    <div className="d-flex gap-0 justify-content-end col">
      {/* <div style={{ position: 'absolute', right: '0', top: '0', display: 'flex' }}> */}
      {networkNames
        .sort((a, b) => a.localeCompare(b))
        .map((_networkName) => {
          const networkName = _networkName as NETWORK;

          const bnString = (newHeads[networkName]?.blockNumber ?? -1).toString();
          const bnLocaleString = (newHeads[networkName]?.blockNumber ?? -1).toLocaleString();

          const goNoderBnString = parseInt(
            goNoderNewHeads[networkName]?.blockNumber ?? '-1',
          ).toString();

          let msSinceLastUpd = 0;
          let tresholdValue = blockTimeDiffTreshold[networkName] || blockTimeDiffTreshold.default;

          const snapshotLastValue = blockSnapshot[networkName];
          if (!snapshotLastValue) {
            blockSnapshot[networkName] = {
              value: bnString,
              timeUpdate: Date.now(),
            };
          } else {
            if (snapshotLastValue.value !== bnString) {
              blockSnapshot[networkName] = {
                value: bnString,
                timeUpdate: Date.now(),
              };
            } else {
              msSinceLastUpd = Date.now() - snapshotLastValue.timeUpdate;
            }
          }

          let showWarning = false;
          if (
            tresholdValue !== undefined &&
            tresholdValue > 0 &&
            msSinceLastUpd > 0 &&
            msSinceLastUpd > tresholdValue
          ) {
            showWarning = true;
          }

          const timeDiffToDisplay = Math.floor(msSinceLastUpd / 1000);

          const goNoderBnLocaleString = parseInt(
            goNoderNewHeads[networkName]?.blockNumber ?? '-1',
          ).toLocaleString();
          return (
            <HeaderButton
              key={networkName}
              img={getNetworkImage(networkName)}
              variant={showWarning ? 'danger' : undefined}
              text={
                <>
                  <Stack
                    direction="vertical"
                    style={{ position: 'relative', fontVariantNumeric: 'tabular-nums' }}
                  >
										<span>
											{bnString.length > 2
                        ? bnString.slice(bnString.length - 2)
                        : bnString}
										</span>
                    <span
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        fontSize: '0.6em',
                        marginTop: '-6px',
                      }}
                    >
											{/* {goNoderBnString.length > 2
												? goNoderBnString.slice(goNoderBnString.length - 2)
												: goNoderBnString} */}
                      {`${timeDiffToDisplay}s`}
										</span>
                  </Stack>
                </>
              }
              hoverText={
                <>
                  <Stack
                    direction="vertical"
                    style={{ position: 'relative', fontVariantNumeric: 'tabular-nums' }}
                  >
                    <span>{bnLocaleString}</span>
                    <span
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        fontSize: '0.6em',
                        marginTop: '-6px',
                      }}
                    >
											{`${timeDiffToDisplay}s`}
										</span>
                  </Stack>
                </>
              }
              onClick={() => {
                window.open(
                  `${NETWORK_EXPLORER_ADDRESS[networkName]}/block/${bnString}`,
                  '_blank',
                );
              }}
            />
          );
        })}
    </div>
    // </>
  );
});
