import React, {memo, useDeferredValue, useState} from "react"

import {Button, Col, Container, Form, Row, Stack} from "react-bootstrap"

import Header_C, {
	HeaderBlockNumSection_C,
	HeaderConnectedServicesSection_C,
	HeaderSection,
	HeaderStartStopSection_C,
} from "../../../components/Header"
import Content, {ContentSection, ContentSpacer} from "../../../components/Content"
import Sidebar, {
	SidebarFilterButton,
	SidebarFilterRow,
	SidebarRootNavLinks,
	SidebarSection,
} from "../../../components/Sidebar"

import {SidebarSettingsNavLinks} from ".."
import SettingsTokenTable from "./TokensTable"

import {DBBridges, DBMiscSettings, DBTokens} from "../../../common/types"

import {createToken} from "./actions"

/**
 * Filter data
 */
export type SettingsTokensFilterData = {
    tokenEnabled: "true" | "false" | "none"
    tokenAutomation: "true" | "false" | "none"
    tokenHidden: "true" | "false" | "none"
    tokenBridge: "true" | "false" | "none"
    tokenComments: "true" | "false" | "none"
    hasMissingData: "true" | "false" | "none"
}
const defaultFilterData: SettingsTokensFilterData = {
    tokenEnabled: "none",
    tokenAutomation: "none",
    tokenHidden: "none",
    tokenBridge: "none",
    tokenComments: "none",
    hasMissingData: "none",
}

/**
 * Sorting data
 */
export type SettingsTokensSortingData = {
    sortBy: "tokenName" | "slippage" | "bridgeName"
    sortOrder: "asc" | "desc"
}
const defaultSortingData: SettingsTokensSortingData = {
    sortBy: "tokenName",
    sortOrder: "asc",
}

/**
 * Token settings page
 *  Route '/settings/tokens'
 */
type SettingsTokensProps = {
    allTokens: DBTokens
    bridges: DBBridges
    miscSettings?: DBMiscSettings
}
export default memo(function SettingsTokens({allTokens, bridges, miscSettings}: SettingsTokensProps) {
    const [textFilter, setTextFilter] = useState("")
    const [filterData, setFilterData] = useState<SettingsTokensFilterData>(defaultFilterData)
    const [sortingData, setSortingData] = useState<SettingsTokensSortingData>(defaultSortingData)

    const deferredTextFilter = useDeferredValue(textFilter)

    return (
        <>
            <Header_C>
                <HeaderSection>
                    <HeaderStartStopSection_C/>
                </HeaderSection>
                <HeaderSection justifyContent="center">
                    <HeaderConnectedServicesSection_C/>
                </HeaderSection>
                <HeaderSection justifyContent="end">
                    <HeaderBlockNumSection_C/>
                </HeaderSection>
            </Header_C>

            <Container fluid className="main">
                <Row>
                    <Col className="sidebarContainer">
                        <Sidebar>
                            <SidebarRootNavLinks/>
                            <SidebarSettingsNavLinks/>

                            <SidebarSection name="Filters" gap={2}>
                                <SidebarTextFilter {...{textFilter, setTextFilter}} />
                                <SidebarPropertyFilter {...{filterData, setFilterData}} />
                            </SidebarSection>

                            <SidebarSection name="Sort by" gap={2}>
                                <SidebarSortBy {...{sortingData, setSortingData}} />
                                <SidebarClearFilters
                                    {...{
                                        textFilter,
                                        filterData,
                                        sortingData,
                                        setTextFilter,
                                        setFilterData,
                                        setSortingData,
                                    }}
                                />
                            </SidebarSection>

                            <SidebarSection renderP gap={1}>
                                <SideButtons {...{allTokens}} />
                            </SidebarSection>
                        </Sidebar>
                    </Col>
                    <Col className="contentContainer">
                        <Content>
                            <ContentSection>
                                <SettingsTokenTable
                                    tokens={allTokens}
                                    bridges={bridges}
                                    filterData={filterData}
                                    sortingData={sortingData}
                                    textFilter={deferredTextFilter}
                                    miscSettings={miscSettings}
                                />
                            </ContentSection>
                            <ContentSection>
                                <ContentSpacer height={100}/>
                            </ContentSection>
                        </Content>
                    </Col>
                </Row>
            </Container>
        </>
    )
})

/**
 *
 */
type SidebarTextFilterProps = {
    textFilter: string
    setTextFilter: React.Dispatch<React.SetStateAction<string>>
}
const SidebarTextFilter = memo(function SidebarTextFilter({
                                                              textFilter,
                                                              setTextFilter,
                                                          }: SidebarTextFilterProps) {
    return (
        <Form.Control
            size="sm"
            type="text"
            placeholder="token | address | b: n:"
            value={textFilter}
            onChange={(e) => setTextFilter(e.target.value)}
        />
    )
})

/**
 *
 */
type SidebarPropertyFilterProps = {
    filterData: SettingsTokensFilterData
    setFilterData: React.Dispatch<React.SetStateAction<SettingsTokensFilterData>>
}
const SidebarPropertyFilter = memo(function SidebarPropertyFilter({
                                                                      filterData,
                                                                      setFilterData,
                                                                  }: SidebarPropertyFilterProps) {
    const {tokenEnabled, tokenAutomation, tokenHidden, tokenBridge, tokenComments, hasMissingData} =
        filterData

    return (
        <>
            <SidebarFilterRow text="Enabled">
                <SidebarFilterButton
                    text="True"
                    active={tokenEnabled === "true"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            tokenEnabled: tokenEnabled === "true" ? "none" : "true",
                        })
                    }
                />
                <SidebarFilterButton
                    text="False"
                    active={tokenEnabled === "false"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            tokenEnabled: tokenEnabled === "false" ? "none" : "false",
                        })
                    }
                />
            </SidebarFilterRow>
            <SidebarFilterRow text="Automation">
                <SidebarFilterButton
                    text="True"
                    active={tokenAutomation === "true"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            tokenAutomation: tokenAutomation === "true" ? "none" : "true",
                        })
                    }
                />
                <SidebarFilterButton
                    text="False"
                    active={tokenAutomation === "false"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            tokenAutomation: tokenAutomation === "false" ? "none" : "false",
                        })
                    }
                />
            </SidebarFilterRow>
            <SidebarFilterRow text="Hidden">
                <SidebarFilterButton
                    text="True"
                    active={tokenHidden === "true"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            tokenHidden: tokenHidden === "true" ? "none" : "true",
                        })
                    }
                />
                <SidebarFilterButton
                    text="False"
                    active={tokenHidden === "false"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            tokenHidden: tokenHidden === "false" ? "none" : "false",
                        })
                    }
                />
            </SidebarFilterRow>
            <SidebarFilterRow text="Bridge">
                <SidebarFilterButton
                    text="True"
                    active={tokenBridge === "true"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            tokenBridge: tokenBridge === "true" ? "none" : "true",
                        })
                    }
                />
                <SidebarFilterButton
                    text="False"
                    active={tokenBridge === "false"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            tokenBridge: tokenBridge === "false" ? "none" : "false",
                        })
                    }
                />
            </SidebarFilterRow>
            <SidebarFilterRow text="Comments">
                <SidebarFilterButton
                    text="True"
                    active={tokenComments === "true"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            tokenComments: tokenComments === "true" ? "none" : "true",
                        })
                    }
                />
                <SidebarFilterButton
                    text="False"
                    active={tokenComments === "false"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            tokenComments: tokenComments === "false" ? "none" : "false",
                        })
                    }
                />
            </SidebarFilterRow>
            <SidebarFilterRow text="Missing data">
                <SidebarFilterButton
                    text="True"
                    active={hasMissingData === "true"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            hasMissingData: hasMissingData === "true" ? "none" : "true",
                        })
                    }
                />
                <SidebarFilterButton
                    text="False"
                    active={hasMissingData === "false"}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            hasMissingData: hasMissingData === "false" ? "none" : "false",
                        })
                    }
                />
            </SidebarFilterRow>
        </>
    )
})

/**
 *
 */
type SidebarSortByProps = {
    sortingData: SettingsTokensSortingData
    setSortingData: React.Dispatch<React.SetStateAction<SettingsTokensSortingData>>
}
const SidebarSortBy = memo(function SidebarSortBy({sortingData, setSortingData}: SidebarSortByProps) {
    return (
        <>
            <Stack direction="horizontal" gap={2} style={{justifyContent: "space-between"}}>
                <SidebarFilterButton
                    text="Token"
                    style={{width: "100%"}}
                    active={sortingData.sortBy === "tokenName"}
                    onClick={() => setSortingData({...sortingData, sortBy: "tokenName"})}
                />
                <SidebarFilterButton
                    text="Slippage"
                    style={{width: "100%"}}
                    active={sortingData.sortBy === "slippage"}
                    onClick={() => setSortingData({...sortingData, sortBy: "slippage"})}
                />
                <SidebarFilterButton
                    text="Bridge"
                    style={{width: "100%"}}
                    active={sortingData.sortBy === "bridgeName"}
                    onClick={() => setSortingData({...sortingData, sortBy: "bridgeName"})}
                />
            </Stack>
            <Stack direction="horizontal" gap={2}>
                <SidebarFilterButton
                    text="Ascending"
                    style={{width: "100%"}}
                    active={sortingData.sortOrder === "asc"}
                    onClick={() => setSortingData({...sortingData, sortOrder: "asc"})}
                />
                <SidebarFilterButton
                    text="Descending"
                    style={{width: "100%"}}
                    active={sortingData.sortOrder === "desc"}
                    onClick={() => setSortingData({...sortingData, sortOrder: "desc"})}
                />
            </Stack>
        </>
    )
})

/**
 *
 */
type SidebarClearFiltersProps = {
    textFilter: string
    filterData: SettingsTokensFilterData
    sortingData: SettingsTokensSortingData
    setTextFilter: React.Dispatch<React.SetStateAction<string>>
    setFilterData: React.Dispatch<React.SetStateAction<SettingsTokensFilterData>>
    setSortingData: React.Dispatch<React.SetStateAction<SettingsTokensSortingData>>
}
const SidebarClearFilters = memo(function SidebarClearFilters({
                                                                  textFilter,
                                                                  filterData,
                                                                  sortingData,
                                                                  setTextFilter,
                                                                  setFilterData,
                                                                  setSortingData,
                                                              }: SidebarClearFiltersProps) {
    const filtersPresent =
        false ||
        textFilter !== "" ||
        JSON.stringify(filterData) !== JSON.stringify(defaultFilterData) ||
        JSON.stringify(sortingData) !== JSON.stringify(defaultSortingData)

    if (!filtersPresent) return <></>

    return (
        <Button
            size="sm"
            variant="dark"
            onClick={() => {
                setTextFilter("")
                setFilterData(defaultFilterData)
                setSortingData(defaultSortingData)
            }}
        >
            Clear all filters
        </Button>
    )
})

/**
 *
 */
type SideButtonsProps = {
    allTokens: DBTokens
}
const SideButtons = memo(function SideButtons({allTokens}: SideButtonsProps) {
    return (
        <Button size="sm" variant="success" onClick={() => createToken(allTokens)}>
            Create new token
        </Button>
    )
})
