import React, { memo, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Updater } from 'use-immer';

import { Badge, Button, Col, Container, Form, Row, Stack } from 'react-bootstrap';

import Header_C, {
  HeaderBlockNumSection_C,
  HeaderConnectedServicesSection_C,
  HeaderSection,
  HeaderStartStopSection_C,
} from '../../components/Header';
import Content, {
  ConnectedUsersBlock,
  ContentSection,
  ContentSpacer,
  GasBlock,
} from '../../components/Content';
import Sidebar, {
  SidebarButtonToggle,
  SidebarFilterButton,
  SidebarFilterRow,
  SidebarRootNavLinks,
  SidebarSection,
} from '../../components/Sidebar';

import { AppContext, AppState, sendUpdateEvent } from '../../App';
import DashboardTable from './DashboardTable';

import {
  BRAIN_EVENTS,
  CheckerRes,
  DBAutomationMinCoeffRules,
  DBBridges,
  DBMiscSettings,
  DBTokens,
  filterActiveTokens,
  LockedNonces,
  MODULE_EVENTS,
  MODULE_EVENTS_PARAMS,
  NativeTokens,
  NETWORK,
  NETWORK_EXPLORER_ADDRESS,
  NETWORK_NATIVE_TOKEN,
  NETWORK_SOURCE_TOKEN,
  REQUESTER,
  Sell,
  WORKER_EVENTS,
} from '../../common';

import {
  selectAccessKey,
  setWorkerTrackingValues,
  toggleGlobalAutomation,
  toggleRequester,
  toggleRequesterMode,
  updateSellBribe,
  updateSellGasPriceGwei,
  updateSellMinAmountOut,
} from './actions';

import { eye, eyeSlash } from '../../helper/svg';
import { getNetworkImage } from '../../assets/networks';
import BigNumber from 'bignumber.js';
import { getAggregatorImage } from '../../assets/aggregators';
import {
  handleRetrySellOrClaimClick,
  handleUpdateSell,
  handleUpdateSellHash,
} from '../transactions';
import Sound from '../../helper/Sound';

/**
 * Filter data
 */
export type DashboardFilterData = {
  displayTokens: 'allall' | 'all' | 'unhidden' | 'hidden'
  dexPosition: 'left' | 'bottom' | 'none'
  sortDisableAutomation: 'false' | 'true'
  displayRoutes: 'true' | 'false' | 'bn'
}
const defaultFilterData: DashboardFilterData = {
  displayTokens: 'unhidden',
  dexPosition: 'left',
  sortDisableAutomation: 'false',
  displayRoutes: 'false',
};

const ParseEventerStatString = (stats: string) => {
  const splitted = stats.split(' | ');

  const multis: ReactNode[] = [];
  const singles: ReactNode[] = [];

  for (const str of splitted) {
    const [
      _networkNameWithMultiId,
      status,
      _lastReceiveTime,
      _lastNonDupReceiveTime,
      _avgMsBehind,
      _expectedEventSecDiff,
    ] = str.split(':');

    const [networkName, multiId] = _networkNameWithMultiId.split('-');

    const onClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      sendUpdateEvent(MODULE_EVENTS.COMMAND, {
        to: 'eventer',
        cmd: `connectOne~${_networkNameWithMultiId}~${250}`,
      });
    };

    const lastReceiveTime = parseInt(_lastReceiveTime || '0');
    const msDiff = Date.now() - lastReceiveTime;
    const sDiff = parseInt((msDiff / 1000).toFixed(0));
    const sDiffAdj = Math.min(sDiff, 99);

    const lastNonDupReceiveTime = parseInt(_lastNonDupReceiveTime || '0');
    const msDiffNonDup = Date.now() - lastNonDupReceiveTime;
    const sDiffNonDup = parseInt((msDiffNonDup / 1000).toFixed(0));
    const sDiffAdjNonDup = Math.min(sDiffNonDup, 99);

    const avgMsBehind = parseInt(_avgMsBehind);
    const avgMsBehindAdj = (avgMsBehind / 1000).toFixed(1).replace('0.', '.');

    const expectedEventSecDiff = parseInt(_expectedEventSecDiff);

    let isWarn = status === 'connected' && sDiffAdj > expectedEventSecDiff * 2;
    let isError =
      status !== 'connected' || (status === 'connected' && sDiffAdj > expectedEventSecDiff * 4);

    let statusAdj = status;
    if (statusAdj === 'connected') statusAdj = 'ok';
    if (statusAdj === 'stopped') statusAdj = 'st';
    if (statusAdj === 'created') {
      isWarn = true;
      isError = false;
      statusAdj = 'cr';
    }

    const sDiffAdjStr = sDiffAdj < 10 ? `0${sDiffAdj}` : `${sDiffAdj}`;

    const avgMsBehindStr = multiId !== undefined && avgMsBehind > 0 ? `${avgMsBehindAdj}/` : '';
    // multiId !== undefined ? `${sDiffAdjNonDup}s/`:''

    const bgColor = isError ? 'danger' : isWarn ? 'warning' : 'light';
    const badge = (
      <Badge
        onClick={(e) => onClick(e)}
        bg={bgColor}
        style={{ cursor: 'pointer', color: isError ? 'white' : 'black' }}
      >
        <div
          style={{
            display: 'inline-block',
            backgroundColor: 'white',
            padding: '2px',
            borderRadius: '100px',
          }}
        >
          <img src={getNetworkImage(networkName)} width={14} height={14} />
        </div>
        {' '}
        <p
          style={{
            display: 'inline-block',
            fontFamily: 'monospace',
            margin: '0',
          }}
        >
          {/* {multiId !== undefined ? `${multiId} ` : ""} */}
          {statusAdj},{avgMsBehindStr}
          {sDiffAdjStr}s
        </p>
      </Badge>
    );

    if (multiId !== undefined) {
      multis.push(badge);
    } else {
      singles.push(badge);
    }
  }

  const joined = [...singles, ...multis];
  const chunked = [...chunks(joined, 12)];

  return (
    <>
      {chunked.map(chunk => (
        <div>{chunk}</div>
      ))}
    </>
  );
};

function* chunks<T>(arr: T[], n: number): Generator<T[], void> {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

const WorkerStatsString = memo(function WorkerStatsString() {
  const {
    workerStatsString,
    workerAnalyticsString,
    workerEventsString,
    eventerStatsString,
    workerLpStatString,
  } = useContext(AppContext);
  const workerStatsStringSplitted = workerStatsString.split('\n');
  const workerEventsStringSplitted = workerEventsString.split('\n');
  const workerLpStatStringSplitted = workerLpStatString.split('\n');
  return (
    <div style={{ marginBottom: '6px' }}>
      {ParseEventerStatString(eventerStatsString)}
      {workerStatsStringSplitted.map((s, i) => (
        <p key={i} style={{ fontSize: '0.8em', marginBottom: '2px' }}>
          {s}
        </p>
      ))}
      <p style={{ fontSize: '0.8em' }}>{workerAnalyticsString}</p>
      {workerLpStatStringSplitted.map((s, i) => (
        <p key={i} style={{ fontSize: '0.8em', marginBottom: '2px' }}>
          {s}
        </p>
      ))}
      {/* {workerEventsStringSplitted.map((s, i) => (
				<p key={i} style={{ fontSize: "0.8em" }}>
					{s}
				</p>
			))} */}
    </div>
  );
});

type PendingSellsProps = {
  sendBackTokens: AppState['brainState']['autosellPins']
  pinnedTokens: AppState['brainState']['pinnedTokens']
  balance: DashboardProps['balance']
  sells: Sell[]
}
const PendingSells = memo(function PendingSells(
  {
    balance,
    sells,
    sendBackTokens,
  }: PendingSellsProps) {
  const allowedSellBribeNetworks = [NETWORK.ETHEREUM, NETWORK.BINANCE, NETWORK.SOLANA];
  const showBribeButton = (network: NETWORK) => allowedSellBribeNetworks.includes(network);
  const showGasPriceButton = (network: NETWORK) => network !== NETWORK.SOLANA;

  const items = sells.map((s, idx) => {
    const {
      balance: sBalance,
      aggregatorAmountOut,
      minAmountOut,
      expectedAmountOut,
      bribe,
      sellStatus,
      claimStatus,
      isClaimable,
    } = s;

    let tokenBalance = balance[s.networkSell]?.[s.token.tokenName] ?? sBalance;
    tokenBalance = BigNumber(tokenBalance).isZero() ? '0' : tokenBalance;
    const sellBalance = BigNumber(sBalance).isZero() ? '0' : sBalance;
    const balanceChanged = tokenBalance !== sellBalance;
    const sendBackPin = Object.keys(sendBackTokens).find((sbt) => {
      if (!sendBackTokens[sbt]) {
        return false;
      }
      const tokenName = sbt.split('_')[2];
      return tokenName === s.token.tokenName;
    });
    const shouldSendBack = !!sendBackPin && !s.sendBack;

    const itemHeight = 22;
    const entryStyle = {
      display: 'flex',
      fontSize: '0.8em',
      marginRight: '4px',
      height: itemHeight,
      lineHeight: 1,
      fontWeight: 400,
    };
    const keyStyle = { fontWeight: 600, display: 'block', marginRight: '4px' };
    const valStyle = { display: 'block' };

    const etherIcon = (
      <img
        style={{ display: 'block', marginLeft: '2px' }}
        width={12}
        height={12}
        src={getNetworkImage('Ethereum')}
      />
    );

    const deltaDecim = (aggregatorAmountOut ?? 0) - (expectedAmountOut ?? 0);
    const delta = normalizeValueNum(deltaDecim);
    const deltaColor = delta >= 0 ? 'success' : 'danger';

    const claimWarning = isClaimable && claimStatus !== 'created' && claimStatus !== 'completed';
    const claimColor =
      claimStatus === 'completed'
        ? 'success'
        : isClaimable
          ? claimWarning
            ? 'warning'
            : 'info'
          : 'secondary';
    const claimIcon =
      claimStatus === 'skip' || claimStatus === 'stale'
        ? ''
        : claimStatus === 'failed'
          ? '🤬'
          : claimStatus === 'completed'
            ? '🎉'
            : '⏳';

    const sellColor =
      sellStatus === 'completed'
        ? 'success'
        : sellStatus === 'skip' || sellStatus === 'stale' || sellStatus === 'failed'
          ? 'warning'
          : 'info';
    const sellIcon =
      sellStatus === 'skip' || sellStatus === 'stale'
        ? ''
        : sellStatus === 'failed'
          ? '🤬'
          : sellStatus === 'completed'
            ? '🎉'
            : '⏳';

    return (
      <div key={s.tradeId}
           style={{ display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
        <Badge
          bg="info"
          style={{
            fontSize: '0.8em',
            fontWeight: 600,
            marginRight: '4px',
            minWidth: '170px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => handleUpdateSell(s, shouldSendBack)}
        >
          <img
            width="14px"
            height="14px"
            alt=""
            style={{ marginRight: '4px' }}
            src={getNetworkImage(s.networkBuy)}
          />
          {'>'}
          <img
            width="14px"
            height="14px"
            alt=""
            style={{ marginLeft: '4px', marginRight: '4px' }}
            src={getNetworkImage(s.networkSell)}
          />
          {shouldSendBack && s.networkSendBack && (
            <>
              {'>'}
              <img
                width="14px"
                height="14px"
                alt=""
                style={{ marginLeft: '4px', marginRight: '4px' }}
                src={getNetworkImage(s.networkSendBack)}
              />
            </>
          )}
          {s.tradeId}
        </Badge>

        <Badge
          bg={balanceChanged ? 'warning' : 'secondary'}
          style={{
            ...entryStyle,
            backgroundColor: balanceChanged ? '#ffcc00' : 'inherit',
          }}
        >
          <span style={valStyle}>{tokenBalance}</span>
          <span
            style={{
              ...valStyle,
              fontWeight: 600,
              marginLeft: '4px',
            }}
          >
						{s.token.tokenName}
					</span>
        </Badge>

        <Badge
          style={{ ...entryStyle, maxWidth: '200px', cursor: 'pointer' }}
          onClick={() => {
            handleUpdateSellHash(s.tradeId);
          }}
        >
          <span style={valStyle}>{ellipsis(s.bridgeTxHash)}</span>
        </Badge>

        <Badge
          bg={sellColor}
          style={{ ...entryStyle, cursor: 'pointer' }}
          onClick={() => {
            if (sellStatus === 'created') return;
            handleRetrySellOrClaimClick(false, s.tradeId);
          }}
        >
          <span style={keyStyle}>Sell: </span>
          <span style={valStyle}>
						{sellStatus}
            {sellIcon}
					</span>
        </Badge>

        <Badge
          bg={claimColor}
          style={{ ...entryStyle, cursor: 'pointer' }}
          onClick={() => {
            if (claimStatus === 'pending' || claimStatus === 'created') return;
            handleRetrySellOrClaimClick(true, s.tradeId);
          }}
        >
          <span style={keyStyle}>Claim: </span>
          <span style={valStyle}>
						{claimStatus}
            {claimIcon}
					</span>
        </Badge>

        <Badge bg="secondary" style={entryStyle}>
          <span style={keyStyle}>{s.aggregatorType}: </span>{' '}
          <span style={valStyle}>{aggregatorAmountOut}</span>
          {etherIcon}
        </Badge>

        <Badge bg="secondary" style={entryStyle}>
          <span style={keyStyle}>Expected: </span> <span
          style={valStyle}>{expectedAmountOut}</span>
          {etherIcon}
        </Badge>

        <Badge bg={deltaColor} style={entryStyle}>
          <span style={keyStyle}>Δ: </span>
          <span style={valStyle}>{delta}</span>
          {etherIcon}
        </Badge>

        <Button
          onClick={() =>
            updateSellMinAmountOut(
              s.tradeId,
              s.token.tokenName,
              s.minAmountOut ?? s.expectedAmountOut ?? 0,
            )
          }
          color="secondary"
          size="sm"
          style={{ ...entryStyle, height: itemHeight, lineHeight: 0.9 }}
        >
          <span style={keyStyle}>Min:</span>
          <span style={valStyle}>{minAmountOut}</span>
          {etherIcon}
        </Button>

        {showBribeButton(s.networkSell) && (
          <Button
            onClick={() => updateSellBribe(s.tradeId, s.networkSell, s.token.tokenName, s.bribe ?? 0)}
            color="secondary"
            size="sm"
            style={{ ...entryStyle, height: itemHeight, lineHeight: 0.9 }}
          >
            <span style={keyStyle}>Bribe:</span>
            <span style={valStyle}>{bribe}</span>
            {etherIcon}
          </Button>
        )}

        {showGasPriceButton(s.networkSell) && (
          <Button
            onClick={() =>
              updateSellGasPriceGwei(s.tradeId, s.token.tokenName, s.gasPriceGwei ?? 0)
            }
            color="secondary"
            size="sm"
            style={{ ...entryStyle, height: itemHeight, lineHeight: 0.9 }}
          >
            <span style={keyStyle}>Gas Price:</span>
            <span style={valStyle}>{s.gasPriceGwei} gwei</span>
          </Button>
        )}
      </div>
    );
  });

  return (
    <div
      style={{
        marginBottom: '6px',
        display: 'flex',
        alignItems: 'baseline',
        flexDirection: 'column',
      }}
    >
      <p style={{ fontSize: '0.8em', marginBottom: '4px', fontWeight: 600 }}>Sells</p>
      {items.map((Item) => Item)}
    </div>
  );
});

type LockedNoncesListProps = {
  data: LockedNonces
}
const LockedNoncesList = memo(function LockedNoncesList({ data }: LockedNoncesListProps) {
  const items = Object.keys(data).reduce((acc, addr) => {
    const addrNonces = Object.keys(data[addr]).reduce((addrAcc, network) => {
      const nonce = data[addr][network as NETWORK];
      if (Number.isNaN(Number(nonce))) {
        return addrAcc;
      }
      return [
        ...addrAcc,
        {
          address: addr,
          network: network as NETWORK,
          nonce: nonce as number,
        },
      ];
    }, [] as { address: string; network: NETWORK; nonce: number }[]);
    return [...acc, ...addrNonces];
  }, [] as { address: string; network: NETWORK; nonce: number }[]);

  return (
    <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'baseline' }}>
      <p style={{ fontSize: '0.8em', marginRight: '4px' }}>[Locked Nonces]: </p>
      {items.map((item) => (
        <div key={`${item.address}${item.network}`} style={{ marginRight: '8px' }}>
          <img
            width="14px"
            height="14px"
            src={getNetworkImage(item.network)}
            alt=""
            style={{ marginRight: '4px' }}
          />
          <span
            style={{
              fontSize: '0.8em',
              fontWeight: 600,
              marginRight: '2px',
            }}
          >
						{item.nonce}
					</span>
          <span
            style={{
              fontSize: '0.8em',
              fontWeight: 600,
              marginRight: '2px',
            }}
          >
						({item.address.substring(item.address.length - 3, item.address.length)})
					</span>
        </div>
      ))}
    </div>
  );
});

/**
 * Dashboard page
 *  Route '/'
 */
type DashboardProps = {
  workersRunning: AppState['brainState']['workersRunning']
  workersStartedBy: AppState['brainState']['workersStartedBy']
  pinnedTokens: AppState['brainState']['pinnedTokens']
  autosellPins: AppState['brainState']['autosellPins']
  wethPriceUSD: AppState['brainState']['wethPriceUSD']
  nativePriceUSD: AppState['brainState']['nativePriceUSD']
  newHeads: AppState['newHeads']
  allTokens: DBTokens
  bridges: DBBridges
  trackingResults: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_ITERATION_RESULT]
  trackingErrors: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_ITERATION_ERROR]
  selectedAccount: AppState['user']
  miscSettings: AppState['miscSettings']
  connectedUsers: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CONNECTED_USERS]
  gasEstimate: AppState['gasEstimate']
  balance: AppState['balance']
  secondaryBalance: AppState['secondaryBalance']
  automationRules?: AppState['automationRules']
  checkerRes?: CheckerRes
  setAppState: Updater<AppState>
}
export default memo(function Dashboard({
                                         workersRunning,
                                         workersStartedBy,
                                         pinnedTokens,
                                         autosellPins,
                                         wethPriceUSD,
                                         nativePriceUSD,
                                         newHeads,
                                         allTokens,
                                         bridges,
                                         trackingResults,
                                         trackingErrors,
                                         selectedAccount,
                                         miscSettings,
                                         connectedUsers,
                                         gasEstimate,
                                         balance,
                                         secondaryBalance,
                                         automationRules,
                                         checkerRes,
                                         setAppState,
                                       }: DashboardProps) {
  const {
    brainState: { pendingSells, lockedNonces, walletAddress },
  } = useContext(AppContext);
  const [textFilter, setTextFilter] = useState('');
  const [filterData, setFilterData] = useState<DashboardFilterData>(defaultFilterData);

  const workerTokens = useMemo(() => filterActiveTokens(allTokens), [allTokens]);
  const hasLockedNonces = useMemo(
    () => lockedNonces !== undefined && Object.values(lockedNonces).some((n) => !!n),
    [lockedNonces],
  );
  const ethGasPrice = gasEstimate[NETWORK.ETHEREUM]?.gasHigh ?? -1;
  const minProfit = getMinProfit(ethGasPrice, automationRules?.minCoeffRules);

  return (
    <>
      <Header_C>
        <HeaderSection>
          <HeaderStartStopSection_C />
        </HeaderSection>
        <HeaderSection justifyContent="center">
          <HeaderConnectedServicesSection_C />
        </HeaderSection>
        <HeaderSection justifyContent="end">
          <HeaderBlockNumSection_C />
        </HeaderSection>
      </Header_C>

      <Container fluid className="main">
        <Row>
          <Col className="sidebarContainer">
            <Sidebar>
              <SidebarRootNavLinks />

              <SidebarAccountSection
                selectedAccount={selectedAccount}
                setAppState={setAppState}
              />

              <SidebarAutomationSection
                globalAutomation={miscSettings?.globalAutomation}
                dashboardDisplayAllPinnedTokens={
                  miscSettings?.dashboardDisplayAllPinnedTokens
                }
              />

              <SidebarWorkerSection
                workerIncludeSlippage={miscSettings?.workerIncludeSlippage}
                workerTrackingValues={miscSettings?.workerTrackingValues}
                requesters={miscSettings?.requesters}
                requesterSettings={miscSettings?.requesterSettings}
              />

              <SidebarBalanceSection
                balance={balance}
                secondaryBalance={secondaryBalance}
                walletAddress={walletAddress}
                wethPriceUSD={Number(wethPriceUSD)}
                nativePriceUSD={nativePriceUSD}
              />

              <SidebarSection name="Display settings" gap={2}>
                <SidebarTextFilter textFilter={textFilter}
                                   setTextFilter={setTextFilter} />
                <SidebarDisplaySettingsSection
                  filterData={filterData}
                  setFilterData={setFilterData}
                />
              </SidebarSection>
            </Sidebar>
          </Col>
          <Col className="contentContainer">
            <Content>
              <p>{workersRunning && `Started by: ${workersStartedBy}`}</p>
              {<WorkerStatsString />}
              {!!pendingSells?.length && (
                <PendingSells
                  sendBackTokens={autosellPins}
                  pinnedTokens={pinnedTokens}
                  balance={balance}
                  sells={pendingSells}
                />
              )}
              {hasLockedNonces && <LockedNoncesList data={lockedNonces} />}
              <ContentSection>
                <ConnectedUsersBlock users={connectedUsers} />
                <GasBlock
                  value={ethGasPrice}
                  minProfit={minProfit}
                  otherNetworks={Object.entries(gasEstimate)
                    .filter(([n]) => n !== NETWORK.ETHEREUM)
                    .map(([n, d]) => ({
                      networkName: n,
                      value: d.gasHigh,
                    }))}
                />
              </ContentSection>
              <div style={{ height: '4px' }}></div>
              <ContentSection>
                <DashboardTable
                  workersRunning={workersRunning}
                  dashboardDisplayAllPinnedTokens={false}
                  pinnedTokens={pinnedTokens}
                  autosellPins={autosellPins}
                  newHeads={newHeads}
                  gasEstimate={gasEstimate}
                  allTokens={allTokens}
                  workerTokens={workerTokens}
                  bridges={bridges}
                  trackingResults={trackingResults}
                  trackingErrors={trackingErrors}
                  textFilter={textFilter}
                  filterData={filterData}
                  miscSettings={miscSettings}
                  checkerRes={checkerRes}
                />
              </ContentSection>
              <ContentSection>
                <ContentSpacer height={100} />
              </ContentSection>
            </Content>
          </Col>
        </Row>
      </Container>
    </>
  );
});

/**
 *
 */
type SidebarAccountSectionProps = {
  selectedAccount: AppState['user']
  setAppState: Updater<AppState>
}
const SidebarAccountSection = memo(function GetSidebarSections({
                                                                 selectedAccount,
                                                                 setAppState,
                                                               }: SidebarAccountSectionProps) {
  return (
    <SidebarSection name="Account">
      <SidebarButtonToggle text="User" value={selectedAccount.name} />
      <SidebarButtonToggle
        text="Access Key"
        value={selectedAccount.accessKey ? '********' : '👉 select 👈'}
        onClick={() => selectAccessKey(selectedAccount.accessKey, setAppState)}
      />
    </SidebarSection>
  );
});

/**
 *
 */
type SidebarAutomationSectionProps = {
  globalAutomation?: boolean
  dashboardDisplayAllPinnedTokens?: boolean
}
const SidebarAutomationSection = memo(function SidebarAutomationSection({
                                                                          globalAutomation,
                                                                          dashboardDisplayAllPinnedTokens,
                                                                        }: SidebarAutomationSectionProps) {
  if (globalAutomation === undefined) {
    // if (globalAutomation === undefined || dashboardDisplayAllPinnedTokens === undefined) {
    return (
      <SidebarSection name="Automation">
        <SidebarButtonToggle text="⏳ Loading" />
        {/* <SidebarButtonToggle text="⏳ Loading" /> */}
      </SidebarSection>
    );
  }

  return (
    <SidebarSection name="Automation">
      <SidebarButtonToggle
        text={globalAutomation ? '🟢 Enabled' : '🔴 Disabled'}
        onClick={() => toggleGlobalAutomation(globalAutomation)}
      />
      {/* <SidebarButtonToggle
				text={dashboardDisplayAllPinnedTokens ? "📍 Display all token pins" : "📍 Display network pair pins"}
				onClick={() => toggleDashboardDisplayAllPinnedTokens(dashboardDisplayAllPinnedTokens)}
			/> */}
    </SidebarSection>
  );
});

/**
 *
 */
type SidebarWorkerSectionProps = {
  workerIncludeSlippage?: boolean
  workerTrackingValues?: number[]
  requesters?: DBMiscSettings['requesters']
  requesterSettings?: DBMiscSettings['requesterSettings']
}
const SidebarWorkerSection = memo(function SidebarWorkerSection({
                                                                  workerIncludeSlippage,
                                                                  workerTrackingValues,
                                                                  requesters,
                                                                  requesterSettings,
                                                                }: SidebarWorkerSectionProps) {
  if (workerIncludeSlippage === undefined || workerTrackingValues === undefined) {
    return (
      <SidebarSection name="Worker">
        <SidebarButtonToggle text="⏳ Loading" />
      </SidebarSection>
    );
  }

  return (
    <SidebarSection name="Worker">
      {/* <SidebarButtonToggle
				text={workerIncludeSlippage ? "🟢 Include slippage" : "🔴 Exclude slippage"}
				onClick={() => toggleWorkerIncludeSlippage(workerIncludeSlippage)}
			/> */}
      <SidebarButtonToggle
        text="Tracking"
        value={workerTrackingValues.join(',')}
        onClick={() => setWorkerTrackingValues(workerTrackingValues)}
      />
      <div style={{ marginTop: '4px' }}>
        {Object.values(REQUESTER).map((r) => {
          const rTracking = requesterSettings?.[r]?.tracking || 'all';
          const rTrackingText = (() => {
            switch (rTracking) {
              case 'all':
                return 'A';
              case 'selected':
                return 'S';
                return '?';
            }
          })();
          return (
            <SidebarFilterButton
              key={r}
              style={{ margin: '2px', position: 'relative' }}
              text={
                <div>
                  <img width={14} src={getAggregatorImage(r)}></img>
                  <span
                    style={{
                      opacity: '0.6',
                      position: 'absolute',
                      bottom: '0px',
                      left: '0',
                      fontSize: '0.7em',
                    }}
                  >
										{rTrackingText}
									</span>
                </div>
              }
              active={requesters?.[r] ?? false}
              onClick={(e) => {
                const { shiftKey, metaKey } = e;
                if (shiftKey && metaKey) {
                  if (requesterSettings) {
                    toggleRequesterMode(r, requesterSettings);
                  }
                } else {
                  toggleRequester(r, requesters || {});
                }
              }}
            />
          );
        })}
      </div>
    </SidebarSection>
  );
});

/**
 *
 */
type SidebarTextFilterProps = {
  textFilter: string
  setTextFilter: React.Dispatch<React.SetStateAction<string>>
}
const SidebarTextFilter = memo(function SidebarTextFilter({
                                                            textFilter,
                                                            setTextFilter,
                                                          }: SidebarTextFilterProps) {
  return (
    <Form.Control
      size="sm"
      type="text"
      placeholder="token | n: g: r: b: d:"
      value={textFilter}
      onChange={(e) => setTextFilter(e.target.value)}
    />
  );
});

/**
 *
 */
type SidebarBalanceSectionProps = {
  balance: AppState['balance']
  secondaryBalance: AppState['secondaryBalance']
  wethPriceUSD: number
  nativePriceUSD: AppState['brainState']['nativePriceUSD']
  walletAddress: string
}
const SidebarBalanceSection = memo(function SidebarBalanceSection({
                                                                    balance,
                                                                    secondaryBalance,
                                                                    wethPriceUSD,
                                                                    walletAddress,
                                                                    nativePriceUSD,
                                                                  }: SidebarBalanceSectionProps) {
  const networkSortOrder: NETWORK[] = [
    NETWORK.SOLANA,
    NETWORK.ETHEREUM,
    NETWORK.BINANCE,
    NETWORK.POLYGON,
    NETWORK.BASE,
    NETWORK.ARBITRUM,
    NETWORK.SNOWTRACE,
    NETWORK.OPTIMISM,
    NETWORK.GNOSIS,
    NETWORK.FANTOM,
    NETWORK.SONIC,
    NETWORK.METIS,
  ];

  const networkBalanceThresholds: { [network in NETWORK]?: number } = {
    [NETWORK.ARBITRUM]: 0.2,
    [NETWORK.BASE]: 0.2,
    [NETWORK.BINANCE]: 1,
    [NETWORK.ETHEREUM]: 0.5,
    [NETWORK.POLYGON]: 1000,
    [NETWORK.SOLANA]: 1,
    [NETWORK.SNOWTRACE]: 12,
    [NETWORK.OPTIMISM]: 0.1,
    [NETWORK.GNOSIS]: 300,
    [NETWORK.FANTOM]: 450,
    [NETWORK.SONIC]: 100,
    [NETWORK.METIS]: 2,
  };

  // balance = {
  //   Ethereum: {
  //     "WETH": "14"
  //   }
  // }
  // secondaryBalance = {
  //   '0x92AD0A1d7BD05c932936d0f1f38414fC9a565C0c': {
  //     'Ethereum': {
  //       "WETH": "5"
  //     }
  //   }
  // }

  let totalBalance = BigNumber(0);
  let totalEthBalance = BigNumber(0);

  for (const network of Object.keys(balance)) {
    const nativeBalance = getNativeInETH(network as NETWORK, wethPriceUSD, nativePriceUSD, balance);
    const wethBalance = BigNumber(balance[network as NETWORK]?.['WETH'] ?? '0');

    totalBalance = totalBalance.plus(nativeBalance);
    totalBalance = totalBalance.plus(wethBalance);
    if (network === NETWORK.FANTOM) {
      const usdcValue = totalBalance.plus(balance[network as NETWORK]?.['AXLUSDC'] ?? '0');
      totalBalance = totalBalance.plus(usdcValue.div(BigNumber(wethPriceUSD)));
    }

    totalEthBalance = totalEthBalance.plus(wethBalance);
    if (NETWORK_NATIVE_TOKEN[network as NETWORK] === NativeTokens.ETH) {
      totalEthBalance = totalEthBalance.plus(nativeBalance);
    }
  }

  for (const balances of Object.values(secondaryBalance)) {
    for (const network of Object.keys(balances)) {
      const nativeBalance = getNativeInETH(network as NETWORK, wethPriceUSD, nativePriceUSD, balances);
      const wethBalance = BigNumber(balances[network as NETWORK]?.['WETH'] ?? '0');

      totalBalance = totalBalance.plus(nativeBalance);
      totalBalance = totalBalance.plus(wethBalance);
      if (network === NETWORK.FANTOM) {
        const usdcValue = totalBalance.plus(balances[network as NETWORK]?.['AXLUSDC'] ?? '0');
        totalBalance = totalBalance.plus(usdcValue.div(BigNumber(wethPriceUSD)));
      }

      totalEthBalance = totalEthBalance.plus(wethBalance);
      if (NETWORK_NATIVE_TOKEN[network as NETWORK] === NativeTokens.ETH) {
        totalEthBalance = totalEthBalance.plus(nativeBalance);
      }
    }
  }

  const [selectedWallet, setSelectedWallet] = useState<string>('main');
  const wallets = Object.keys(secondaryBalance);

  let selectedBalance = balance;
  if (selectedWallet !== 'main') {
    selectedBalance = secondaryBalance[selectedWallet];
  }

  const TotalBalance = useMemo(
    () => (
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginRight: '16px',
          }}
        >
          <span>{totalEthBalance.toFixed(3)}</span>
          <span
            style={{
              fontWeight: 400,
              fontSize: '0.6em',
              marginTop: '-5px',
              color: 'blue',
            }}
          >
						eth
					</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span>{totalBalance.toFixed(3)}</span>
          <span
            style={{ fontWeight: 400, fontSize: '0.6em', marginTop: '-5px' }}>total</span>
        </div>
      </div>
    ),
    [totalEthBalance, totalBalance],
  );

  return (
    <SidebarSection name="Balance" gap={0} right={TotalBalance}>
      <div style={{ display: 'flex', gap: '0.25em', paddingBottom: '4px' }}>
        <Button
          size="sm"
          variant={selectedWallet === 'main' ? 'primary' : 'outline-primary'}
          onClick={() => {
            setSelectedWallet('main');
          }}
        >
          M
        </Button>
        {wallets.map((w) => (
          <Button
            size="sm"
            variant={selectedWallet === w ? 'primary' : 'outline-primary'}
            onClick={() => {
              setSelectedWallet(w);
            }}
          >
            {w.slice(-3).toLowerCase()}
          </Button>
        ))}
      </div>
      {Object.entries(selectedBalance)
        .sort(([a], [b]) => {
          const indexA = networkSortOrder.indexOf(a as NETWORK);
          const indexB = networkSortOrder.indexOf(b as NETWORK);
          return indexA - indexB;
        })
        .map(([_networkName, networkBalances]) => {
          const networkName = _networkName as NETWORK;
          const nativeBalance = BigNumber(networkBalances[NETWORK_NATIVE_TOKEN[networkName]] || 0);
          const nativeBalanceStr = nativeBalance.toFixed(3);
          const thresholdReached =
            nativeBalance.comparedTo(0) !== 0 &&
            nativeBalance.comparedTo(networkBalanceThresholds[networkName]!) === -1;
          return (
            <SidebarButtonToggle
              key={networkName}
              text={
                <>
                  <Stack direction="horizontal" gap={1} style={{ width: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignSelf: 'stretch',
                        paddingLeft: 4,
                        paddingRight: 4,
                        alignItems: 'center',
                        borderRadius: 4,
                        backgroundColor: thresholdReached ? '#ff8686' : 'transparent',
                      }}
                    >
                      <img
                        width="14px"
                        src={getNetworkImage(networkName)}
                        alt=""
                        style={{ marginRight: 4 }}
                      />
                      <span>
												<span>{nativeBalanceStr}</span>
												<span style={{ marginLeft: '4px', fontSize: '0.75em' }}>
													{NETWORK_NATIVE_TOKEN[networkName]}
												</span>
											</span>
                    </div>
                    <span style={{ width: '100%', textAlign: 'right' }}>
											<span>
												{BigNumber(
                          networkBalances[NETWORK_SOURCE_TOKEN[networkName][0]] || 0,
                        ).toFixed(3)}
											</span>
											<span style={{ marginLeft: '4px', fontSize: '0.75em' }}>
												{NETWORK_SOURCE_TOKEN[networkName][0]}
											</span>
										</span>
                  </Stack>
                </>
              }
              onClick={() => {
                window.open(
                  `${NETWORK_EXPLORER_ADDRESS[networkName]}/address/${walletAddress}`,
                  '_blank',
                );
              }}
            />
          );
        })}
    </SidebarSection>
  );
});

const getNativeInETH = (
  network: NETWORK,
  wethPriceUSD: number,
  nativePrices: AppState['brainState']['nativePriceUSD'],
  balances: { [n in NETWORK]?: { [p: string]: string } },
) => {
  if (
    network === NETWORK.ARBITRUM ||
    network === NETWORK.ETHEREUM ||
    network === NETWORK.BASE ||
    network === NETWORK.OPTIMISM
  ) {
    return balances[network as NETWORK]?.['ETH'] ?? '0';
  }

  const nativeToken = network === NETWORK.POLYGON ? NativeTokens.POL : NETWORK_NATIVE_TOKEN[network];

  const balance = balances[network as NETWORK]?.[nativeToken] ?? '0';
  const nativeUSD = nativePrices[nativeToken as NativeTokens];
  const res = BigNumber(balance).multipliedBy(BigNumber(nativeUSD)).div(wethPriceUSD).toFixed(4);
  return res;
};

const VolumeSlider = memo(function VolumeSlider() {
  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    const initValue = parseInt(localStorage.getItem('volumeSlider') || '100');
    setValue(initValue);
  }, []);

  useEffect(() => {
    if (value === null) return;
    localStorage.setItem('volumeSlider', value.toString());
  }, [value]);

  if (value === null) return <></>;

  return (
    <div style={{ display: 'flex', width: '100%', gap: '8px' }}>
      <span>Volume:</span>
      <input
        style={{ width: '100%' }}
        type="range"
        value={value}
        min={0}
        max={100}
        step={1}
        onChange={(e) => setValue(parseInt(e.target.value))}
      ></input>
      <Button
        size="sm"
        variant="outline"
        onClick={() => {
          Sound.play('dindilin');
        }}
      >
        test
      </Button>
    </div>
  );
});

/**
 *
 */
type SidebarDisplaySettingsSectionProps = {
  filterData: DashboardFilterData
  setFilterData: React.Dispatch<React.SetStateAction<DashboardFilterData>>
}
const SidebarDisplaySettingsSection = memo(function SidebarDisplaySettingsSection({
                                                                                    filterData,
                                                                                    setFilterData,
                                                                                  }: SidebarDisplaySettingsSectionProps) {
  return (
    <>
      <SidebarFilterRow text="Display">
        <SidebarFilterButton
          text="🦄"
          active={filterData.displayTokens === 'allall'}
          onClick={() => setFilterData({ ...filterData, displayTokens: 'allall' })}
        />
        <SidebarFilterButton
          text="All"
          active={filterData.displayTokens === 'all'}
          onClick={() => setFilterData({ ...filterData, displayTokens: 'all' })}
        />
        <SidebarFilterButton
          text={eye as any}
          active={filterData.displayTokens === 'unhidden'}
          onClick={() =>
            setFilterData({
              ...filterData,
              displayTokens: 'unhidden',
            })
          }
        />
        <SidebarFilterButton
          text={eyeSlash as any}
          active={filterData.displayTokens === 'hidden'}
          onClick={() =>
            setFilterData({
              ...filterData,
              displayTokens: 'hidden',
            })
          }
        />
      </SidebarFilterRow>
      {/* <SidebarFilterRow text="Dex position">
        <SidebarFilterButton
          text="Left"
          active={filterData.dexPosition === 'left'}
          onClick={() =>
            setFilterData({
              ...filterData,
              dexPosition: filterData.dexPosition === 'left' ? 'none' : 'left',
            })
          }
        />
        <SidebarFilterButton
          text="Bottom"
          active={filterData.dexPosition === 'bottom'}
          onClick={() =>
            setFilterData({
              ...filterData,
              dexPosition: filterData.dexPosition === 'bottom' ? 'none' : 'bottom',
            })
          }
        />
      </SidebarFilterRow> */}
      <SidebarFilterRow text="Sort !automation">
        <SidebarFilterButton
          text="False"
          active={filterData.sortDisableAutomation === 'false'}
          onClick={() =>
            setFilterData({
              ...filterData,
              sortDisableAutomation: 'false',
            })
          }
        />
        <SidebarFilterButton
          text="True"
          active={filterData.sortDisableAutomation === 'true'}
          onClick={() =>
            setFilterData({
              ...filterData,
              sortDisableAutomation: 'true',
            })
          }
        />
      </SidebarFilterRow>
      {/* <SidebarFilterRow text="Routes">
        <SidebarFilterButton
          text="True"
          active={filterData.displayRoutes === 'true'}
          onClick={() => setFilterData({ ...filterData, displayRoutes: 'true' })}
        />
        <SidebarFilterButton
          text="False"
          active={filterData.displayRoutes === 'false'}
          onClick={() => setFilterData({ ...filterData, displayRoutes: 'false' })}
        />
        <SidebarFilterButton
          text="BN"
          active={filterData.displayRoutes === 'bn'}
          onClick={() => setFilterData({ ...filterData, displayRoutes: 'bn' })}
        />
      </SidebarFilterRow> */}
      <VolumeSlider />
    </>
  );
});

type MinCoefRule = {
  gasPrice: number
  minCoeff: number
}

const getMinProfit = (ethGasPrice?: number, minCoeffRules?: DBAutomationMinCoeffRules): number => {
  if (!ethGasPrice || ethGasPrice < 0 || !minCoeffRules) {
    return -1;
  }

  let rules: MinCoefRule[] = Object.keys(minCoeffRules).map((key) => {
    return {
      gasPrice: Number(key),
      minCoeff: minCoeffRules[Number(key)]!.minCoeff,
    };
  });
  rules.sort((a, b) => a.gasPrice - b.gasPrice);

  for (const { gasPrice, minCoeff } of rules || []) {
    if (ethGasPrice <= gasPrice) return minCoeff;
  }

  return -1;
};

const normalizeValueNum = (
  amount: number,
  maxDecimals: number = 4,
  minDecimals: number = 3,
  cmp: number = 0.01,
) => {
  return +normalizeValue(amount, maxDecimals, minDecimals, cmp);
};

const normalizeValue = (
  amount: number,
  maxDecimals: number = 6,
  minDecimals: number = 3,
  cmp: number = 0.01,
) => {
  if (amount === 0) return 0;
  return amount.toFixed(amount > cmp ? minDecimals : maxDecimals);
};

const ellipsis = (text: string, maxLength: number = 16) => {
  if ((text?.length ?? 0) > maxLength) {
    const start = text.substring(0, Math.ceil(maxLength / 2));
    const end = text.substring(text.length - Math.floor(maxLength / 2));
    return `${start}...${end}`;
  }
  return text;
};
