import { NETWORK } from '../common';

/**
 *
 */
export function getDexNameAndVersion(dexNameWithV: string): [string, string] {
  const dexSepIdx = dexNameWithV.lastIndexOf('_');
  if (dexSepIdx === -1) return ['', ''];

  const dexName = dexNameWithV.slice(0, dexSepIdx);
  const dexVersion = dexNameWithV.slice(dexSepIdx).replace('_V', '');
  return [dexName, dexVersion];
}

export const NetworkToTicker: { [key in NETWORK]?: string } = {
  [NETWORK.ETHEREUM]: 'ETH',
  [NETWORK.BINANCE]: 'BNB',
  [NETWORK.POLYGON]: 'MATIC',
  [NETWORK.ARBITRUM]: 'ETH',
  [NETWORK.BASE]: 'ETH',
  [NETWORK.GNOSIS]: 'xDAI',
  [NETWORK.SNOWTRACE]: 'AVAX',
  [NETWORK.OPTIMISM]: 'ETH',
  [NETWORK.SONIC]: 'S',
  [NETWORK.METIS]: 'METIS',
};
