/**
 *
 */

import { io, Socket } from 'socket.io-client';

import InnerEmitter from '../common/InnerEmitter';

import {
	GLOBAL_EVENTS,
	MODULE_EVENTS,
	MODULE_EVENTS_EXCLUDE_SECURE,
	MODULE_EVENTS_PARAMS,
} from '../common/moduleEvents';
import { SOCKET_PATH } from '../common/defaults';

import Logger from './Logger';

export type INTERFACE_SOCKET_CLIENT_RSA_KEYS = {
  keySize: number
  private: CryptoKey
  public: CryptoKey
  time: number
}

export function base64encode(input: ArrayBuffer) {
  return window.btoa(String.fromCharCode(...new Uint8Array(input)));
}

export function base64decode(input: string) {
  return Uint8Array.from(window.atob(input), (c) => c.charCodeAt(0));
}

/**
 *
 */
async function encryptSecuredEvent<T extends MODULE_EVENTS_EXCLUDE_SECURE>(
  publicKey: CryptoKey,
  moduleEvent: T,
  moduleEventData: MODULE_EVENTS_PARAMS[T],
) {
  const moduleEventDataAsString = JSON.stringify(moduleEventData);

  const iv = crypto.getRandomValues(new Uint8Array(16));
  const key = crypto.getRandomValues(new Uint8Array(32));

  const secret = await crypto.subtle.importKey('raw', key, {
    name: 'AES-CBC',
    length: 256,
  }, false, [
    'encrypt',
    'decrypt',
  ]);
  const encryptedText = await crypto.subtle.encrypt(
    { name: 'AES-CBC', iv: iv },
    secret,
    new TextEncoder().encode(moduleEventDataAsString),
  );

  const encryptedKey = await crypto.subtle.encrypt({ name: 'RSA-OAEP' }, publicKey, key);

  const securedEventData: MODULE_EVENTS_PARAMS[GLOBAL_EVENTS.SECURED_EVENT] = {
    eventName: moduleEvent,
    iv: base64encode(iv),
    key: base64encode(encryptedKey),
    securedData: base64encode(encryptedText),
  };
  return securedEventData;
}

/**
 *
 */
async function decryptSecuredEvent(
  privateKey: CryptoKey,
  securedEventData: MODULE_EVENTS_PARAMS[GLOBAL_EVENTS.SECURED_EVENT],
) {
  const decryptedKey = await crypto.subtle.decrypt(
    { name: 'RSA-OAEP' },
    privateKey,
    base64decode(securedEventData.key),
  );
  const decryptor = await crypto.subtle.importKey(
    'raw',
    decryptedKey,
    { name: 'AES-CBC', length: 256 },
    false,
    ['encrypt', 'decrypt'],
  );
  const decrypted = await crypto.subtle.decrypt(
    { name: 'AES-CBC', iv: base64decode(securedEventData.iv) },
    decryptor,
    base64decode(securedEventData.securedData),
  );
  const decText = new TextDecoder().decode(decrypted);

  return JSON.parse(decText);
}

export async function importSPKIPublicKeyPem(publicKey: string) {
  const publicKeyBase64SingleLineWithoutHeaders = publicKey
    .split('\n')
    .filter((line) => !line.startsWith('-----'))
    .join('');
  return await crypto.subtle.importKey(
    'spki',
    base64decode(publicKeyBase64SingleLineWithoutHeaders),
    { name: 'RSA-OAEP', hash: { name: 'SHA-1' } },
    true,
    ['encrypt'],
  );
}

export async function importPKCS8PrivateKeyPem(privateKey: string) {
  const privateKeyBase64SingleLineWithoutHeaders = privateKey
    .split('\n')
    .filter((line) => !line.startsWith('-----'))
    .join('');
  return await crypto.subtle.importKey(
    'pkcs8',
    base64decode(privateKeyBase64SingleLineWithoutHeaders),
    { name: 'RSA-OAEP', hash: { name: 'SHA-1' } },
    true,
    ['decrypt'],
  );
}

export async function exportSPKIPublicKeyPem(publicKey: CryptoKey) {
  const publicPem = await crypto.subtle.exportKey('spki', publicKey);
  return (
    `-----BEGIN PUBLIC KEY-----\n` +
    base64encode(publicPem)
      .match(/.{1,64}/g)!
      .join('\n') +
    `\n-----END PUBLIC KEY-----`
  );
}

export async function exportPKCS8PrivateKeyPem(privateKey: CryptoKey) {
  let privatePem = await crypto.subtle.exportKey('pkcs8', privateKey);
  return (
    `-----BEGIN PRIVATE KEY-----\n` +
    base64encode(privatePem)
      .match(/.{1,64}/g)!
      .join('\n') +
    `\n-----END PRIVATE KEY-----`
  );
}

type SocketClientInitData = {
  baseUrl: string
  socketPath: SOCKET_PATH
  keyPair: INTERFACE_SOCKET_CLIENT_RSA_KEYS
}

class SocketClient {
  // private _rsaKeySize = 1280
  private _rsaKeySize = 2048;

  private _baseUrl!: string;
  private _socketPath!: SOCKET_PATH;
  private _url!: string;

  private _socket?: Socket;
  private _clientKeys!: INTERFACE_SOCKET_CLIENT_RSA_KEYS;
  private _serverPublicKey?: CryptoKey;

  private l = Logger.bindModule('SocketClient');

  private _initDone = false;

  public async generateKeyPairSync(): Promise<INTERFACE_SOCKET_CLIENT_RSA_KEYS> {
    const timeStarted = Date.now();
    const { publicKey, privateKey } = await crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: this._rsaKeySize,
        publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
        hash: { name: 'SHA-1' },
      },
      true,
      ['encrypt', 'decrypt'],
    );
    return {
      public: publicKey,
      private: privateKey,
      keySize: this._rsaKeySize,
      time: Date.now() - timeStarted,
    };
  }

  /**
   *
   */
  public init(initData: SocketClientInitData) {
    this._baseUrl = initData.baseUrl;
    this._socketPath = initData.socketPath;
    this._clientKeys = initData.keyPair;

    this._url = this._baseUrl + '/' + this._socketPath;

    this._initDone = true;
  }

  public get initDone() {
    return this._initDone;
  }

  /**
   *
   */
  public async connect(
    subscriptions: MODULE_EVENTS_PARAMS[GLOBAL_EVENTS.SUBSCRIBE] = [],
    authKey = '',
    onDisconnect?: () => void,
  ): Promise<void> {
    console.log('CONNECT');
    return new Promise((resolve, reject) => {
      /**
       * Create socket instance
       */
      this._socket = io(this._url, {
        autoConnect: false,
        query: {
          subscribe: JSON.stringify(subscriptions),
        },
      });

      /**
       * On connect
       */
      this._socket.on('connect', () => {
        this.l.log('🟡 connected to', this._url, ', sending client key...');

        /**
         * Send client public key and auth data
         */
        setTimeout(async () => {
          this.emitEvent(MODULE_EVENTS.CLIENT_KEY_EXCHANGE, {
            clientPublicKey: await exportSPKIPublicKeyPem(this._clientKeys.public),
          });
        }, 200);
      });

      /**
       * Listen ONCE to SERVER_KEY_EXCHANGE
       */
      this._socket.once(
        MODULE_EVENTS.SERVER_KEY_EXCHANGE,
        async (data: MODULE_EVENTS_PARAMS[GLOBAL_EVENTS.SERVER_KEY_EXCHANGE]) => {
          this.l.log('🟠 received server key, authenticating...');
          this._serverPublicKey = await importSPKIPublicKeyPem(data.serverPublicKey);

          /**
           * Send SECURED CLIENT_AUTH_REQUEST
           */
          this.emitSecuredEvent(MODULE_EVENTS.CLIENT_AUTH_REQUEST, {
            authKey: authKey,
          });
        },
      );

      /**
       * Listen once for SERVER_AUTH_SUCCESS, SERVER_AUTH_FAILURE
       */
      this._socket.once(
        MODULE_EVENTS.SERVER_AUTH_SUCCESS,
        (data: MODULE_EVENTS_PARAMS[GLOBAL_EVENTS.SERVER_AUTH_SUCCESS]) => {
          this.l.log('🟢 authenticated successfully');
          resolve();
        },
      );
      this._socket.once(
        MODULE_EVENTS.SERVER_AUTH_FAILURE,
        (data: MODULE_EVENTS_PARAMS[GLOBAL_EVENTS.SERVER_AUTH_FAILURE]) => {
          this.l.log('🔴 authentication failed', data.msg);
          reject();
        },
      );

      /**
       * Set immediate subscriptions
       */
      for (const moduleEvent of subscriptions) {
        this._socket.on(moduleEvent, (moduleEventData) => {
          InnerEmitter.emit(moduleEvent, moduleEventData);
        });
      }

      /**
       * Subscribe to SECURED_EVENT
       */
      this._socket.on(
        MODULE_EVENTS.SECURED_EVENT,
        async (data: MODULE_EVENTS_PARAMS[GLOBAL_EVENTS.SECURED_EVENT]) => {
          if (subscriptions.includes(data.eventName)) {
            const eventData = await decryptSecuredEvent(this._clientKeys.private, data);
            InnerEmitter.emit(data.eventName, eventData);
          }
        },
      );

      /**
       * Subscribe to TERMINATE
       */
      this._socket.on(MODULE_EVENTS.TERMINATE, () => {
        this.l.log(`${MODULE_EVENTS.TERMINATE} received`);
        InnerEmitter.emit(MODULE_EVENTS.TERMINATE, undefined);
      });

      /**
       * On disconnect
       */
      this._socket.on('disconnect', () => {
        this.l.log('disconnected from', this._url);
        this._socket?.close();
        onDisconnect?.();
      });

      /**
       * Connect to server
       */
      this.l.log('connecting to', this._url);
      this._socket.connect();
    });
  }

  /**
   *
   */
  public async subscribe(moduleEvents: MODULE_EVENTS[]) {
    for (const moduleEvent of moduleEvents) {
      this._socket?.on(moduleEvent, (moduleEventData) => {
        InnerEmitter.emit(moduleEvent, moduleEventData);
      });
    }
    this._socket?.emit(MODULE_EVENTS.SUBSCRIBE, moduleEvents);
  }

  /**
   * Emit event, no encryption
   */
  public emitEvent<T extends MODULE_EVENTS>(moduleEvent: T, moduleEventData: MODULE_EVENTS_PARAMS[T]) {
    this._socket?.emit(moduleEvent, moduleEventData);
  }

  /**
   * Emit event, RSA encryption with public key
   */
  public async emitSecuredEvent<T extends MODULE_EVENTS_EXCLUDE_SECURE>(
    moduleEvent: T,
    moduleEventData: MODULE_EVENTS_PARAMS[T],
  ) {
    if (!this._serverPublicKey) return this.l.log('Cannot emitSecuredEvent, no _serverPublicKey');

    const securedEventData = await encryptSecuredEvent(
      this._serverPublicKey,
      moduleEvent,
      moduleEventData,
    );
    this._socket?.emit(MODULE_EVENTS.SECURED_EVENT, securedEventData);
  }

  /**
   *
   */
  public async disconnect(): Promise<void> {
    this._socket?.disconnect();
  }
}

export default new SocketClient();
