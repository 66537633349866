import { memo, useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';

import Header_C, {
  HeaderBlockNumSection_C,
  HeaderConnectedServicesSection_C,
  HeaderSection,
  HeaderStartStopSection_C,
} from '../../../components/Header';
import Content, { ContentSection, ContentSpacer } from '../../../components/Content';
import Sidebar, { SidebarRootNavLinks } from '../../../components/Sidebar';
import {
  BRIDGE,
  DBTokens,
  DEX,
  NETWORK,
  NETWORK_NATIVE_AND_WRAPPED_TOKENS,
  TokenShortArrayData,
  TxType,
} from '../../../common';
import {
  sendApproveBridgeTx,
  sendApproveDexTx,
  sendBridgeTx,
  sendDexTx,
  sendTransferTx,
} from '../actions';
import { infoToast } from '../../../helper/Toast';

/**
 * New Transaction page
 *  Route '/tx/new'
 */
type NewTransactionProps = {
  tokens: DBTokens
}
export default memo(function NewTransaction({ tokens }: NewTransactionProps) {
  const navigate = useNavigate();

  const defaultPath = [['', '', 18]] as any;

  // dex tx params
  const [txType, setTxTypeFn] = useState<TxType>(TxType.Transfer);
  const [network, setNetworkFn] = useState<NETWORK>(NETWORK.ETHEREUM);
  const [dexName, setDexName] = useState<DEX>(DEX.Uniswap_V3);
  const [path, setPath] = useState<TokenShortArrayData[]>(defaultPath);
  const [fees, setFees] = useState<string>('');
  const [returnAmountShifted, setReturnAmountShifted] = useState<string>('');
  const [trackingValue, setTrackingValue] = useState<string>('');
  const [isSelling, setIsSelling] = useState<boolean>(false);

  // bridge tx params
  const [bridgeName, setBridgeName] = useState<BRIDGE>(BRIDGE.CHAINPORT);
  const [fromNetwork, setFromNetworkFn] = useState<NETWORK>(NETWORK.ETHEREUM);
  const [networkTo, setToNetwork] = useState<NETWORK>(NETWORK.BINANCE);
  const [tokenName, setTokenName] = useState<string>('');
  const [tokenAddr, setTokenAddr] = useState<string>('');
  const [tokenDecim, setTokenDecim] = useState<number>(18);
  const [amount, setAmount] = useState<string>('');

  const [errorMessage, setErrorMessage] = useState('');

  const setNetwork = ((val: NETWORK) => {
    clearValues();
    setNetworkFn(val);
  }) as any;
  const setFromNetwork = ((val: NETWORK) => {
    clearValues();
    setFromNetworkFn(val);
  }) as any;
  const setTxType = ((val: TxType) => {
    setNetwork(NETWORK.ETHEREUM);
    setFromNetwork(NETWORK.ETHEREUM);
    setToNetwork(NETWORK.BINANCE);
    setTxTypeFn(val);
  }) as any;

  const clearValues = () => {
    setErrorMessage('');
    setPath(defaultPath);
    setFees('');
    setReturnAmountShifted('');
    setTrackingValue('');
    setIsSelling(false);
    setTokenName('');
    setTokenAddr('');
    setAmount('');
    setTokenDecim(18);
  };

  const sendTx = () => {
    setErrorMessage('');

    const feesArr = !!fees
      ? fees
        .trim()
        .replace(' ', '')
        .split(',')
        .map((feeStr) => +feeStr)
      : undefined;

    if (
      !validateForm({
        setErrorMessage,
        txType,
        network,
        dexName,
        path,
        feesArr,
        returnAmountShifted,
        trackingValue,
        fromNetwork: network,
        networkTo: networkTo,
        tokenName,
        tokenAddr,
        bridgeName,
        amount,
      })
    ) {
      return;
    }

    switch (txType) {
      case TxType.Transfer:
        sendTransferTx({ network, amount, tokenName });
        break;
      case TxType.Swap:
        sendDexTx({
          txType: isSelling ? TxType.Sell : TxType.Swap,
          network,
          dexName,
          path,
          fees: feesArr,
          amountOutShifted: returnAmountShifted,
          amountInShifted: trackingValue,
        } as any);
        break;
      case TxType.ApproveSwap:
        sendApproveDexTx({
          txType: TxType.ApproveSwap,
          network, networkTo,
          token: { tokenName, tokenAddr, tokenDecim: 18 },
        } as any);
        break;
      case TxType.Bridge:
        sendBridgeTx({
          network,
          networkTo,
          token: {
            tokenName,
            tokenAddr,
            tokenDecim,
          },
          bridgeName,
          amountOutShifted: amount,
          skipApprovalCheck: true,
        } as any);
        break;
      case TxType.ApproveBridge:
        sendApproveBridgeTx({
          txType: TxType.ApproveBridge,
          network, networkTo,
          token: { tokenName, tokenAddr, tokenDecim: 18 },
        } as any);
        break;
    }

    infoToast('Tx data sent to transactions module for processing');
    navigate('/tx/latest');
  };

  return (
    <>
      <Header_C>
        <HeaderSection>
          <HeaderStartStopSection_C />
        </HeaderSection>
        <HeaderSection justifyContent="center">
          <HeaderConnectedServicesSection_C />
        </HeaderSection>
        <HeaderSection justifyContent="end">
          <HeaderBlockNumSection_C />
        </HeaderSection>
      </Header_C>

      <Container fluid className="main">
        <Row>
          <Col className="sidebarContainer">
            <Sidebar>
              <SidebarRootNavLinks />
            </Sidebar>
          </Col>
          <Col className="contentContainer">
            <Content>
              <ContentSection>
                <h3>Create new transaction</h3>

                <InputGroup style={{ width: 450, marginRight: 24, marginBottom: 32 }}>
                  <InputGroup.Text style={{ width: 130, backgroundColor: '#b9bcbf' }}>
                    Tx Type
                  </InputGroup.Text>
                  <FormSelect
                    val={txType}
                    setFn={setTxType}
                    options={Object.values(TxType)}
                  />
                </InputGroup>

                {(() => {
                  const formSwapTxComponent = (
                    <FormSwapTx
                      network={network}
                      setNetwork={setNetwork}
                      dexName={dexName}
                      setDexName={setDexName}
                      tokens={tokens}
                      path={path}
                      setPath={setPath}
                      fees={fees}
                      setFees={setFees}
                      returnAmountShifted={returnAmountShifted}
                      setReturnAmountShifted={setReturnAmountShifted}
                      trackingValue={trackingValue}
                      setTrackingValue={setTrackingValue}
                      isSelling={isSelling}
                      setIsSelling={setIsSelling}
                    />
                  );

                  switch (txType) {
                    case TxType.Transfer:
                      return <FormTransferTx
                        network={network}
                        setNetwork={setNetwork}
                        tokens={tokens}
                        tokenName={tokenName}
                        setTokenName={setTokenName}
                        tokenAddress={tokenAddr}
                        setTokenAddress={setTokenAddr}
                        tokenDecimals={tokenDecim}
                        setTokenDecimals={setTokenDecim}
                        amount={amount}
                        setAmount={setAmount}
                      />;
                    case TxType.Swap:
                      return formSwapTxComponent;
                    case TxType.Bridge:
                      return (
                        <FormBridgeTx
                          fromNetwork={fromNetwork}
                          setFromNetwork={setFromNetwork}
                          networkTo={networkTo}
                          setToNetwork={setToNetwork}
                          tokens={tokens}
                          tokenName={tokenName}
                          setTokenName={setTokenName}
                          tokenAddress={tokenAddr}
                          setTokenAddress={setTokenAddr}
                          tokenDecimals={tokenDecim}
                          setTokenDecimals={setTokenDecim}
                          bridgeName={bridgeName}
                          setBridgeName={setBridgeName}
                          amount={amount}
                          setAmount={setAmount}
                        />
                      );
                    case TxType.ApproveSwap:
                      return (
                        <FormApproveSwapTx
                          network={network}
                          setNetwork={setNetwork}
                          dexName={dexName}
                          setDexName={setDexName}
                          tokens={tokens}
                          tokenName={tokenName}
                          setTokenName={setTokenName}
                          tokenAddress={tokenAddr}
                          tokenDecimals={tokenDecim}
                          setTokenDecimals={setTokenDecim}
                          setTokenAddress={setTokenAddr}
                        />
                      );
                    case TxType.ApproveBridge:
                      return (
                        <FormApproveBridgeTx
                          network={network}
                          setNetwork={setNetwork}
                          tokens={tokens}
                          tokenName={tokenName}
                          setTokenName={setTokenName}
                          tokenAddress={tokenAddr}
                          setTokenAddress={setTokenAddr}
                          tokenDecimals={tokenDecim}
                          setTokenDecimals={setTokenDecim}
                          bridgeName={bridgeName}
                          setBridgeName={setBridgeName}
                        />
                      );
                    default:
                      return formSwapTxComponent;
                  }
                })()}

                {!!errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                <Button style={{ width: 200 }} variant="success" onClick={sendTx}>
                  Send
                </Button>
              </ContentSection>
              <ContentSection>
                <ContentSpacer height={100} />
              </ContentSection>
            </Content>
          </Col>
        </Row>
      </Container>
    </>
  );
});

type ValidateFormProps = {
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  txType: TxType
  network: NETWORK
  dexName: DEX
  path: TokenShortArrayData[]
  feesArr?: number[]
  returnAmountShifted: string
  trackingValue: string
  fromNetwork: NETWORK
  networkTo: NETWORK
  tokenName: string
  tokenAddr: string
  bridgeName: BRIDGE
  amount: string
}
const validateForm = ({
                        setErrorMessage,
                        txType,
                        network,
                        dexName,
                        path,
                        feesArr,
                        returnAmountShifted,
                        trackingValue,
                        fromNetwork,
                        networkTo,
                        tokenName,
                        tokenAddr,
                        bridgeName,
                        amount,
                      }: ValidateFormProps): boolean => {
  switch (txType) {
    case TxType.Swap:
      if ((network as string) === '') {
        setErrorMessage('Network field is required');
        return false;
      }
      if ((dexName as string) === '') {
        setErrorMessage('Dex field is required');
        return false;
      }
      if (path.length < 2) {
        setErrorMessage('It\'s required to have at least 2 tokens in path');
        return false;
      }
      if (returnAmountShifted === '') {
        setErrorMessage('Return amount field is required');
        return false;
      }
      if (trackingValue === '') {
        setErrorMessage('Tracking value field is required');
        return false;
      }
      for (let i = 0; i < path.length; i++) {
        if (path[i][0] === '') {
          setErrorMessage(`Wrong token name value in path[${i}]`);
          return false;
        }
        if (path[i][1] === '') {
          setErrorMessage(`Wrong token address value in path[${i}]`);
          return false;
        }
        if (path[i][2] === 0) {
          setErrorMessage(`Wrong token decimals value in path[${i}]`);
          return false;
        }
      }
      if (!!feesArr && !!feesArr.length) {
        if (feesArr.length !== path.length - 1) {
          setErrorMessage('Path-fees length does not match');
          return false;
        }
        for (let i = 0; i < feesArr.length; i++) {
          if (feesArr[i] === 0) {
            setErrorMessage(`Wrong fee value at index ${i}: ${feesArr[i]}`);
            return false;
          }
        }
      }
      break;
    case TxType.ApproveSwap:
      if ((network as string) === '') {
        setErrorMessage('Network field is required');
        return false;
      }
      if ((dexName as string) === '') {
        setErrorMessage('Dex field is required');
        return false;
      }
      if (tokenName === '') {
        setErrorMessage('Token name field is required');
        return false;
      }
      if (tokenAddr === '') {
        setErrorMessage('Token address field is required');
        return false;
      }
      break;
    case TxType.Bridge:
      if ((fromNetwork as string) === '') {
        setErrorMessage('Network from field is required');
        return false;
      }
      if ((networkTo as string) === '') {
        setErrorMessage('Network to field is required');
        return false;
      }
      if ((bridgeName as string) === '') {
        setErrorMessage('Bridge field is required');
        return false;
      }
      if (tokenName === '') {
        setErrorMessage('Token name field is required');
        return false;
      }
      if (tokenAddr === '') {
        setErrorMessage('Token address field is required');
        return false;
      }
      if (amount === '') {
        setErrorMessage('Amount field is required');
        return false;
      }
      break;
    case TxType.ApproveBridge:
      if ((network as string) === '') {
        setErrorMessage('Network field is required');
        return false;
      }
      if ((bridgeName as string) === '') {
        setErrorMessage('Bridge field is required');
        return false;
      }
      if (tokenName === '') {
        setErrorMessage('Token name field is required');
        return false;
      }
      if (tokenAddr === '') {
        setErrorMessage('Token address field is required');
        return false;
      }
      break;
  }
  return true;
};

/**
 *
 */
type FormTransferTxProps = {
  network: NETWORK
  setNetwork: React.Dispatch<React.SetStateAction<NETWORK>>
  tokens: DBTokens
  tokenName: string
  setTokenName: React.Dispatch<React.SetStateAction<string>>
  tokenAddress: string
  setTokenAddress: React.Dispatch<React.SetStateAction<string>>
  tokenDecimals: number
  setTokenDecimals: React.Dispatch<React.SetStateAction<number>>
  amount: string
  setAmount: React.Dispatch<React.SetStateAction<string>>
}
const FormTransferTx = ({
                          network,
                          setNetwork,
                          tokens: dbTokens,
                          tokenAddress,
                          setTokenAddress,
                          tokenDecimals,
                          setTokenDecimals,
                          tokenName,
                          setTokenName,
                          amount,
                          setAmount,
                        }: FormTransferTxProps) => {
  const tokens = getTokensForNetwork(network, dbTokens);

  return (
    <div>
      <InputGroup style={{ width: 450, marginBottom: 18 }}>
        <InputGroup.Text style={{ width: 130 }}>Network</InputGroup.Text>
        <FormSelect val={network} setFn={setNetwork} options={Object.values(NETWORK)} />
        <NetworkSelectorWarning />
      </InputGroup>
      <FormLabel style={{ marginBottom: 8 }} text="Token" />
      <FormTokensSelector
        style={{ marginBottom: 28 }}
        network={network}
        tokens={tokens}
        tokenName={tokenName}
        tokenAddress={tokenAddress}
        tokenDecimals={tokenDecimals}
        setTokenName={setTokenName}
        setTokenAddress={setTokenAddress}
        setTokenDecimals={setTokenDecimals}
      />
      <InputGroup style={{ width: 450, marginBottom: 24 }}>
        <InputGroup.Text style={{ width: 130 }}>Amount</InputGroup.Text>
        <Form.Control value={amount} onChange={(e) => setAmount(e.target.value)} />
      </InputGroup>
    </div>
  );
};

/**
 *
 */
type FormSwapTxProps = {
  network: NETWORK
  setNetwork: React.Dispatch<React.SetStateAction<NETWORK>>
  dexName: DEX
  setDexName: React.Dispatch<React.SetStateAction<DEX>>
  tokens: DBTokens
  path: TokenShortArrayData[]
  setPath: React.Dispatch<React.SetStateAction<TokenShortArrayData[]>>
  fees: string
  setFees: React.Dispatch<React.SetStateAction<string>>
  returnAmountShifted: string
  setReturnAmountShifted: React.Dispatch<React.SetStateAction<string>>
  trackingValue: string
  setTrackingValue: React.Dispatch<React.SetStateAction<string>>
  isSelling: boolean
  setIsSelling: React.Dispatch<React.SetStateAction<boolean>>
}
const FormSwapTx = ({
                      network,
                      setNetwork,
                      dexName,
                      setDexName,
                      tokens: dbTokens,
                      path,
                      setPath,
                      fees,
                      setFees,
                      returnAmountShifted,
                      setReturnAmountShifted,
                      trackingValue,
                      setTrackingValue,
                      isSelling,
                      setIsSelling,
                    }: FormSwapTxProps) => {
  const tokens = getTokensForNetwork(network, dbTokens);

  return (
    <>
      <InputGroup style={{ width: 450, marginBottom: 18 }}>
        <InputGroup.Text style={{ width: 130 }}>Network</InputGroup.Text>
        <FormSelect val={network} setFn={setNetwork} options={Object.values(NETWORK)} />
        <NetworkSelectorWarning />
      </InputGroup>
      <InputGroup style={{ width: 450, marginBottom: 18 }}>
        <InputGroup.Text style={{ width: 130 }}>Dex</InputGroup.Text>
        <FormSelect val={dexName} setFn={setDexName} options={Object.values(DEX)} />
      </InputGroup>
      <FormLabel text="Path" />
      <FormPath
        style={{ marginBottom: 24 }}
        fees={fees.trim().split(',')}
        network={network}
        tokens={tokens}
        path={path}
        setPath={setPath}
      />
      <InputGroup style={{ width: 450, marginBottom: 18 }}>
        <InputGroup.Text style={{ width: 130 }}>Fees</InputGroup.Text>
        <Form.Control
          style={{
            borderTopRightRadius: '0.375rem',
            borderBottomRightRadius: '0.375rem',
          }}
          value={fees}
          onChange={(e) => setFees(e.target.value)}
        />
        <Form.Text style={{ width: '100%' }}>Enter comma separated numeric
          values</Form.Text>
      </InputGroup>
      <InputGroup style={{ width: 450, marginBottom: 18 }}>
        <InputGroup.Text style={{ width: 130 }}>Tracking value</InputGroup.Text>
        <Form.Control
          style={{
            borderTopRightRadius: '0.375rem',
            borderBottomRightRadius: '0.375rem',
          }}
          value={trackingValue}
          onChange={(e) => setTrackingValue(e.target.value)}
        />
        <Form.Text style={{ width: '100%' }}>Should be value in ether (ETH
          amount)</Form.Text>
      </InputGroup>
      <InputGroup style={{ width: 450, marginBottom: 18 }}>
        <InputGroup.Text style={{ width: 130 }}>Return amount</InputGroup.Text>
        <Form.Control
          style={{
            borderTopRightRadius: '0.375rem',
            borderBottomRightRadius: '0.375rem',
          }}
          value={returnAmountShifted}
          onChange={(e) => setReturnAmountShifted(e.target.value)}
        />
        <Form.Text>Should be amount of token we want to swap (token amount)</Form.Text>
      </InputGroup>
      <div style={{ display: 'flex', marginBottom: 24 }}>
        <FormLabel style={{ width: 120, marginRight: 0 }} text="Selling tokens" />
        <FormToggle val={isSelling} setFn={setIsSelling} />
      </div>
    </>
  );
};

/**
 *
 */
type FormApproveSwapTxProps = {
  network: NETWORK
  setNetwork: React.Dispatch<React.SetStateAction<NETWORK>>
  dexName: DEX
  setDexName: React.Dispatch<React.SetStateAction<DEX>>
  tokens: DBTokens
  tokenName: string
  setTokenName: React.Dispatch<React.SetStateAction<string>>
  tokenAddress: string
  setTokenAddress: React.Dispatch<React.SetStateAction<string>>
  tokenDecimals: number
  setTokenDecimals: React.Dispatch<React.SetStateAction<number>>
}
const FormApproveSwapTx = ({
                             network,
                             setNetwork,
                             dexName,
                             setDexName,
                             tokens: dbTokens,
                             tokenName,
                             setTokenName,
                             tokenAddress,
                             setTokenAddress,
                             tokenDecimals,
                             setTokenDecimals,
                           }: FormApproveSwapTxProps) => {
  const tokens = getTokensForNetwork(network, dbTokens);

  return (
    <div>
      <InputGroup style={{ width: 450, marginBottom: 18 }}>
        <InputGroup.Text style={{ width: 130 }}>Network</InputGroup.Text>
        <FormSelect val={network} setFn={setNetwork} options={Object.values(NETWORK)} />
        <NetworkSelectorWarning />
      </InputGroup>
      <FormLabel style={{ marginBottom: 8 }} text="Token" />
      <FormTokensSelector
        style={{ marginBottom: 28 }}
        network={network}
        tokens={tokens}
        tokenName={tokenName}
        tokenAddress={tokenAddress}
        tokenDecimals={tokenDecimals}
        setTokenName={setTokenName}
        setTokenAddress={setTokenAddress}
        setTokenDecimals={setTokenDecimals}
      />
      <InputGroup style={{ width: 450, marginBottom: 24 }}>
        <InputGroup.Text style={{ width: 130 }}>Dex</InputGroup.Text>
        <FormSelect val={dexName} setFn={setDexName} options={Object.values(DEX)} />
      </InputGroup>
    </div>
  );
};

/**
 *
 */
type FormBridgeTxProps = {
  fromNetwork: NETWORK
  setFromNetwork: React.Dispatch<React.SetStateAction<NETWORK>>
  networkTo: NETWORK
  setToNetwork: React.Dispatch<React.SetStateAction<NETWORK>>
  tokens: DBTokens
  tokenName: string
  setTokenName: React.Dispatch<React.SetStateAction<string>>
  tokenAddress: string
  setTokenAddress: React.Dispatch<React.SetStateAction<string>>
  tokenDecimals: number
  setTokenDecimals: React.Dispatch<React.SetStateAction<number>>
  bridgeName: BRIDGE
  setBridgeName: React.Dispatch<React.SetStateAction<BRIDGE>>
  amount: string
  setAmount: React.Dispatch<React.SetStateAction<string>>
}
const FormBridgeTx = ({
                        fromNetwork,
                        setFromNetwork,
                        networkTo,
                        setToNetwork,
                        tokens: dbTokens,
                        tokenName,
                        setTokenName,
                        tokenAddress,
                        setTokenAddress,
                        tokenDecimals,
                        setTokenDecimals,
                        bridgeName,
                        setBridgeName,
                        amount,
                        setAmount,
                      }: FormBridgeTxProps) => {
  const tokens = getTokensForNetwork(fromNetwork, dbTokens);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'left', marginBottom: 18 }}>
        <InputGroup style={{ width: 450, marginRight: 24 }}>
          <InputGroup.Text style={{ width: 130 }}>Network From</InputGroup.Text>
          <FormSelect
            style={{
              borderTopRightRadius: '0.375rem',
              borderBottomRightRadius: '0.375rem',
            }}
            val={fromNetwork}
            setFn={setFromNetwork}
            options={Object.values(NETWORK)}
          />
          <NetworkSelectorWarning />
        </InputGroup>
        <InputGroup style={{ width: 450, height: 38 }}>
          <InputGroup.Text style={{ width: 130 }}>Network To</InputGroup.Text>
          <FormSelect val={networkTo} setFn={setToNetwork}
                      options={Object.values(NETWORK)} />
        </InputGroup>
      </div>
      <FormLabel style={{ marginBottom: 8 }} text="Token" />
      <FormTokensSelector
        style={{ marginBottom: 28 }}
        network={fromNetwork}
        tokens={tokens}
        tokenName={tokenName}
        tokenAddress={tokenAddress}
        tokenDecimals={tokenDecimals}
        setTokenName={setTokenName}
        setTokenAddress={setTokenAddress}
        setTokenDecimals={setTokenDecimals}
      />
      <InputGroup style={{ width: 450, marginBottom: 18 }}>
        <InputGroup.Text style={{ width: 130 }}>Bridge</InputGroup.Text>
        <FormSelect val={bridgeName} setFn={setBridgeName}
                    options={Object.values(BRIDGE)} />
      </InputGroup>
      <InputGroup style={{ width: 450, marginBottom: 24 }}>
        <InputGroup.Text style={{ width: 130 }}>Amount</InputGroup.Text>
        <Form.Control value={amount} onChange={(e) => setAmount(e.target.value)} />
      </InputGroup>
    </div>
  );
};

/**
 *
 */
type FormApproveBridgeTxProps = {
  network: NETWORK
  setNetwork: React.Dispatch<React.SetStateAction<NETWORK>>
  tokens: DBTokens
  tokenName: string
  setTokenName: React.Dispatch<React.SetStateAction<string>>
  tokenAddress: string
  setTokenAddress: React.Dispatch<React.SetStateAction<string>>
  tokenDecimals: number
  setTokenDecimals: React.Dispatch<React.SetStateAction<number>>
  bridgeName: BRIDGE
  setBridgeName: React.Dispatch<React.SetStateAction<BRIDGE>>
}
const FormApproveBridgeTx = ({
                               network,
                               setNetwork,
                               tokens: dbTokens,
                               tokenName,
                               setTokenName,
                               tokenAddress,
                               setTokenAddress,
                               tokenDecimals,
                               setTokenDecimals,
                               bridgeName,
                               setBridgeName,
                             }: FormApproveBridgeTxProps) => {
  const tokens = getTokensForNetwork(network, dbTokens);

  return (
    <div>
      <InputGroup style={{ width: 450, marginBottom: 18 }}>
        <InputGroup.Text style={{ width: 130 }}>Network</InputGroup.Text>
        <FormSelect val={network} setFn={setNetwork} options={Object.values(NETWORK)} />
        <NetworkSelectorWarning />
      </InputGroup>
      <FormLabel style={{ marginBottom: 8 }} text="Token" />
      <FormTokensSelector
        style={{ marginBottom: 28 }}
        network={network}
        tokens={tokens}
        tokenName={tokenName}
        tokenAddress={tokenAddress}
        tokenDecimals={tokenDecimals}
        setTokenName={setTokenName}
        setTokenAddress={setTokenAddress}
        setTokenDecimals={setTokenDecimals}
      />
      <InputGroup style={{ width: 450, marginBottom: 24 }}>
        <InputGroup.Text style={{ width: 130 }}>Bridge</InputGroup.Text>
        <FormSelect val={bridgeName} setFn={setBridgeName}
                    options={Object.values(BRIDGE)} />
      </InputGroup>
    </div>
  );
};

/**
 *
 */
type FormTokensSelectorProps = {
  style?: any
  network?: NETWORK
  tokens: DBTokens
  tokenName: string
  tokenAddress: string
  tokenDecimals: number
  setTokenName: React.Dispatch<React.SetStateAction<string>>
  setTokenAddress: React.Dispatch<React.SetStateAction<string>>
  setTokenDecimals: React.Dispatch<React.SetStateAction<number>>
}
const FormTokensSelector = ({
                              style,
                              network,
                              tokens,
                              tokenName,
                              tokenAddress,
                              tokenDecimals,
                              setTokenName,
                              setTokenAddress,
                              setTokenDecimals,
                            }: FormTokensSelectorProps) => {
  const [custom, setCustom] = useState<boolean>(false);
  const options = Object.keys(tokens);
  const selected = tokenName ? [tokenName] : [];

  return (
    <div style={{ ...style }}>
      <div>
        <Form.Text style={{ marginTop: 0 }} muted>
          Select token from database or enter custom token data
        </Form.Text>
        <FormToggle
          style={{ marginBottom: 4 }}
          labelStyle={{ fontWeight: 400 }}
          label="Custom token data"
          val={custom}
          setFn={(val: boolean) => {
            setTokenName('');
            setTokenAddress('');
            setTokenDecimals(18);
            setCustom(val);
          }}
        />
      </div>

      <Form.Group>
        {custom ? (
          <InputGroup style={{ display: 'flex', justifyContent: 'left' }}>
            <FormInput
              style={{
                marginBottom: 8,
                width: 200,
                marginRight: 0,
              }}
              inputStyle={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              val={tokenName}
              setFn={setTokenName}
              helpText="Token name"
            />
            <FormInput
              style={{ marginBottom: 8, width: 500, marginRight: 0 }}
              inputStyle={{ borderRadius: 0 }}
              val={tokenAddress}
              setFn={setTokenAddress}
              helpText="Token address"
            />
            <FormInput
              style={{ marginBottom: 8, width: 100 }}
              inputStyle={{
                borderRadius: 0,
                borderTopRightRadius: '0.375rem',
                borderBottomRightRadius: '0.375rem',
              }}
              val={`${tokenDecimals}`}
              setFn={(text: string) => {
                const reg = /^\d+$/;
                if (reg.test(text)) {
                  setTokenDecimals(+text);
                }
              }}
              helpText="Token decimals"
            />
          </InputGroup>
        ) : (
          <InputGroup>
            <Typeahead
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              disabled={!options.length || !network}
              id="form-example"
              labelKey="capital"
              onChange={(opt) => {
                if (
                  !tokens[opt.toString()] ||
                  !tokens[opt.toString()].networkData ||
                  !tokens[opt.toString()].networkData![network as NETWORK]!
                ) {
                  setTokenName('');
                  setTokenAddress('');
                  setTokenDecimals(18);
                  return;
                }
                const tokenData = tokens[opt.toString()].networkData![network as NETWORK]!;
                setTokenName(opt.toString());
                setTokenAddress(tokenData.address);
                setTokenDecimals(tokenData.decimals);
              }}
              options={options}
              placeholder="Select a token..."
              selected={selected}
            />
            {!!tokenAddress && (
              <InputGroup.Text style={{ width: 450 }}>{tokenAddress}</InputGroup.Text>
            )}
            {!!tokenAddress && !!tokenDecimals && (
              <InputGroup.Text style={{ width: 50 }}>{tokenDecimals}</InputGroup.Text>
            )}
          </InputGroup>
        )}
      </Form.Group>
    </div>
  );
};

/**
 *
 */
type FormPathProps = {
  style?: any
  fees?: string[]
  network: NETWORK
  tokens: DBTokens
  path: TokenShortArrayData[]
  setPath: React.Dispatch<React.SetStateAction<TokenShortArrayData[]>>
}
const FormPath = ({ style, fees, network, tokens, path, setPath }: FormPathProps) => {
  const addNewElementToPath = () => setPath([...path, ['', '', 18]]);

  const deleteElementFromPath = (idx: number) => {
    path.splice(idx, 1);
    setPath([...path]);
  };

  const updatePath = (idx: number, el: number, val: string) => {
    const update = [...path];
    update[idx][el] = val;
    setPath(update);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <InputGroup
        style={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: 750,
          maxWidth: 750, ...style,
        }}
      >
        {path.map((item, idx) => (
          <div style={{ display: 'flex', minWidth: 700 }}>
            <FormTokensSelector
              key={idx}
              style={{ marginBottom: 8 }}
              network={network}
              tokens={tokens}
              tokenName={item[0]}
              tokenAddress={item[1]}
              tokenDecimals={item[2]}
              setTokenName={((val: string) => updatePath(idx, 0, val)) as any}
              setTokenAddress={((val: string) => updatePath(idx, 1, val)) as any}
              setTokenDecimals={((val: string) => updatePath(idx, 2, val)) as any}
            />
            {idx !== 0 && (
              <Button
                style={{
                  width: 32,
                  height: 32,
                  margin: 'auto',
                  marginLeft: 12,
                  marginTop: 62,
                  borderRadius: '0.375rem',
                  padding: 0,
                }}
                variant="danger"
                onClick={() => deleteElementFromPath(idx)}
              >
                <IconCross size={24} />
              </Button>
            )}
          </div>
        ))}
        <Button
          style={{ width: 200, borderRadius: '0.375rem' }}
          variant="primary"
          onClick={addNewElementToPath}
        >
          Add Token to Path
        </Button>
      </InputGroup>
      <InputGroup
        style={{ display: 'flex', alignItems: 'baseline', paddingLeft: '3%', ...style }}>
        {path.map((item, idx) => (
          <>
            <div key={`${idx}_main`} style={{ display: 'flex', flexDirection: 'column' }}>
              <Badge
                bg={
                  idx === 0
                    ? 'info'
                    : idx !== 0 && idx === path.length - 1
                      ? 'success'
                      : 'secondary'
                }
              >
                {item[0]}
              </Badge>
            </div>
            {path.length > idx + 1 && !!item[0] && path[idx + 1][0] && (
              <div
                key={`${idx}_fees`}
                style={{ display: 'flex', flexDirection: 'column', padding: 12 }}
              >
                <div style={{ margin: 0, textAlign: 'center' }}>
                  <PathArrowIcon />
                </div>
                {!!fees && !!fees[idx] && (
                  <p
                    style={{
                      margin: 0,
                      textAlign: 'center',
                      fontSize: '0.8rem',
                      color: '#aaa',
                    }}
                  >
                    {fees[idx]}
                  </p>
                )}
              </div>
            )}
          </>
        ))}
      </InputGroup>
    </div>
  );
};

/**
 *
 */
function PathArrowIcon() {
  return (
    <svg fill="none" viewBox="0 0 15 15" height="1em" width="1em">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.5 7.5a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

/**
 *
 */
type FormLabelProps = {
  text: string
  style?: any
}
const FormLabel = ({ style, text }: FormLabelProps) => (
  <Form.Label
    style={{
      width: 'auto',
      marginLeft: 0,
      marginRight: 24,
      fontWeight: 500,
      ...style,
    }}
  >
    {text}
  </Form.Label>
);

/**
 *
 */
type FormSelectProps = {
  style?: any
  val: any
  setFn: any
  options: any[]
}
const FormSelect = ({ val, style, setFn, options }: FormSelectProps) => (
  <Form.Select
    size="sm"
    value={val}
    style={{ textAlign: 'center', ...style }}
    onChange={(e) => setFn(e.target.value)}
  >
    {options.map((value) => (
      <option key={value} value={value}>
        {value}
      </option>
    ))}
  </Form.Select>
);

/**
 *
 */
type FormInputProps = {
  val: any
  setFn: any
  helpText?: string
  style?: any
  inputStyle?: any
}
const FormInput = ({ style, inputStyle, val, setFn, helpText }: FormInputProps) => (
  <InputGroup style={{ display: 'flex', flexDirection: 'column', ...style }}>
    <Form.Control
      value={val}
      style={{ textAlign: 'left', width: '100%', marginBottom: 0, ...inputStyle }}
      onChange={(e) => setFn(e.target.value)}
    />
    {helpText && (
      <Form.Text style={{ marginTop: 0 }} muted>
        {helpText}
      </Form.Text>
    )}
  </InputGroup>
);

/**
 *
 */
type FormToggleProps = {
  val: any
  setFn: any
  label?: string
  style?: any
  labelStyle?: any
}
const FormToggle = ({ style, labelStyle, label, val, setFn }: FormToggleProps) => (
  <InputGroup style={{ ...style }}>
    {!!label && <FormLabel style={{ marginRight: 4, ...labelStyle }} text={label} />}
    <Form.Switch
      value={val}
      style={{ textAlign: 'center', paddingTop: 2 }}
      onChange={(e) => setFn(e.target.checked)}
    />
  </InputGroup>
);

const getTokensForNetwork = (network: NETWORK, tokens: DBTokens): DBTokens => {
  const dbTokens = { ...tokens };
  for (const tokenName in NETWORK_NATIVE_AND_WRAPPED_TOKENS) {
    if (!dbTokens[tokenName]) {
      dbTokens[tokenName] = NETWORK_NATIVE_AND_WRAPPED_TOKENS[tokenName];
    }
  }

  const res: DBTokens = {};
  for (const tokenName in dbTokens) {
    if (
      !!dbTokens[tokenName].networkData &&
      !!dbTokens[tokenName].networkData![network] &&
      !!dbTokens[tokenName].networkData![network]?.address
    ) {
      res[tokenName] = dbTokens[tokenName];
    }
  }

  return res;
};

const IconWarning = () => {
  return (
    <svg viewBox="0 0 1024 1024" fill="#F0D500" height="1.2em" width="1.2em">
      <path
        d="M955.7 856l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zM480 416c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v184c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V416zm32 352a48.01 48.01 0 010-96 48.01 48.01 0 010 96z" />
    </svg>
  );
};

const IconCross = ({ size }: { size: number }) => {
  return (
    <svg fill="none" viewBox="0 0 15 15" height="1em" width="1em"
         style={{ width: size, height: size }}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.782 4.032a.575.575 0 10-.813-.814L7.5 6.687 4.032 3.218a.575.575 0 00-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 00.814.814L7.5 8.313l3.469 3.469a.575.575 0 00.813-.814L8.313 7.5l3.469-3.468z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const NetworkSelectorWarning = () => (
  <div>
    <IconWarning />
    <Form.Text style={{ marginLeft: 4 }}>
      Choose network first! Data will be reset on network change.
    </Form.Text>
  </div>
);
