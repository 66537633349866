import { Updater } from 'use-immer';

import Popup, { SwalStyled } from '../../helper/Popup';
import { AppState, sendUpdateEvent } from '../../App';
import SecureLocalStorage from '../../helper/SecureLocalStorage';

import { DBMiscSettings, MODULE_EVENTS, NETWORK, PinType, REQUESTER } from '../../common';
import { infoToast } from '../../helper/Toast';

/**
 *
 */
export async function selectAccessKey(selectedPrivateKey: string, setAppState: Updater<AppState>) {
  Popup.singleInput({
    title: 'Access Key',
    width: 800,
    value: '',
    validator: (v) => v.length >= 64 ? null : `Should be at least 64 characters length`,
    onNewValue: (accessKey) => {
      SecureLocalStorage.setItem('USER_ACCESS_KEY', accessKey);

      setAppState((draft) => {
        draft.user = {
          name: '',
          accessKey,
        };
      });
    },
    secondButtonText: 'Clear auth data',
    onSecondButton: () => {
      Popup.confirm({
        title: 'Clear auth data',
        text: 'All locally stored account data will be cleared.<br>You will need to paste your private key again.<br>Press Yes to clear data.',
        onConfirm: () => {
          SecureLocalStorage.clear();
          window.location.reload();
        },
      });
    },
  });
}

/**
 *
 */
export function toggleGlobalAutomation(globalAutomation: DBMiscSettings['globalAutomation']) {
  sendUpdateEvent(MODULE_EVENTS.UPDATE_MISC_SETTINGS, { updates: { globalAutomation: !globalAutomation } });
}

/**
 *
 */
export function toggleRequester(requester: REQUESTER, requesters: DBMiscSettings['requesters']) {
  sendUpdateEvent(MODULE_EVENTS.UPDATE_MISC_SETTINGS, {
    updates: {
      requesters: {
        ...requesters,
        [requester]: !(requesters[requester] || false),
      },
    },
  });
}

export function toggleRequesterMode(
  requester: REQUESTER,
  requesterSettings: DBMiscSettings['requesterSettings'],
) {
  const currentValue = requesterSettings?.[requester]?.tracking || 'all';
  const newValue = currentValue === 'all' ? 'selected' : 'all';
  const newSettings = structuredClone(requesterSettings);
  newSettings[requester] = {
    ...newSettings[requester],
    tracking: newValue,
  };
  sendUpdateEvent(MODULE_EVENTS.UPDATE_MISC_SETTINGS, {
    updates: { requesterSettings: newSettings },
  });
}

/**
 *
 */
export function toggleDashboardDisplayAllPinnedTokens(
  dashboardDisplayAllPinnedTokens: DBMiscSettings['dashboardDisplayAllPinnedTokens'],
) {
  sendUpdateEvent(MODULE_EVENTS.UPDATE_MISC_SETTINGS, {
    updates: { dashboardDisplayAllPinnedTokens: !dashboardDisplayAllPinnedTokens },
  });
}

/**
 *
 */
export function toggleWorkerIncludeSlippage(workerIncludeSlippage: DBMiscSettings['workerIncludeSlippage']) {
  sendUpdateEvent(MODULE_EVENTS.UPDATE_MISC_SETTINGS, {
    updates: { workerIncludeSlippage: !workerIncludeSlippage },
  });
}

/**
 *
 */
export async function updateSellMinAmountOut(tradeId: string, tokenName: string, initialMinAmountOut: number) {
  const result = await SwalStyled.fire({
    title: `Set Min Amount Out for ${tokenName}`,
    input: 'text',
    inputPlaceholder: 'Min Amount Out',
    confirmButtonText: 'Update',
    allowEnterKey: true,
    inputValue: `${initialMinAmountOut}`,
    inputValidator: (amount) => {
      if (amount.length === 0) return 'Should not be empty';
      if (!/^\d+(\.\d+)?$/.test(amount)) return 'Should be natural or decimal number';
      return null;
    },
  });

  if (!result.isConfirmed) return;
  const minAmountOut = Number(result.value);

  console.log(tradeId, minAmountOut);
  sendUpdateEvent(MODULE_EVENTS.TRANSACTIONS_UPDATE_SELL_MIN_AMOUNT_OUT, {
    tradeId,
    minAmountOut,
  });
}

/**
 *
 */
export async function updateSellBribe(tradeId: string, network: NETWORK, tokenName: string, initialBribe: number) {
  const result = await SwalStyled.fire({
    title: `Set Sell Bribe for ${tokenName}`,
    input: 'text',
    inputPlaceholder: `Bribe in ${network === NETWORK.SOLANA ? 'SOL' : 'ETH'}`,
    confirmButtonText: 'Update',
    allowEnterKey: true,
    inputValue: `${initialBribe}`,
    inputValidator: (amount) => {
      if (amount.length === 0) return 'Should not be empty';
      if (!/^\d+(\.\d+)?$/.test(amount)) return 'Should be natural or decimal number';
      return null;
    },
  });

  if (!result.isConfirmed) return;
  const bribe = Number(result.value);

  console.log(tradeId, bribe);
  sendUpdateEvent(MODULE_EVENTS.TRANSACTIONS_UPDATE_SELL_BRIBE, { tradeId, bribe });
}

/**
 *
 */
export async function updateSellGasPriceGwei(tradeId: string, tokenName: string, initialGasPriceGwei: number) {
  const result = await SwalStyled.fire({
    title: `Set Sell Gas Price for ${tokenName}`,
    input: 'text',
    inputPlaceholder: 'Gas Price in gwei',
    confirmButtonText: 'Update',
    allowEnterKey: true,
    inputValue: `${initialGasPriceGwei}`,
    inputValidator: (amount) => {
      if (amount.length === 0) return 'Should not be empty';
      if (!/^\d+(\.\d+)?$/.test(amount)) return 'Should be natural or decimal number';
      return null;
    },
  });

  if (!result.isConfirmed) return;
  const gasPriceGwei = Number(result.value);

  console.log(tradeId, gasPriceGwei);
  sendUpdateEvent(MODULE_EVENTS.UPDATE_SELL_GAS_PRICE, { tradeId, gasPriceGwei });
}

/**
 *
 */
export function setWorkerTrackingValues(workerTrackingValues: DBMiscSettings['workerTrackingValues']) {
  Popup.singleInput({
    title: 'Tracking values',
    text: 'Enter comma separated values',
    value: workerTrackingValues.join(','),
    validator: (v) => {
      try {
        const splitted = v.split(',');
        for (const s of splitted) {
          const num = parseFloat(s);
          if (s !== num.toString()) {
            return `"${s}" is not a number. Separate values with "," (comma)`;
          }
        }
        return '';
      } catch (e) {
        return 'Invalid scheme. Separate values with "," (comma)';
      }
    },
    onNewValue: (v) => {
      const values = v.split(',').map((s) => parseFloat(s));
      sendUpdateEvent(MODULE_EVENTS.UPDATE_MISC_SETTINGS, { updates: { workerTrackingValues: values } });
    },
  });
}

/**
 *
 */
export async function pinToken(tokenName: string, networkBuy: NETWORK, networkSell: NETWORK) {
  const result = await SwalStyled.fire({
    title: `Pin Token by Trade ID`,
    input: 'text',
    inputPlaceholder: 'Trade ID',
    confirmButtonText: 'Pin',
    allowEnterKey: true,
    inputValidator: (amount) => {
      if (amount.length === 0) return 'Should not be empty';
      if (!/^[1-9]\d*$/.test(amount)) return 'Should be a natural number';
      return null;
    },
  });

  if (!result.isConfirmed) return;

  infoToast(`${tokenName}: manually pinned for ${result.value!}`, { autoClose: 2000 });
  const eventData = {
    tokenName,
    tradeId: result.value!,
    networkBuy,
    networkSell,
    value: true,
    type: PinType.AUTO,
  };
  sendUpdateEvent(MODULE_EVENTS.UPDATE_PIN, eventData);
}
