import { Updater } from 'use-immer';
import { AppState } from '../App';
import React, { useEffect } from 'react';
import * as yaml from 'js-yaml';

import {
  BRAIN_EVENTS,
  DBTX,
  GLOBAL_EVENTS,
  GO_NODER_EVENTS,
  INTERFACE_EVENTS,
  marshal,
  MODULE_EVENTS,
  MODULE_EVENTS_PARAMS,
  NODER_EVENTS,
  TRANSACTIONS_EVENTS,
  TxsAPIResponse,
  TxSentData,
  TxType,
  WORKER_EVENTS,
} from '../common';
import InnerEmitter from '../common/InnerEmitter';
import Sound from './Sound';
import { MONITORING_EVENTS } from '../common/moduleEvents/monitoringEvents';
import { addToast, errorToast, infoToast, successToast } from './Toast';
import { getNetworkImage } from '../assets/networks';
import BigNumber from 'bignumber.js';
import axios from 'axios';

export function useSetEventHandlers(setAppState: Updater<AppState>, toastDataUpdate: (text: string) => void) {
  useEffect(() => {
    const _onClientAuthSuccess = onClientAuthSuccess.bind(null, setAppState);
    const _onInitInterface = onInitInterface.bind(null, setAppState);
    const _onInterfaceEventCache = onInterfaceEventCache.bind(null, setAppState);
    const _onBrainStateUpdate = onBrainStateUpdate.bind(null, setAppState);
    const _onAWSGroupsUpdateProcessed = onAWSGroupsUpdateProcessed.bind(null, setAppState);
    const _onConnectedUsers = onConnectedUsers.bind(null, setAppState);
    const _onConnectedServices = onConnectedServices.bind(null, setAppState);
    const _onUpdateMiscSettingsProcessed = onUpdateMiscSettingsProcessed.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.CLIENT_AUTH_SUCCESS, _onClientAuthSuccess);
    InnerEmitter.on(MODULE_EVENTS.INIT_INTERFACE, _onInitInterface);
    InnerEmitter.on(MODULE_EVENTS.INTERFACE_EVENT_CACHE, _onInterfaceEventCache);
    InnerEmitter.on(MODULE_EVENTS.BRAIN_STATE_UPDATE, _onBrainStateUpdate);
    InnerEmitter.on(MODULE_EVENTS.AWS_GROUPS_UPDATE_PROCESSED, _onAWSGroupsUpdateProcessed);
    InnerEmitter.on(MODULE_EVENTS.CONNECTED_USERS, _onConnectedUsers);
    InnerEmitter.on(MODULE_EVENTS.CONNECTED_SERVICES, _onConnectedServices);
    InnerEmitter.on(MODULE_EVENTS.UPDATE_MISC_SETTINGS_PROCESSED, _onUpdateMiscSettingsProcessed);

    InnerEmitter.on(MODULE_EVENTS.COMMAND, ({ to, cmd }) => {
      if (cmd === 'call_girls') {
        Sound.play('call');
        setTimeout(() => {
          document.getElementById('root')?.classList.add('girls-bg');
        }, 1000);
        setTimeout(() => {
          document.getElementById('root')?.classList.remove('girls-bg');
        }, 19000);
      }
    });

    /**
     * Noder
     */
    const _onNewHeads = onNewHeads.bind(null, setAppState);
    const _onGasEstimate = onGasEstimate.bind(null, setAppState);
    const _onBalanceUpdate = onBalanceUpdate.bind(null, setAppState);
    const _onSecondaryBalanceUpdate = onSecondaryBalanceUpdate.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.NEW_HEADS, _onNewHeads);
    InnerEmitter.on(MODULE_EVENTS.GAS_ESTIMATE, _onGasEstimate);
    InnerEmitter.on(MODULE_EVENTS.BALANCE_UPDATE, _onBalanceUpdate);
    InnerEmitter.on(MODULE_EVENTS.SECONDARY_BALANCE_UPDATE, _onSecondaryBalanceUpdate);

    /**
     * GoNoder
     */
    const _onNewBlockTxDatas = onNewBlockTxDatas.bind(null, setAppState);
    const _onBlockTxExtractError = onBlockTxExtractError.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.NEW_BLOCK_TX_DATAS, _onNewBlockTxDatas);
    InnerEmitter.on(MODULE_EVENTS.BLOCK_TX_EXTRACT_ERROR, _onBlockTxExtractError);

    /**
     * Worker
     */
    const _onWorkerIterationResult = onWorkerIterationResult.bind(null, setAppState);
    const _onWorkerIterationError = onWorkerIterationError.bind(null, setAppState);
    const _onEventerStatsString = onEventerStatsString.bind(null, setAppState);
    const _onWorkerStatsString = onWorkerStatsString.bind(null, setAppState);
    const _onWorkerAnalyticsString = onWorkerAnalyticsString.bind(null, setAppState);
    const _onWorkerEventsString = onWorkerEventsString.bind(null, setAppState);
    const _onWorkerLpStatString = onWorkerLpStatString.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.WORKER_ITERATION_RESULT, _onWorkerIterationResult);
    InnerEmitter.on(MODULE_EVENTS.WORKER_ITERATION_ERROR, _onWorkerIterationError);
    InnerEmitter.on(MODULE_EVENTS.EVENTER_STATS_STRING, _onEventerStatsString);
    InnerEmitter.on(MODULE_EVENTS.WORKER_STATS_STRING, _onWorkerStatsString);
    InnerEmitter.on(MODULE_EVENTS.WORKER_ANALYTICS_STRING, _onWorkerAnalyticsString);
    InnerEmitter.on(MODULE_EVENTS.WORKER_EVENTS_STRING, _onWorkerEventsString);
    InnerEmitter.on(MODULE_EVENTS.WORKER_LP_STAT_STRING, _onWorkerLpStatString);

    /**
     * Configs
     */
    const _onListJsonConfigsResult = onListJsonConfigsResult.bind(null, setAppState);
    const _onRequestJsonConfigsResult = onRequestJsonConfigsResult.bind(null, setAppState);
    const _onUpdateJsonConfigsResult = onUpdateJsonConfigsResult.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.LIST_JSON_CONFIGS_RESULT, _onListJsonConfigsResult);
    InnerEmitter.on(MODULE_EVENTS.REQUEST_JSON_CONFIG_RESULT, _onRequestJsonConfigsResult);
    InnerEmitter.on(MODULE_EVENTS.UPDATE_JSON_CONFIG_RESULT, _onUpdateJsonConfigsResult);

    /**
     * Monitoring
     */
    const _onUpdateNewTokensProcessed = onUpdateNewTokensProcessed.bind(null, setAppState);
    const _onUpdateCompetitorsProcessed = onUpdateCompetitorsProcessed.bind(null, setAppState);

    InnerEmitter.on(
      MODULE_EVENTS.MONITORING_ON_UPDATE_UNKNOWN_TOKEN_PROCESSED,
      _onUpdateNewTokensProcessed,
    );
    InnerEmitter.on(
      MODULE_EVENTS.MONITORING_ON_UPDATE_COMPETITOR_PROCESSED,
      _onUpdateCompetitorsProcessed,
    );

    /**
     * Rest
     */
    const _onNotificationToClient = onNotificationToClient.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.NOTIFICATION_TO_CLIENT, _onNotificationToClient);

    const _onCreateTokenProcessed = onCreateTokenProcessed.bind(null, setAppState);
    const _onUpdateTokenProcessed = onUpdateTokenProcessed.bind(null, setAppState, toastDataUpdate);
    const _onDeleteTokenProcessed = onDeleteTokenProcessed.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.CREATE_TOKEN_PROCESSED, _onCreateTokenProcessed);
    InnerEmitter.on(MODULE_EVENTS.UPDATE_TOKEN_PROCESSED, _onUpdateTokenProcessed);
    InnerEmitter.on(MODULE_EVENTS.DELETE_TOKEN_PROCESSED, _onDeleteTokenProcessed);

    const _onCreateBridgeProcessed = onCreateBridgeProcessed.bind(null, setAppState);
    const _onUpdateBridgeProcessed = onUpdateBridgeProcessed.bind(null, setAppState);
    const _onDeleteBridgeProcessed = onDeleteBridgeProcessed.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.CREATE_BRIDGE_PROCESSED, _onCreateBridgeProcessed);
    InnerEmitter.on(MODULE_EVENTS.UPDATE_BRIDGE_PROCESSED, _onUpdateBridgeProcessed);
    InnerEmitter.on(MODULE_EVENTS.DELETE_BRIDGE_PROCESSED, _onDeleteBridgeProcessed);

    const _onUpdateAutomationRulesProcessed = onUpdateAutomationRulesProcessed.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.UPDATE_AUTOMATION_RULES_PROCESSED, _onUpdateAutomationRulesProcessed);

    const _onGetTxsConfResult = onGetTxsConfResult.bind(null, setAppState);
    const _onGetNoderGoConfResult = onGetNoderGoConfResult.bind(null, setAppState);
    const _onGetMonitoringConfResult = onGetMonitoringConfResult.bind(null, setAppState);
    InnerEmitter.on(MODULE_EVENTS.FETCH_TXS_CONFIG_RESULT, _onGetTxsConfResult);
    InnerEmitter.on(MODULE_EVENTS.FETCH_NODER_GO_CONFIG_RESULT, _onGetNoderGoConfResult);
    InnerEmitter.on(MODULE_EVENTS.FETCH_MONITORING_CONFIG_RESULT, _onGetMonitoringConfResult);

    const _onTransactionsSent = onTransactionsSent.bind(null, setAppState);
    const _onTransactionsOvertaken = onTransactionsOvertaken.bind(null, setAppState);
    const _onTransactionsConfirmed = onTransactionsConfirmed.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.TRANSACTIONS_SENT, _onTransactionsSent);
    InnerEmitter.on(MODULE_EVENTS.TRANSACTIONS_OVERTAKEN, _onTransactionsOvertaken);
    InnerEmitter.on(MODULE_EVENTS.TRANSACTIONS_CONFIRMED, _onTransactionsConfirmed);

    const _onAddCheckerRes = onAddCheckerRes.bind(null, setAppState);
    const _onDeletedCheckerRes = onDeleteCheckerRes.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.ADD_CHECKER_RES, _onAddCheckerRes);
    InnerEmitter.on(MODULE_EVENTS.DELETE_CHECKER_RES, _onDeletedCheckerRes);

    return () => {
      InnerEmitter.off(MODULE_EVENTS.INIT_INTERFACE, _onInitInterface);

      InnerEmitter.off(MODULE_EVENTS.CREATE_TOKEN_PROCESSED, _onCreateTokenProcessed);
      InnerEmitter.off(MODULE_EVENTS.UPDATE_TOKEN_PROCESSED, _onUpdateTokenProcessed);
      InnerEmitter.off(MODULE_EVENTS.DELETE_TOKEN_PROCESSED, _onDeleteTokenProcessed);

      InnerEmitter.off(MODULE_EVENTS.CREATE_BRIDGE_PROCESSED, _onCreateBridgeProcessed);
      InnerEmitter.off(MODULE_EVENTS.UPDATE_BRIDGE_PROCESSED, _onUpdateBridgeProcessed);
      InnerEmitter.off(MODULE_EVENTS.DELETE_BRIDGE_PROCESSED, _onDeleteBridgeProcessed);

      InnerEmitter.off(
        MODULE_EVENTS.UPDATE_AUTOMATION_RULES_PROCESSED,
        _onUpdateAutomationRulesProcessed,
      );
    };
  }, []);
}

/**
 *
 */
function onClientAuthSuccess(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[GLOBAL_EVENTS.CLIENT_AUTH_SUCCESS],
) {
  setAppState((draft) => {
    draft.user.name = data.name;
  });
}

/**
 *
 */
function onInitInterface(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.INIT_INTERFACE],
) {
  const {
    devMode,
    msRemainingBeforeDisconnect,
    allTokens,
    competitors,
    bridges,
    miscSettings,
    brainState,
    awsGroups,
    connectedServices,
  } = data;

  setAppState((draft) => {
    draft.devMode = devMode;
    draft.msRemainingBeforeDisconnect = msRemainingBeforeDisconnect;
    draft.allTokens = allTokens;
    draft.competitors = competitors;
    draft.bridges = bridges;
    draft.miscSettings = miscSettings;
    draft.brainState = brainState;
    draft.awsGroups = awsGroups;
    draft.connectedServices = connectedServices;
  });
}

/**
 *
 */
function onInterfaceEventCache(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.INTERFACE_EVENT_CACHE],
) {
  setAppState((draft) => {
    for (const key of Object.keys(data)) {
      // @ts-ignore
      draft[key] = data[key];
    }
  });
}

/**
 *
 */
function onBrainStateUpdate(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.BRAIN_STATE_UPDATE],
) {
  setAppState((draft) => {
    draft.brainState = data;
  });
}

/**
 *
 */
function onAWSGroupsUpdateProcessed(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.AWS_GROUPS_UPDATE_PROCESSED],
) {
  setAppState((draft) => {
    draft.awsGroups = data.awsGroups;
  });
}

/**
 *
 */
function onConnectedUsers(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CONNECTED_USERS],
) {
  setAppState((draft) => {
    draft.connectedUsers = data;
  });
}

/**
 *
 */
function onConnectedServices(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CONNECTED_SERVICES],
) {
  setAppState((draft) => {
    draft.connectedServices = data;
  });
}

/**
 *
 */
function onUpdateMiscSettingsProcessed(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.UPDATE_MISC_SETTINGS_PROCESSED],
) {
  setAppState((draft) => {
    draft.miscSettings = data.miscSettings;
  });
}

/**
 *
 */
function onNewHeads(setAppState: Updater<AppState>, data: MODULE_EVENTS_PARAMS[NODER_EVENTS.NEW_HEADS]) {
  setAppState((draft) => {
    draft.newHeads[data.networkName as keyof AppState['newHeads']] = data;
  });
}

/**
 *
 */
function onGasEstimate(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[NODER_EVENTS.GAS_ESTIMATE],
) {
  setAppState((draft) => {
    draft.gasEstimate[data.networkName] = data;
  });
}

/**
 *
 */
function onBalanceUpdate(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[NODER_EVENTS.BALANCE_UPDATE],
) {
  setAppState((draft) => {
    draft.balance[data.networkName] = data.balance;
  });
}

function onSecondaryBalanceUpdate(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[NODER_EVENTS.SECONDARY_BALANCE_UPDATE],
) {
  setAppState((draft) => {
    draft.secondaryBalance[data.walletAddress] = {
      ...(draft.secondaryBalance[data.walletAddress] || {}),
      [data.networkName]: data.balance,
    };
  });
}

/**
 *
 */
function onNewBlockTxDatas(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[GO_NODER_EVENTS.NEW_BLOCK_TX_DATAS],
) {
  // console.log("onNewBlockTxDatas", data)
  setAppState((draft) => {
    draft.goNoderNewHeads[data.networkName] = data;
  });
}

function onBlockTxExtractError(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[GO_NODER_EVENTS.BLOCK_TX_EXTRACT_ERROR],
) {
  addToast(
    `${data.networkName}: tx extract error for block ${data.blockNumber}. Workers refetching all tokens`,
    { type: 'warning', autoClose: 4000 },
  );
}

/**
 *
 */
function onWorkerIterationResult(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_ITERATION_RESULT],
) {
  setAppState((draft) => {
    for (const [tokenName, res] of Object.entries(data)) {
      draft.trackingResults[tokenName] = res;
    }
  });
}

function onWorkerIterationError(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_ITERATION_ERROR],
) {
  setAppState((draft) => {
    for (const [tokenName, res] of Object.entries(data)) {
      draft.trackingErrors[tokenName] = res;
    }
  });
}

function onEventerStatsString(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_EVENTS_STRING],
) {
  setAppState((draft) => {
    draft.eventerStatsString = data;
  });
}

function onWorkerStatsString(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_STATS_STRING],
) {
  setAppState((draft) => {
    draft.workerStatsString = data;
  });
}

function onWorkerAnalyticsString(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_ANALYTICS_STRING],
) {
  setAppState((draft) => {
    draft.workerAnalyticsString = data;
  });
}

function onWorkerEventsString(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_EVENTS_STRING],
) {
  setAppState((draft) => {
    draft.workerEventsString = data;
  });
}

function onWorkerLpStatString(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_LP_STAT_STRING],
) {
  setAppState((draft) => {
    draft.workerLpStatString = data;
  });
}

/**
 *
 */
function onUpdateNewTokensProcessed(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[MONITORING_EVENTS.MONITORING_ON_UPDATE_UNKNOWN_TOKEN_PROCESSED],
) {
  setAppState((draft) => {
    // draft.newTokens = data
  });
}

/**
 *
 */
function onUpdateCompetitorsProcessed(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[MONITORING_EVENTS.MONITORING_ON_UPDATE_COMPETITOR_PROCESSED],
) {
  setAppState((draft) => {
    draft.competitors = data;
  });
}

/**
 *
 */
function onNotificationToClient(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.NOTIFICATION_TO_CLIENT],
) {
  if (data.autoClose === 60000) {
    const f = async () => {
      const num = 14 + Math.floor(Math.random() * 8);
      for (let i = 0; i < num; i++) {
        Sound.play('dindilin');
        await new Promise(r => setTimeout(r, 50));
      }
    };
    f();
  }
  addToast(data.msg, { type: data.type, autoClose: data.autoClose });
}

/**
 *
 */
function onCreateTokenProcessed(
  setAppState: Updater<AppState>,
  { tokenName, tokenData }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CREATE_TOKEN_PROCESSED],
) {
  setAppState((appState) => {
    appState.allTokens[tokenName] = tokenData;
  });
}

/**
 *
 */
function onUpdateTokenProcessed(
  setAppState: Updater<AppState>,
  toastDataUpdate: (text: string) => void,
  { tokenName, tokenData }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.UPDATE_TOKEN_PROCESSED],
) {
  // toastDataUpdate(`Token ${tokenName} updated`)
  setAppState((appState) => {
    appState.allTokens[tokenName] = tokenData;
  });
}

/**
 *
 */
function onDeleteTokenProcessed(
  setAppState: Updater<AppState>,
  { tokenName }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.DELETE_TOKEN_PROCESSED],
) {
  setAppState((appState) => {
    delete appState.allTokens[tokenName];
  });
}

/**
 *
 */
function onCreateBridgeProcessed(
  setAppState: Updater<AppState>,
  { bridgeName, bridgeData }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CREATE_BRIDGE_PROCESSED],
) {
  setAppState((appState) => {
    appState.bridges[bridgeName] = bridgeData;
  });
}

/**
 *
 */
function onUpdateBridgeProcessed(
  setAppState: Updater<AppState>,
  { bridgeName, bridgeData }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.UPDATE_BRIDGE_PROCESSED],
) {
  setAppState((appState) => {
    appState.bridges[bridgeName] = bridgeData;
  });
}

/**
 *
 */
function onDeleteBridgeProcessed(
  setAppState: Updater<AppState>,
  {
    bridgeName,
    updatedTokens,
  }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.DELETE_BRIDGE_PROCESSED],
) {
  setAppState((appState) => {
    delete appState.bridges[bridgeName];
    appState.allTokens = {
      ...appState.allTokens,
      ...updatedTokens,
    };
  });
}

/**
 *
 */
function onUpdateAutomationRulesProcessed(
  setAppState: Updater<AppState>,
  { rules }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.UPDATE_AUTOMATION_RULES_PROCESSED],
) {
  setAppState((appState) => {
    appState.automationRules = rules;
  });
}

function onTransactionsSent(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[TRANSACTIONS_EVENTS.TRANSACTIONS_SENT],
) {
  Sound.play('dindilin');

  for (const tx of data) {
    const content = <TxInfoToastContent tx={tx} state="SENDING" />;
    infoToast(content, { autoClose: 10000, icon: false });
  }
}

export function onTransactionsConfirmed(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[TRANSACTIONS_EVENTS.TRANSACTIONS_CONFIRMED],
) {
  Sound.play('beep');
  setTimeout(() => {
    Sound.play('beep');
  }, 200);

  for (const txHash in data.txs) {
    const tx = data.txs[txHash];
    const content = tx.success
      ? <TxInfoToastContent tx={tx} state="CONFIRM" success={true} />
      : <TxInfoToastContent tx={tx} state="FAIL" success={false} />;

    const method = tx.success ? successToast : errorToast;
    method(content, { autoClose: 10000, icon: false });

    if (
      !tx.success ||
      (tx.txType !== TxType.Sell && tx.txType !== TxType.BundleSell) ||
      BigNumber(tx.expectedProfit ?? 0).isLessThan(BigNumber(0.5))
    ) {
      continue;
    }
    checkActualProfit(tx.tradeId, tx);
  }
}

async function checkActualProfit(tradeId: string, sellTx: DBTX) {
  try {
    const url = `${process.env.REACT_APP_SOCKET_BASE_URL}`
      .replace(/^wss:/, 'https:')
      .concat('/txs');
    const txs = await axios.get<TxsAPIResponse>(url);

    const trade = txs.data.trades.find(t => t.id === tradeId);
    if (!trade) {
      return;
    }
    const buyTx = Object.values(trade.txs).find((tx: DBTX) => tx.txType === TxType.Swap || tx.txType === TxType.Bundle);
    if (!buyTx) {
      return;
    }

    const amountIn = BigNumber(buyTx.srcTokenAmount ?? '0');
    if (amountIn.isNaN() || amountIn.isZero()) {
      return;
    }
    const amountOut = BigNumber(sellTx.dstTokenAmount ?? '0');
    if (amountOut.isNaN() || amountOut.isZero()) {
      return;
    }
    const diff = amountOut.minus(amountIn);
    if (diff.isGreaterThan(BigNumber(0.5))) {
      Sound.play('profit');
    }
  } catch (err: any) {
    console.error(marshal(err));
  }
}

type TxInfoToastContentProps = {
  tx: TxSentData;
  state: 'SENDING' | 'CONFIRM' | 'FAIL'
  success?: boolean
}
const TxInfoToastContent = ({ tx, state, success }: TxInfoToastContentProps) => {
  const stateColor = state === 'SENDING' ? '#3498db' : success ? '#07bc0c' : '#e74c3c';

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{
            fontWeight: 600,
            marginBottom: '8px',
          }}>
            <span style={{ marginRight: '4px' }}>{tx.tokenName} ({tx.txType}):</span>
            <span style={{ color: stateColor }}>{state}</span>
          </span>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          width="20px"
          height="20px"
          style={{ marginRight: '4px' }}
          src={getNetworkImage(tx.networkBuy)}
        />
        {tx.bridgeName}
        <img
          width="20px"
          height="20px"
          style={{ marginLeft: '4px', marginRight: '4px' }}
          src={getNetworkImage(tx.networkSell)}
        />
      </div>
    </div>
  );
};

function onTransactionsOvertaken(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[TRANSACTIONS_EVENTS.TRANSACTIONS_OVERTAKEN],
) {
  Sound.play('beep');
  infoToast('Transaction overtaken', {
    autoClose: 10000,
  });
}

function onAddCheckerRes(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.ADD_CHECKER_RES],
) {
  setAppState((appState) => {
    appState.checkerRes = { ...appState.checkerRes, [data.iterId]: data };
  });
}

function onDeleteCheckerRes(
  setAppState: Updater<AppState>,
  iterId: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.DELETE_CHECKER_RES],
) {
  setAppState((appState) => {
    delete appState.checkerRes[iterId];
    appState.checkerRes = { ...appState.checkerRes };
  });
}

/**
 *
 */
function onGetTxsConfResult(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[INTERFACE_EVENTS.FETCH_TXS_CONFIG_RESULT],
) {
  setAppState((draft) => {
    if (!draft.configs) {
      draft.configs = { 'noder-go': '', monitoring: '', transactions: yaml.dump(data) };
    } else {
      draft.configs.transactions = yaml.dump(data);
    }
  });
}

/**
 *
 */
function onGetNoderGoConfResult(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[INTERFACE_EVENTS.FETCH_NODER_GO_CONFIG_RESULT],
) {
  setAppState((draft) => {
    if (!draft.configs) {
      draft.configs = { transactions: '', monitoring: '', 'noder-go': yaml.dump(data) };
    } else {
      draft.configs['noder-go'] = yaml.dump(data);
    }
  });
}

/**
 *
 */
function onGetMonitoringConfResult(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[INTERFACE_EVENTS.FETCH_MONITORING_CONFIG_RESULT],
) {
  setAppState((draft) => {
    if (!draft.configs) {
      draft.configs = { transactions: '', 'noder-go': '', monitoring: yaml.dump(data) };
    } else {
      draft.configs['noder-go'] = yaml.dump(data);
    }
  });
}

function onListJsonConfigsResult(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[INTERFACE_EVENTS.LIST_JSON_CONFIGS_RESULT],
) {
  setAppState(draft => {
    draft.configsJson = Object.fromEntries(data.configNames.map(name => ([name, undefined])));
  });
}

function onRequestJsonConfigsResult(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[INTERFACE_EVENTS.REQUEST_JSON_CONFIG_RESULT],
) {
  setAppState(draft => {
    draft.configsJson[data.configName] = data.configData;
  });
}

function onUpdateJsonConfigsResult(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[INTERFACE_EVENTS.UPDATE_JSON_CONFIG_RESULT],
) {
  setAppState(draft => {
    draft.configsJson[data.configName] = data.configData;
  });
}