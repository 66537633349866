import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react';
import { useVirtualizer, useWindowVirtualizer } from '@tanstack/react-virtual';
import { Button } from 'react-bootstrap';

import { NETWORK } from '../common';
import { getNetworkImage } from '../assets/networks';

type VitrualTableColumn = {
  name: string;
  key: string;
  minWidth: number;
  tooltip?: string;
  width?: number | string;
};

export type HeaderTitleData = {
  date: string;
  tradeId: string;
  tokenName: string;
  profitMsg: string;
  networkBuy: NETWORK;
  networkSell: NETWORK;
};

export type HeaderRow = {
  title: HeaderTitleData;
  profit: number;
  fee: number;
  time: number;
  color: string;
  action?: {
    name: string;
    handler: () => void;
  };
};

export type TableRow = HeaderRow | JSX.Element[];

type VitrualTableProps = {
  columns: VitrualTableColumn[];
  data: TableRow[];
  style?: React.CSSProperties;
  estimateRowHeight: (index: number) => number;
  onLoadMore?: () => void; // We'll use this for infinite scroll
};

export type VirtualTableRefProps = {
  measure: () => void;
};

export default memo(
  forwardRef(function VirtualTable(
    { columns, data, style, estimateRowHeight, onLoadMore }: VitrualTableProps,
    ref,
  ) {
    const parentRef = useRef<HTMLDivElement | null>(null);
    const parentOffsetRef = useRef(0);

    useLayoutEffect(() => {
      parentOffsetRef.current = parentRef.current?.offsetTop ?? 0;
    }, []);

    const getColumnWidth = (index: number) => columns[index].width;
    const getColumnMinWidth = (index: number) => columns[index].minWidth;
    const getColumnTooltip = (index: number) => columns[index].tooltip;

    // Use the window virtualizer for vertical scrolling
    const virtualizer = useWindowVirtualizer({
      count: data.length,
      estimateSize: estimateRowHeight,
      overscan: 5,
      scrollMargin: parentOffsetRef.current,
    });

    // Use a horizontal virtualizer for columns
    const columnVirtualizer = useVirtualizer({
      horizontal: true,
      count: columns.length,
      getScrollElement: () => parentRef.current,
      estimateSize: getColumnMinWidth,
      overscan: 5,
    });

    // When a parent wants to recalc row heights, they can call ref.measure()
    useImperativeHandle(
      ref,
      () => ({
        measure: () => {
          virtualizer.measure();
        },
      }),
      [virtualizer],
    );

    // This effect sets up our infinite scroll logic
    useEffect(() => {
      if (!onLoadMore) return; // If there's no onLoadMore, skip

      // Grab the virtual items. Reverse them to get the last item
      const [lastItem] = [...virtualizer.getVirtualItems()].reverse() || [];
      if (!lastItem) return;

      // If the last item is in or near the view, trigger onLoadMore
      // Adjust conditions as needed, e.g. overscan, etc.
      if (lastItem.index >= data.length - 1) {
        onLoadMore();
      }
    }, [data.length, onLoadMore, virtualizer.getVirtualItems()]);

    const columnItems = columnVirtualizer.getVirtualItems();
    const [before, after] =
      columnItems.length > 0
        ? [
          columnItems[0].start,
          columnVirtualizer.getTotalSize() -
          columnItems[columnItems.length - 1].end,
        ]
        : [0, 0];

    return (
      <div className="virtual-table" style={{ ...style }}>
        {/* Header */}
        <div
          className="stickyHeader"
          style={{
            display: 'flex',
            justifyContent: 'center',
            verticalAlign: 'center',
            textAlign: 'center',
          }}
        >
          {columnItems.map((column) => {
            const width = getColumnWidth(column.index);
            const minWidth = getColumnMinWidth(column.index);
            const tooltip = getColumnTooltip(column.index);

            return (
              <div
                key={columns[column.index].key}
                title={tooltip ?? ''}
                style={{
                  width: width || minWidth,
                  minWidth: minWidth,
                  borderRight:
                    column.index < columns.length - 1
                      ? '1px solid #e3e3e3'
                      : '',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '0.8rem',
                }}
              >
                <span>{columns[column.index].name}</span>
              </div>
            );
          })}
        </div>

        {/* Body */}
        <div
          className="virtual-table-body"
          ref={parentRef}
          style={{ overflowY: 'auto', maxHeight: '100%' }}
        >
          <div
            className="virtual-table-body-container"
            style={{
              height: virtualizer.getTotalSize(),
              position: 'relative',
            }}
          >
            {virtualizer.getVirtualItems().map((row) => {
              if (!row) return null;

              // If this row is a "header row"
              if (
                typeof data[row.index] === 'object' &&
                'title' in (data[row.index] as any)
              ) {
                const rowData = data[row.index] as HeaderRow;

                return (
                  <div
                    key={row.key}
                    data-index={row.index}
                    style={{
                      left: 0,
                      top: 0,
                      color: rowData.color ?? 'inherit',
                      width: '100%',
                      minHeight: row.size,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'end',
                      paddingBottom: 8,
                      paddingLeft: 8,
                      fontWeight: 600,
                      position: 'absolute',
                      borderBottom: '1px solid var(--bs-gray-200)',
                      transform: `translateY(${
                        row.start - virtualizer.options.scrollMargin
                      }px)`,
                    }}
                  >
                    {/* Row content */}
                    <div style={{ display: 'flex' }}>
                      <span style={{ fontSize: '0.9rem', marginRight: '4px' }}>
                        {rowData.title.date}
                      </span>
                      <span style={{ fontSize: '0.9rem', marginRight: '4px' }}>
                        [{rowData.title.tradeId}]
                      </span>
                      <span style={{ fontSize: '0.9rem', marginRight: '8px' }}>
                        {rowData.title.tokenName}
                      </span>
                      <img
                        src={getNetworkImage(rowData.title.networkBuy)}
                        style={{
                          marginRight: '4px',
                          marginTop: '2px',
                        }}
                        alt=""
                        width={16}
                        height={16}
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '1.5rem',
                            marginRight: '4px',
                            marginTop: '-10px',
                          }}
                        >
                          {'\u21D2'}
                        </span>
                      </div>
                      <img
                        src={getNetworkImage(rowData.title.networkSell)}
                        style={{ marginRight: '12px', marginTop: '2px' }}
                        alt=""
                        width={16}
                        height={16}
                      />
                      <span style={{ fontSize: '0.9rem' }}>
                        {rowData.title.profitMsg}
                      </span>
                    </div>
                    {!!rowData.action && (
                      <div className="d-grid" style={{ height: 50, width: 98 }}>
                        <Button
                          size="sm"
                          variant="light"
                          style={{
                            fontSize: '0.8em',
                            marginBottom: '-4px',
                            cursor: 'pointer',
                          }}
                          className="text-truncate"
                          onClick={rowData.action.handler}
                        >
                          {rowData.action.name}
                        </Button>
                      </div>
                    )}
                  </div>
                );
              }

              // Otherwise, assume it's a JSX.Element[] row
              const rowData = data[row.index] as JSX.Element[];
              return (
                <div
                  key={row.key}
                  data-index={row.index}
                  style={{
                    display: 'flex',
                    left: 0,
                    top: 0,
                    width: '100%',
                    position: 'absolute',
                    transform: `translateY(${
                      row.start - virtualizer.options.scrollMargin
                    }px)`,
                  }}
                >
                  {/* Render any needed "blank" spacing to the left */}
                  <div style={{ width: `${before}px` }} />
                  {columnItems.map((column) => {
                    const width = getColumnWidth(column.index);
                    const minWidth = getColumnMinWidth(column.index);
                    return (
                      <div
                        key={column.key}
                        data-index={column.index}
                        style={{
                          minHeight: row.size,
                          width: width || minWidth,
                          minWidth: minWidth,
                          borderBottom: '1px solid var(--bs-gray-200)',
                          display: 'flex',
                          verticalAlign: 'middle',
                          alignItems: 'center',
                          padding: '4px 2px',
                        }}
                      >
                        <div
                          className="d-grid"
                          style={{
                            width: '100%',
                            height: '100%',
                            alignItems: 'center',
                          }}
                        >
                          {rowData[column.index]}
                        </div>
                      </div>
                    );
                  })}
                  {/* Render any needed "blank" spacing to the right */}
                  <div style={{ width: `${after}px` }} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }),
);
