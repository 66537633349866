import Arbitrum from './Arbitrum.png';
import Base from './Base.png';
import Binance from './Binance.png';
import Ethereum from './Ethereum.png';
import Fantom from './Fantom.png';
import Gnosis from './Gnosis.png';
import Optimism from './Optimism.png';
import Polygon from './Polygon.png';
import PulseChain from './PulseChain.png';
import Snowtrace from './Snowtrace.png';
import Solana from './Solana.png';
import Sonic from './Sonic.png';
import TON from './TON.png';
import ATA from './ATA.png';
import Linea from './Linea.png';
import Blast from './Blast.png';
import Unichain from './Unichain.png';
import Metis from './Metis.png';
import Tron from './Tron.png';

import Default from './Default.png';

const NETWORK_IMAGES: {
  [networkName: string]: string
  Default: string
} = {
  Arbitrum,
  Base,
  Binance,
  Ethereum,
  Fantom,
  Gnosis,
  Optimism,
  Polygon,
  PulseChain,
  Snowtrace,
  Solana,
  TON,
  ATA,
  Linea,
  Blast,
  Unichain,
  Sonic,
  Metis,
  Tron,

  Default: Default,
};

export function getNetworkImage(networkName: string) {
  return NETWORK_IMAGES[networkName] || NETWORK_IMAGES.Default;
}
