import * as yaml from 'js-yaml';

import { sendUpdateEvent } from '../../../App';
import { MODULE_EVENTS } from '../../../common';

/**
 *
 */
export async function fetchTxsConf() {
  sendUpdateEvent(MODULE_EVENTS.FETCH_TXS_CONFIG, {});
}

/**
 *
 */
export async function fetchNoderGoConf() {
  sendUpdateEvent(MODULE_EVENTS.FETCH_NODER_GO_CONFIG, {});
}

/**
 *
 */
export async function fetchMonitoringConf() {
  sendUpdateEvent(MODULE_EVENTS.FETCH_MONITORING_CONFIG, {});
}

/**
 *
 */
export async function updateTxsConf(data: string) {
  const body: any = yaml.load(data);
  sendUpdateEvent(MODULE_EVENTS.UPDATE_TXS_CONFIG, body);
}

/**
 *
 */
export async function updateNoderGoConf(data: string) {
  const body: any = yaml.load(data);
  sendUpdateEvent(MODULE_EVENTS.UPDATE_NODER_GO_CONFIG, body);
}

/**
 *
 */
export async function updateMonitoringConf(data: string) {
  const body: any = yaml.load(data);
  sendUpdateEvent(MODULE_EVENTS.UPDATE_MONITORING_CONFIG, body);
}
